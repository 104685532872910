import React from 'react'
import { Router, Route, Switch } from 'react-router-dom'
import history from './history'
import useAppContext from '../hooks/useAppContext'
import utils from '../utils/utils'

// Subcomponents
import PrivateRoute from './PrivateRoute'
import Auth from '../components/Auth'
import ModalOnlyOneTab from '../components/ModalOnlyOneTab'
import PopupBonuses from '../components/PopupBonuses'
import WizardPrivate from '../components/WizardPrivate'
import VerificationSMSPayment from '../components/VerificationSMSPayment'
import ModalLevelUp from '../components/ModalLevelUp/ModalLevelUp'
import PopupWelcome from '../components/PopupWelcome'
import { PopupCashoutsPending } from '../components/Retirar/PopupCashoutsPending'

// Route Pages Components
import Book from '../components/Book'
import Home from '../components/Home'
import Ayuda from '../components/Ayuda'
import Login from '../components/Login'
import NoMatch from '../components/NoMatch'
import UserInfo from '../components/UserInfo'
import Quiniela from '../components/Quiniela'
import Registro from '../components/Registro'
import Terminos from '../components/Terminos'
import Slots from '../components/Slots/Slots'
import LobbyUsers from '../components/LobbyUsers'
import Promotions from '../components/Promotions'
import RegistroFB from '../components/RegistroFB'
import EvCasino from '../components/EvCasino/EvCasino'
import MisQuinielas from '../components/MisQuinielas'
import BracketsLobby from '../components/BracketsLobby'
import ResetPassword from '../components/ResetPassword'
import ErrorBoundary from '../components/ErrorBoundary'
import SurvivorLobby from '../components/SurvivorLobby'
import RecuperarPass from '../components/RecuperarPass'
import ErrorComponent from '../components/ErrorComponent'
import ValidarUsuario from '../components/ValidarUsuario'
import SportRules from '../components/SportRules/SportRules'
import BracketsPointsLobby from '../components/BracketPointsLobby'
import QuinielaGroupsLobby from '../components/QuinielaGroupsLobby'
import BracketsPerfectLobby from '../components/BracketPerfectLobby'
import PanelSmsVerification from '../components/PanelSmsVerification'
import CloseSessions from '../components/CloseSessions/CloseSessions'
import EditarQuinielaPrivada from '../components/EditarQuinielaPrivada'
import FirstTimeDownloadPanel from '../components/FirstTimeDownloadPanel'
import ResponsibleGaming from '../components/ResponsibleGaming/ResponsibleGaming'
import BracketTennisLobby from '../components/BracketTennisLobby/BracketTennisLobby'
import Terminos2025 from '../components/Terminos2025'


const Routes = () => {
  const { isNetworkAvailable } = useAppContext()

  if (!isNetworkAvailable) {
    return (
      <Router history={history}>
        <Route component={ErrorComponent} />
      </Router>
    )
  }

  return (
    <Router history={history}>
      <>
        <Auth />
        <ModalOnlyOneTab />
        <PopupBonuses />
        <WizardPrivate />
        <ModalLevelUp />
        <VerificationSMSPayment />
        <PopupWelcome />
        <PopupCashoutsPending />
        <Switch>
          <Route
            exact
            path='/lobby'
            state={utils.detectDevice()}
            component={(props) => <Home deviceType={utils.detectDevice()} {...props} />}
          />
          <Route
            exact
            path='/lobby/quinielaPop/:slug/:poolId/'
            component={(props) => <Home deviceType={utils.detectDevice()} {...props} />}
          />
          <Route
            exact
            path='/lobby/quinielaGrupoPop/:slug/:poolGroupId/'
            component={(props) => <Home deviceType={utils.detectDevice()} {...props} />}
          />
          <Route
            exact
            path='/lobby/survivorPop/:slug/:survivorId/'
            component={(props) => <Home deviceType={utils.detectDevice()} {...props} />}
          />
          <Route
            exact
            path='/lobby/bracketPop/:slug/:bracketId/'
            component={(props) => <Home deviceType={utils.detectDevice()} {...props} />}
          />
          <PrivateRoute path='/lobby_usuarios' component={LobbyUsers} />
          <PrivateRoute path='/mis_quinielas' component={MisQuinielas} />
          <PrivateRoute path='/usuario/' component={UserInfo} />
          <PrivateRoute
            gameType='quiniela'
            path='/quiniela/:slug/:poolId/:entryId?'
            component={Quiniela}
          />
          <PrivateRoute
            gameType='quinielaGrupo'
            path='/quinielaGrupo/:slug/:poolGroupId/:entryId?'
            component={QuinielaGroupsLobby}
          />
          <PrivateRoute
            exact
            gameType='bracket'
            path='/bracket/:slug/:bracketId/:bracketEntryId?'
            component={BracketsLobby}
          />
          <PrivateRoute
            exact
            gameType='bracketPerfect'
            path='/bracketPerfect/:slug/:bracketId/:bracketEntryId?'
            component={BracketsPerfectLobby}
          />
          <PrivateRoute
            exact
            gameType='bracketPoints'
            path='/bracketPoints/:slug/:bracketId/:bracketEntryId?'
            component={BracketsPointsLobby}
          />
          <PrivateRoute
            exact
            gameType='bracketTennis'
            path='/bracketTennisLobby/:slug/:bracketId/:bracketEntryId?'
            component={BracketTennisLobby}
          />
          <PrivateRoute
            exact
            gameType='survivor'
            path='/survivor/:slug/:survivorId/:survivorEntryId?'
            component={SurvivorLobby}
          />
          <Route exact path='/' component={Book} />
          <PrivateRoute exact path='/casino' component={EvCasino} />
          <PrivateRoute exact path='/slots' component={Slots} />
          <PrivateRoute path='/editar/:gameType/:privadaId' component={EditarQuinielaPrivada} />
          <Route path='/registrate' component={Registro} />
          <Route exact path='/registrate/FB' component={RegistroFB} />
          <Route path='/verificarCuenta' component={PanelSmsVerification} />
          <Route path='/password_perdido' component={RecuperarPass} />
          <Route exact path='/descargar_app' component={FirstTimeDownloadPanel} />
          <Route exact path='/reset_password/:token' component={ResetPassword} />
          <Route path='/ayuda' component={Ayuda} />
          <Route path='/validar-usuario' component={ValidarUsuario} />
          <Route path='/close-sessions' component={CloseSessions} />
          <Route
            exact
            path='/legal/terminos'
            render={() => {
              return <Terminos2025 />
            }}
          />
          <Route
            exact
            path='/legal/terminos-20250501'
            render={() => {
              return <Terminos2025 />
            }}
          />
          <Route
            exact
            path='/legal/privacidad'
            render={() => {
              return <Terminos which='privacidad' />
            }}
          />
          <Route
            exact
            path='/legal/legales'
            render={() => {
              return <Terminos which='legales' />
            }}
          />
          <Route
            exact
            path='/legal/reglas-deportes'
            render={() => {
              return <SportRules />
            }}
          />
          <Route exact path='/promociones' component={Promotions} />
          <Route
            exact
            path='/legal/responsible-gaming'
            render={() => {
              return <ResponsibleGaming />
            }}
          />
          <Route path='/ingresar' component={Login} />
          <PrivateRoute path='/salir' component={Home} />
          <Route path='/error' component={ErrorBoundary} />
          <Route component={NoMatch} />
        </Switch>
      </>
    </Router>
  )
}

export default Routes
