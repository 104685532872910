import React from 'react'
import styled from 'styled-components'
import numeral from 'numeral'

import { CancellTrigger } from './CancellTrigger'
import { useCashouts } from './use-cashouts'
import { Status } from './Status'
import Text from '../Text'

export const CashoutsTable = () => {
  const { cashouts, isLoading } = useCashouts()

  if (cashouts.length === 0 && !isLoading) {
    return (
      <Text my={20} ml={10}>No hay retiros pendientes</Text>
    )
  }

  return (
    <TableContainer>
      <Table>
        <thead>
          <tr>
            <Th>Fecha</Th>
            <Th>Monto</Th>
            <Th>Cuenta</Th>
            <Th>Estado</Th>
            <Th>Cancelación</Th>
          </tr>
        </thead>
        <tbody>
          {cashouts.map((cashout, index) => (
            <Tr key={index}>
              <Td>{new Date(cashout.created).toLocaleDateString()}</Td>
              <Td>{numeral(cashout.amount / 100).format('$0,000.00')}</Td>
              <Td>{cashout.bank}...{cashout.bank_code}</Td>
              <Td>
                <Status status={cashout.status} />
              </Td>
              <Td>
                <CancellTrigger cashout={cashout} />
              </Td>
            </Tr>
          ))}
        </tbody>
      </Table>
    </TableContainer>
  )
}

export const TableContainer = styled.div`
  margin: 15px 0;
  padding: 0 15px;
  min-width: 350px;
  overflow-x: auto;
  font-size: 14px;
`

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  background: #000000;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  border: 1px solid #333333;
  border-radius: 10px;
`

export const Th = styled.th`
  padding: 12px;
  text-align: left;
  background-color: #000000;
  border-bottom: 2px solid #333333;
  color: #ffffff;
  font-weight: 600;
  border-radius: 10px;
`

export const Td = styled.td`
  padding: 12px;
  border-bottom: 1px solid #333333;
  color: #ffffff;
`

export const Tr = styled.tr`
  &:nth-child(even) {
    background-color: #0e0e0e;
  }
`
