import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import useUserContext from '../../hooks/useUserContext'
import BalanceReal from '../Store/components/BalanceReal'
import utils from '../../utils/utils'

export const BalanceRealAnimated = () => {
  const initialBalance = utils.getUserBalance()?.balanceReal ?? 0
  const [prevBalance, setPrevBalance] = useState(initialBalance)
  const { userBalance } = useUserContext()

  useEffect(() => {
    if (userBalance?.balanceReal) {
      setPrevBalance(userBalance.balanceReal)
    }
  }, [userBalance])

  return (
    <BalanceContainer>
    <BalanceReal
      prevBalance={prevBalance}
      nextBalance={userBalance?.balanceReal}
      duration={1.4}
    />
  </BalanceContainer>
  )
}

const BalanceContainer = styled.div` 
  width: fit-content;
  margin: 0 auto;
  margin-bottom: 25px;
`
