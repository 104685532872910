import React from 'react'
import Iconify from '../Iconify'

const STATUS = {
  approved: 'fa6-solid:circle-check',
  rejected: 'fa6-solid:circle-xmark',
  pending: 'fa6-solid:clock',
  processing: 'fa6-solid:spinner',
  verification: 'fa6-solid:circle-exclamation',
}

const COLOR = {
  approved: '#78bc28',
  rejected: '#FF0000',
  pending: '#00b7ff',
  processing: '#e2e2e2',
  verification: '#ffc400',
}

export const Status = ({ status }) => {
  return (
    <Iconify
      icon={STATUS[status]}
      style={{ color: COLOR[status] }}
      width={16}
      height={16}
    />
  )
}
