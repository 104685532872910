import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import numeral from 'numeral'

// Hooks
import useRealNode from '../../hooks/useRealNode'
import useUserContext from '../../hooks/useUserContext'
import { useCashouts } from './use-cashouts'

// Utils
import API from '../../utils/api'
import utils from '../../utils/utils'

// Components
import Text from '../Text'
import Iconify from '../Iconify'
import { useCashoutsPending } from './use-cashouts-pending'
import { BalanceRealAnimated } from './BalanceRealAnimated'
import { Bar, ButtonCancel, ButtonConfirm, ButtonsDelete, CloseButton, Content, ModalPanel } from '../ConfirmModal/ConfirmModal'

const STATUS = {
  IDLE: 'IDLE',
  PROCESSING: 'PROCESSING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR'
}

const MESSAGES = {
  ERROR: 'Error al cancelar el retiro',
  CONFIRM: '¿Estas seguro de que deseas cancelar este retiro?',
  PROCESSING: 'Cancelando retiro...',
  SUCCESS: 'Retiro cancelado exitosamente:'
}

export const PopupCancelCashout = (props) => {
  const node = useRealNode('modal-node-portal')
  const { refetch } = useCashouts()
  const cashoutPending = useCashoutsPending()
  const { updateUserBalance } = useUserContext()
  const [status, setStatus] = useState(STATUS.IDLE)

  const onConfirm = async () => {
    setStatus(STATUS.PROCESSING)
    try {
      const response = await API.cancelCashout(props.cashout.id)

      if (response.data.success) {
        const balance = utils.getUserBalance()
        balance.balanceReal = Number(response.data.balance)
        updateUserBalance(balance)

        // Refetch the cashouts
        refetch()
        cashoutPending.refetch()

        if (props.closeAfterCancel) {
          handleClose()
        } else {
          setStatus(STATUS.SUCCESS)
        }
      } else {
        setStatus(STATUS.ERROR)
      }
    } catch (error) {
      setStatus(STATUS.ERROR)
    } 
  }

  const handleClose = (event) => {

    if (event) {
      event.stopPropagation()
      event.preventDefault()
    }

    if (props.onClose) {
      setStatus(STATUS.IDLE)
      props.onClose()
    }
  }

  const stopPropagation = (event) => {
    event.stopPropagation()
  }

  if (!props.show) return null

  return ReactDOM.createPortal(
    (
      <ModalPanel tabIndex='1' onMouseDown={stopPropagation}>
        <Content>
          <Bar type={props.type}>
            <Text center as='h6'>{props.title}</Text>
            <CloseButton onClick={handleClose}>
              <Iconify icon='ic:baseline-close' />
            </CloseButton>
          </Bar>
        
     
          {status === STATUS.ERROR && (
            <Text size={15} mt={5} mb={5} center color='red'>{MESSAGES.ERROR}</Text>
          )}

          {(status === STATUS.IDLE || status === STATUS.ERROR) && (
            <>
              <Text mt={25} size={15} mb={8} center>{MESSAGES.CONFIRM}</Text>
              <Text size={15} mb={5} center>
                <span>Se devolverá el retiro a tu cuenta </span>
                <strong bold>{numeral(props.cashout.amount / 100).format('$0,000.00')}</strong>
              </Text>
              <ButtonsDelete>
                <ButtonCancel onMouseDown={handleClose} className='guardar-picks btn-rojo'>NO</ButtonCancel>
                <ButtonConfirm onMouseDown={onConfirm} className='guardar-picks'>SI</ButtonConfirm>
              </ButtonsDelete>
            </>
          )}

          {status === STATUS.PROCESSING && (
            <Text center size={15} mb={5} mt={10} >{MESSAGES.PROCESSING}</Text>
          )}

          {status === STATUS.SUCCESS && (
            <>
              <Text center size={15} mb={5} mt={10}>{MESSAGES.SUCCESS}</Text>
              <BalanceRealAnimated />
              <ButtonConfirm onMouseDown={handleClose} className='guardar-picks' style={{padding: '0.3em 4em'}}>Cerrar</ButtonConfirm>
            </>
          )}
        </Content>
      </ModalPanel>
    ), node)
}


