import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import 'moment/locale/es'
import firstBy from 'thenby'
import _ from 'underscore'

// Components
import ConfirmModal from '../ConfirmModal'
import { HeaderPicks, ResetButton } from '../SurvivorUI/ListFixtures'
import { PicksSoccerNFL } from '../SurvivorUI/components/PicksSoccerNFL'
import SurvivorContestUI from '../SurvivorContestUI/SurvivorContestUI'

// Hooks
import API from '../../utils/api'
import useMergedState from '../../hooks/useMergedState'

// Utils
import survivorUtils from '../../utils/survivorUtils'
import SubscriptionRxs from '../../utils/subscriptions'
import utils, { ConfirmationOverlay } from '../../utils/utils'
import Loading from '../Loading/Loading'

export const PickSelector = (props) => {
  const initialState = {
    loading: true,
    dataLoaded: false,

    // State for SurvivorUI
    // ---------------------------------------------------------------------------
    availableTeams: null,
    errorSavePicks: false,
    showOverlay: false,
    statusEntry: false,
    guardarPicksStatus: false,
    currentSavedPicks: 0,
    reditectToLobby: false,
    showTutorial: false,
    submitting: false,

    // Representa la entrada de picks del usuario
    myEntry: {
      id: null,
      survivor_id: null,
      survivor_entry_id: null,
      user_id: null,
      survivor_picks: null
    },

    // State for SurvivorLobby
    // ---------------------------------------------------------------------------
    cambiarEntradas: null,
    changedStatus: false,
    createdPrivate: null,
    defaultStandings: null,
    branchLink: null,
    emptyList: false,
    fixtures: [],
    invitarAmigosMail: false,
    items: [],
    liveClosedFixtures: null,
    liveFixturesData: Date.now(),
    loaded: false,
    loadedPicks: false,
    logged: false,
    mounted: false,
    updateMyPicks: false,
    survivor: null,
    survivorWeeks: [],
    weekSelected: null,
    redirectLobby: null,
    redirectLobbyWithEntry: false,
    redirectMisQuinielas: null,
    redirectToSame: null,
    searchNick: null,
    reRegister: false,
    selectedEntryId: null,
    selectedEntryData: null,
    selectedWeekFixtures: [],
    showInvite: false,
    showInvitePanel: false,
    showScorePanelRegister: false,
    showPanelCancel: false,
    showPanelFirstRegister: false,
    showReRegister: false,
    sortChanged: false,
    standings: null,
    submittingSearch: false,
    survivorEntries: null,
    survivorId: null,
    firstEntry: null,
    ticketsAvailable: null,
    temporalRegisterPicks: null,
    updatedStandings: false,
    userData: null,
    withRegister: false,
    standingsChanged: false,
    queryParams: {
      page: 1,
      limit: 100,
      nick: '',
      sort: [],
      uid: utils.isLogged() ? utils.getUserInfo().id : undefined
    },

    activeSort: {
      posiciones: false,
      jugadores: false,
      alive: false,
      premios: false
    },

    allFixtures: {},
    previousWeek: null,
    // Golf and F1
    players: [],
    currentContest: null
  }

  const [state, setState] = useMergedState(initialState)
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)

  // Migrated from survivorUtils.handleFixtureUpdateSocket.bind(this)
  const handleFixtureUpdateSocket = (fixtureLiveData) => {
    if (!state.survivor || !state.fixtures) {
      return
    }

    const tempFixtures = state.fixtures
    const currentWeek = state.selectedWeek
    const temporalEntryData = state.selectedEntryData
    const currentPick = temporalEntryData !== null ? temporalEntryData.survivor_picks[currentWeek] : null

    let winnerTeam = null
    let tempCurrentFixture = null
    const socketLiveFixtures = fixtureLiveData.live_fixtures
    const socketLiveResults = fixtureLiveData.results

    if (!socketLiveFixtures || !socketLiveResults) {
      return
    }

    /**
     * Replace the live data of the fixture
     * */
    socketLiveFixtures.forEach((liveFixture) => {
      tempFixtures.forEach((fixture, fixtureIdx) => {
        tempCurrentFixture = fixture

        if (fixture.id !== liveFixture.id) {
          return
        }

        tempCurrentFixture.score_home = liveFixture.score_home
        tempCurrentFixture.score_away = liveFixture.score_away
        tempCurrentFixture.winning_pick = liveFixture.winning_pick
        tempCurrentFixture.winning_pick_line = liveFixture.winning_pick_line

        if (currentPick && currentPick.fixture_id === liveFixture.id) {
          const which =
            state.survivor.point_format === 'natural' ? 'winning_pick' : 'winning_pick_line'
          const winningPick = liveFixture[which]
          winnerTeam = 'tie'

          if (winningPick === 'home') {
            winnerTeam = liveFixture.home_team
          }
          if (winningPick === 'away') {
            winnerTeam = liveFixture.away_team
          }

          if (currentPick.pick === winnerTeam.id) {
            currentPick.correct = true
            currentPick.strike = false
          } else {
            if (!state.survivor.strikes_enabled) {
              if (currentPick.pick !== winnerTeam.id && winnerTeam === 'tie') {
                currentPick.correct = true
                currentPick.strike = false
              }
              if (currentPick.pick !== winnerTeam.id && winnerTeam !== 'tie') {
                currentPick.correct = false
                currentPick.strike = false
              }
            } else {
              if (state.survivor.point_format === 'natural') {
                if (currentPick.pick !== winnerTeam.id && winnerTeam !== 'tie') {
                  currentPick.correct = false
                  currentPick.strike = false
                }
                if (currentPick.pick !== winnerTeam.id && winnerTeam === 'tie') {
                  currentPick.correct = false
                  currentPick.strike = true
                }
              } else {
                // line

                if (currentPick.pick !== winnerTeam.id && winnerTeam !== 'tie') {
                  currentPick.correct = false
                  currentPick.strike = true
                }
                if (currentPick.pick !== winnerTeam.id && winnerTeam === 'tie') {
                  currentPick.correct = false
                  currentPick.strike = true
                }
              }
            }
          }
          temporalEntryData.survivor_picks[currentWeek] = currentPick
        }
        /**
         * Replace the live results of the fixture
         * */
        const liveResults = socketLiveResults[tempCurrentFixture.feed_id]

        if (liveResults) {
          if (!tempCurrentFixture.hasOwnProperty('stats')) {
            tempCurrentFixture.stats = {}
          }
          tempCurrentFixture.stats = liveResults
          tempCurrentFixture.stats.quarter = liveResults.hasOwnProperty('quarter')
            ? liveResults.quarter
            : null

          tempCurrentFixture.stats.timer = liveResults.timer
          tempCurrentFixture.stats.score_home_first_half = liveResults.score_home_first_half
          tempCurrentFixture.stats.score_away_first_half = liveResults.score_away_first_half
          tempCurrentFixture.events = {}
          tempCurrentFixture.events = liveResults.events
        }
        tempFixtures[fixtureIdx] = tempCurrentFixture
      }) // end foreach fixtures from state
    }) // end foreach fixtures from socket

    setState({
      fixtures: tempFixtures,
      selectedEntryData: temporalEntryData
    })
  }

  // Migrated from survivorUtils.handleCloseSurvivor.bind(this);
  const handleCloseSurvivor = (survivorCloseData) => {
    // console.log('%c Survivor close Update','color:rgb(223, 75, 38); font-size:2em;');
    // console.log( survivorCloseData );

    if (!state.survivor) {
      return
    }
    if (!state.standings) {
      return
    }

    const tempSurvivorInfo = state.survivorInfo
    const tempStandings = state.standings
    const nextStandings = survivorCloseData.standings
    const prevStandings = state.standings.items
    const currentEntriesData = state.entriesInfo

    if (!survivorCloseData && survivorCloseData.survivor) {
      return
    }
    /**
     * If the closePool is the same as the current poolInfo
     * update the status, the upcoming and closed boolean
     * values in the  mainState
     */
    if (survivorCloseData.survivor !== tempSurvivorInfo.survivor.id) {
      return
    }

    tempSurvivorInfo.survivor.closed = true
    tempSurvivorInfo.survivor.status = 'closed'
    tempSurvivorInfo.survivor.upcoming = false

    /**
     * Update the Standings with the final Standings
     * after the close action
     */

    tempStandings.items.forEach((prevStanding, prevStandingIdx) => {
      nextStandings.forEach((nextStanding, nextStandingIdx) => {
        if (nextStanding.survivor_entry_id !== prevStanding.survivor_entry_id) {
          return
        }
        // prevStanding['total_possibly_correct'] = nextStanding.points;
        prevStanding.points = nextStanding.points
        prevStanding.rank = nextStanding.rank
        prevStanding.strikes = nextStanding.strikes
        prevStanding.alive = nextStanding.alive
        prevStanding.user_prize.base_amount = nextStanding.base_amount
        prevStanding.user_prize.amount = nextStanding.prize_amount
        prevStanding.user_prize.tax = nextStanding.tax
        prevStanding.prize_amount = nextStanding.prize_amount
        prevStandings[prevStandingIdx] = prevStanding
      })
    })

    // console.log('Closed Pool');
    // Reassign the new standigns

    // prevStandings = updateStandingsAganar( prevStandings, state.survivor.prize_currency );
    tempStandings.items = prevStandings

    tempStandings.items.sort(
      firstBy('rank')
        .thenBy('alive', -1)
        .thenBy('open_picks', -1)
        .thenBy('strikes')
        .thenBy('pick_count', -1)
        .thenBy('survivor_entry_id')
    )
    // Update Entry data
    // currentEntriesData.entry = utils.updateEntry( currentEntriesData.entry, closedPool.standings );

    console.log('CloseUpdatePoolSubscription')
    setState(
      {
        survivorEntries: currentEntriesData,
        survivorInfo: tempSurvivorInfo,
        standings: tempStandings
      }
    )
  }

  // Migrated from fixtureCloseSocket = survivorUtils.fixtureCloseSocket.bind(this);
  const fixtureCloseSocket = (fixtureData) => {
    if (!state.fixtures) {
      return
    }

    let fixtureIdx = null
    const tempFixtures = state.fixtures
    const updatedFixture = state.fixtures.find((fixture, fixtureIndx) => {
      if (fixture.id === fixtureData) {
        fixtureIdx = fixtureIndx
        return fixture
      }
    })

    if (updatedFixture === -1 || updatedFixture === undefined) {
      return
    }

    updatedFixture.finished = true
    updatedFixture.sort_status = 2
    updatedFixture.status = 'closed'

    tempFixtures[fixtureIdx] = updatedFixture

    setState({ fixtures: tempFixtures })
  }

  // migrated from standingsUpdateSocket = survivorUtils.standingsUpdateSocket.bind(this);
  const standingsUpdateSocket = (standingsUpdateData) => {
    // console.log('%c standings Update','color:rgb(46, 140, 207); font-size:2em;');
    // console.table(state.standings.items);
    // console.log(standingsUpdateData.standings);
    // check not loaded
    if (!state.survivorInfo) {
      return
    }
    if (standingsUpdateData.survivorId !== state.survivorInfo.survivor.id) {
      return
    }

    if (!state.standings) {
      return
    }

    let tempStandings = state.standings.items.map((standing) => {
      const updated = standingsUpdateData.standings.find(
        (updatedStanding) => updatedStanding.survivor_entry_id === standing.survivor_entry_id
      )
      if (!updated) {
        return standing
      }
      return {
        ...standing,
        ...{
          rank: updated.rank,
          alive: updated.alive,
          elimination_week_id: updated.elimination_week_id,
          elimination_contest_id: updated.elimination_contest_id,
          strikes: updated.strikes,
          strikes_available: updated.strikes_available,
          open_picks: updated.open_picks,
          mod: Date.now()
        }
      }
    })

    const tempSelectedEntryData = state.selectedEntryData

    const updatedTiesDistribution = updatePrizeDistribution(
      standingsUpdateData.ties_distribution,
      state.survivorInfo.survivor.available,
      state.prizes
    )

    if (updatedTiesDistribution) {
      setState({ tiesDistribution: updatedTiesDistribution }, () => {
        tempStandings = updateStandingsAganar(
          tempStandings,
          state.survivorInfo.survivor.entry_currency,
          state.tiesDistribution,
          state.prizes,
          state.survivorInfo.survivor
        )
      })
    }

    /*******************************
     * Recalculate the entry Prize *
     *******************************/
    if (state.withRegister) {
      // setState({
      //   submitting: true
      // });
      // updateCurrentEntry();
      // console.log( 'TSED', tempSelectedEntryData );
      standingsUpdateData.standings.forEach((position) => {
        if (position.survivor_entry_id === state.selectedEntryData.id) {
          if (!tempSelectedEntryData.hasOwnProperty('survivor_standing')) {
            return
          }
          tempSelectedEntryData.survivor_standing.strikes = position.strikes
          tempSelectedEntryData.survivor_standing.rank = position.rank
          tempSelectedEntryData.survivor_standing.points = position.points
          tempSelectedEntryData.survivor_standing.posible_points = position.possible_points
          tempSelectedEntryData.alive = position.alive

          // tempSelectedEntryData.survivor_standing.user_prize                = position.user_prize;
          // tempSelectedEntryData.user_prize                                  = position.user_prize;
          // tempSelectedEntryData.user_prize                         = position.user_prize;
          // tempSelectedEntryData.user_prize                         = position.user_prize;
        }
      })
    }

    const newStadings = state.standings
    newStadings.items = tempStandings
    // tempStandings["items"] = standingsUpdateData.standings;

    setState(
      {
        standings: newStadings
      },
      () => {
        setState(
          {
            standingsChanged: true
          },
          () => {
            setTimeout(() => {
              console.log()
              setState({
                standingsChanged: false
                // submitting:false,
              })
            }, 500)
          }
        )
      }
    )
  }

  // Soccer y NFL
  const initializeSocket = () => {
    console.log('%c Initialize Socket', 'color:rgb(46, 140, 207); font-size:2em;')

    API.subscribeSocketLiveSurvivor(
      state.survivorId,
      (fixtureUpdateData) => {
        SubscriptionRxs.announceFixturesUpdated(fixtureUpdateData)
        handleFixtureUpdateSocket(fixtureUpdateData)
      },

      (closeSurvivorData) => {
        handleCloseSurvivor(closeSurvivorData)
      },

      (fixtureCloseData) => {
        SubscriptionRxs.announceFixtureCloseUpdated(fixtureCloseData)
        fixtureCloseSocket(fixtureCloseData)
      },

      (standingsUpdatedData) => {
        SubscriptionRxs.announceSurvivorStandingsUpdatedsUpdated(standingsUpdatedData)
        standingsUpdateSocket(standingsUpdatedData)
      },

      (statsData) => {
        if (statsData.survivorId !== props?.survivorInfo?.id) { return }
        // Soccer and NFL
        if (props.survivorInfo.sport_id === 1 || props.survivorInfo.sport_id === 2) {
          const weekId = statsData.weekId
          const stats = statsData.pickStats

          if (state.allFixtures?.pickStats) {
          //  state.allFixtures.pickStats[weekId] = stats

            setState(state => ({
              ...state,
              allFixtures: {
                ...state.allFixtures,
                pickStats: {
                  ...state.allFixtures.pickStats,
                  [weekId]: stats
                }
              }
            }))
          }
        }
      }
    )
  }


  useEffect(() => {
    if (state?.currentContest?.id && state?.mounted && (state?.survivor?.sport_id === 6 || state?.survivor?.sport_id === 7)) {
      initializeContestsSocket()
    }
  }, [state?.currentContest?.id, state?.mounted])

  // Golf y F1
  // Migrated from updatePlayerResults = survivorUtils.playersUpdateSocket.bind(this);
  const updatePlayerResults = (data)  => {
    const playerResults = data.player_results ?? [];

    let players = state.players.map((player) => {
      let res = playerResults.find((_player) => player.id === _player.player_id);
      if (!res) {
        return player;
      }

      player = {
        ...player,
        ...{
          position: res.position,
          score: res.score,
          real_score: res.real_score,
          score_num: res.score_num,
          data: res.data,
        },
      };

      return player;
    });

    players = players.sort(
      firstBy('score_num')
        .thenBy('position')
        .thenBy('name')
    );

    setState({ players })

    let currentContest = state.currentContest;
    let temporalEntryData = props.selectedEntryData;

    let currentPick =  temporalEntryData !== null ? temporalEntryData.survivor_picks[currentContest.id] : null;
    const regExp = /AM|PM/;
    if (currentPick) {
      let player = playerResults.find((_player) => _player.player_id === currentPick.pick);
      // console.log( player, !regExp.test(player.data.hole) || player.position !== null );
      if (player && (!regExp.test(player.data.hole) || player.position !== null)) {
        currentPick.pr.position = player.position;
        currentPick.pr.score = player.score;
        currentPick.pr.real_score = player.real_score;
        currentPick.pr.score_num = player.score_num;
        currentPick.pr.data = player.data;
        currentPick.modified = Date.now();

        let eliminationType = currentContest.elimination_type;
        let threshold = currentContest.elimination_threshold;
        let currentContestId = currentContest.id;

        if (eliminationType === 'cut') {
          if (player.position === '' || player.position === 'null' || player.position === null) {
            currentPick.correct = false;
            currentPick.strike = true;
          } else {
            currentPick.correct = true;
            currentPick.strike = false;
          }
        } else {
          if (player.position === null || player.position >= threshold) {
            currentPick.correct = false;
            currentPick.strike = true;
          } else {
            currentPick.correct = true;
            currentPick.strike = false;
          }
        }

        temporalEntryData.mod = Date.now();
        temporalEntryData.survivor_picks[currentContestId] = currentPick;


        // Update the entry data
        props.updateSelectedEntryData(temporalEntryData);
      }
    }
  }

  const updateRaceResults = () => {
    const playerResults = data.race_results;

    let players = state.players.map((player) => {
      let res = playerResults.find((_player) => parseInt(player.id) === parseInt(_player.player_id));
      if (!res) {
        return player;
      }
      player.finish_pos = res.finish_pos;
      player.laps = res.laps;
      player.laps_num = res.laps_num;
      player.pit = res.pit;
      player.time = res.time;

      return player;
    });

    players = players.sort(
      firstBy('finish_pos')
    );

    setState({ players });

    let currentContest = state.currentContest;
    let temporalEntryData = props.selectedEntryData;
    let currentPick = temporalEntryData.survivor_picks[currentContest.id];

    if (currentPick && currentPick.pick ) {
      let player = playerResults.find((_player) => _player.player_id === currentPick.pick);
      // console.log( player, !regExp.test(player.data.hole) || player.position !== null );
      if (player && player.finish_pos != null) {

        currentPick.rr.finish_pos = player.finish_pos;
        currentPick.rr.time       = player.time;
        currentPick.rr.laps       = player.laps;
        currentPick.rr.laps_num   = player.laps_num;
        currentPick.rr.pit        = player.pit;
        currentPick.modified = Date.now();

        let threshold = state.survivor.elimination_threshold; // ?? sacar del survivor
        let currentContestId = currentContest.id;

        // marcar como eliminados si el piloto no queda en el umbral definido
        if ( player.finish_pos === null || player.finish_pos > threshold) {
          currentPick.correct = false;
          currentPick.strike  = true;
        } else {
          currentPick.correct = true;
          currentPick.strike  = false;
        }

        temporalEntryData.mod = Date.now();
        temporalEntryData.survivor_picks[currentContestId] = currentPick;

        // Update the entry data
        props.updateSelectedEntryData(temporalEntryData);
      }
    }
  
  }

  // Golf y F1
  const initializeContestsSocket = () => {
    API.subscribeLiveSurvivorContestSocket(
      state.survivorId,
      state.currentContest.id,
      (standingsUpdatedData) => {
        console.log('standings:update');
        SubscriptionRxs.announceSurvivorStandingsUpdatedsUpdated(standingsUpdatedData);
        SubscriptionRxs.announceSurvivorStandingsUpdatedsUpdatedF1(standingsUpdatedData);
        standingsUpdateSocket(standingsUpdatedData);
      },
      (closeSurvivorData) => {
        handleCloseSurvivor(closeSurvivorData);
      },
      // Golf
      (playerData) => {
        SubscriptionRxs.announceGroupPoolPlayersUpdated(playerData);
        updatePlayerResults(playerData);
      },
      // F1
      (raceData) => {
        SubscriptionRxs.announceGroupPoolPlayersUpdatedF1(raceData);
        updateRaceResults(raceData);
      }
    );
  };


  /**
     * This function get the Corresponding data for the
     * selected survivor game when the user has an available entry
     *
     * @param { Number } survivorId - The id of the survivorGame
     * @param { Number }survivorEntryId - The id of the Entry that the user request
     *
     */
  const getWithRegisterData = (survivorId, survivorEntryId) => {
    console.log('With Register')
    /**
         * Update user Data
         * fetchTickets
         * fetch SurvivorEntries
         * fetch survivorDetails
         * fetchWeek
         * fetch fixtures
         * fetch standings
         * fetch standingsGroup
         * fetch prizes
         * fetch Similar Entries
         */
    axios
      .all([
        API.fetchUserData(),
        API.fetchTickets(survivorId, 'survivor'),
        API.fetchSurvivorDetails(survivorId),
        API.fetchSurvivorEntry(survivorEntryId),
        API.fetchSurvivorTeams(survivorId),
        API.fetchSurvivorStandings(survivorId, state.queryParams),
        API.fetchSurvivorAllFixtures(survivorId)
      ])
      .then(
        axios.spread(
          (
            userData,
            tickets,
            survivorResponse,
            survivorEntry,
            survivorTeams,
            survivorStandings,
            allFixtures
          ) => {
            const responses = [
              userData,
              tickets,
              survivorResponse,
              survivorEntry,
              survivorTeams,
              survivorStandings,
              allFixtures
            ]
            try {
              utils.checkAxiosAllErrors(responses)
            } catch (e) {
              console.error(e)
              Sentry.captureException(e)
            }

            const { survivor, currentWeek, previousWeek } = survivorResponse.data

            const balanceEnough = utils.isBalanceEnough(
              survivor.entry_currency,
              survivor.entry_fee
            )

            setState({
              enoughBalance: !(!balanceEnough && !tickets.length),
              survivor,
              survivorId,
              standings: survivorStandings.data,
              survivorEntries: survivorEntry.data,
              selectedEntryData: survivorEntry.data.survivor_entry,
              selectedEntryId: survivorEntryId,
              prizes: survivorResponse.data.survivor_prizes,
              logged: utils.isLogged(),
              ticketsAvailable: null,
              userData: userData.data,
              withRegister: true,
              tiesDistribution: survivorUtils.updatePrizeDistribution(
                survivorResponse.data.ties_distribution,
                survivor.available,
                survivorResponse.data.survivor_prizes
              ),
              allFixtures: allFixtures.data,
              previousWeek
            })

            if (survivor.sport_id === 1 || survivor.sport_id === 2) {
              API.fetchSurvivorFixtures(currentWeek.id)
                .then((responseFixtures) => {
                  setState(
                    {
                      selectedWeek: currentWeek.id,
                      currentWeek,
                      firstOf: responseFixtures.data.firstOf,
                      fixtures: responseFixtures.data.fixtures,
                      survivorTeams: survivorTeams.data.teams,
                      survivorWeeks: survivor.weeks,
                      loadedPicks: true,
                      mounted: true,  
                      dataLoaded: true
                    },
                    () => {
                      // setTimeout(props.onDataLoaded, 500)
                      if (props.survivorInfo.status === 'live' && state?.standings?.items) {
                        const temporalStands = survivorUtils.updateStandingsAganar(
                          state.standings.items,
                          state.survivor.prize_currency,
                          state.tiesDistribution,
                          state.prizes,
                          state.survivor
                        )

                        const tempStandings = state.standings
                        tempStandings.items = temporalStands

                        const myTemporalEntry = state.selectedEntryData

                        myTemporalEntry.user_prize = myTemporalEntry.survivor_user_prize

                        setState(
                          {
                            selectedEntryData: myTemporalEntry,
                            standings: tempStandings,
                            standingsChanged: true
                          },
                          () => {
                            setState({
                              standingsChanged: false
                            })
                          }
                        )
                      }

                      initializeSocket()
                    }
                  )
                })
                .catch((error) => {
                  console.error(error)
                })
            }
            // Golf y F1
            if (survivor.sport_id === 6 || survivor.sport_id === 7) {
              let currentContest = survivorResponse.data.currentContest;
              API.getSurvivorContestPlayers(currentContest.id).then(
                (response) => {
                  setState(
                    {
                      currentContest: currentContest,
                      players: response.data.players,
                      mounted: true,
                      dataLoaded: true
                    })
                }
              );
            }
          }
        )
      )
      .catch((error) => {
        console.error(error)
      })
  }

  useEffect(() => {
    if (props?.survivorInfo?.id && props?.selectedEntryData?.id) {
      getWithRegisterData(props?.survivorInfo?.id, props?.selectedEntryData?.id)
    }
  }, [props?.survivorInfo?.id, props?.selectedEntryData?.id])

  const closeRegisterPanel = () => {
    setState({
      showRegisterPanel: false
    })
  }

  const handleConfirmation = (response) => {
    closeRegisterPanel()
    setState(
      {
        guardarPicksStatus: true,
        currentPicksSaved: 0
      },
      () => {
        setTimeout(() => {
          setState({
            guardarPicksStatus: false
          })
        }, 1000)
      }
    )
  }

  const handleError = (errorMessage) => {
    Sentry.captureException(errorMessage, { extra: 'Error Saving SurvivorPicks' })
    setState(
      {
        errorSavePicks: true,
        errorMessage
      },
      () => {
        setTimeout(() => {
          setState({
            errorSavePicks: false
          })
        }, 1800)
      }
    )
  }

  /**
   * This function receives the data fetched from the server
   * and handles the type of data
   * @param { Object } data - data response frm the server after save picks
   */
  const callbackGuardarPicks = (response) => {
    /**
     * If the response is success this snippet handles
     * the Overlay and the confirmation of the saved picks
     */
    if (response.data.success || response.data.survivor_entry) {
      handleConfirmation()
    } else {
      /**
       * If the response isnt successfull this snippet handles
       * the Overlay and the error message after saved picks
       */
      handleError(response.data.error)
    }

    setState(
      {
        submitting: false
      },
      () => {
        if (props.refreshAfterGuardarInLobby) {
          props.refreshAfterGuardarInLobby(response.data)
        }
        if (props.isPopUp) {
          handleRedirectToLobby(response.data.survivorEntries[0].id)
        }
      }
    )
  }

  /**
   * This function sends the Entry object to the server
   * to save the entry
   */
  const guardarPicksSurvivor = (type) => {
    // console.log('%c Guardar Pick ', 'font-size:2em; color: #FEC830;');
    // console.table( state.myEntry );
    if (type === undefined) {
      setState({
        submitting: true
      })
    }

    API.postSaveSurvivorPicks(state.myEntry)
      .then((response) => {
        // console.log(response);
        callbackGuardarPicks(response)
      })
      .catch((error) => {
        /**
         * If the API return an error this snippet handles
         * the Overlay and the error message of the saved picks
         */
        // console.error(error);
        Sentry.captureException(error, { extra: 'Error Saving picks' })
        handleError('Encontramos un error en tu petición.')
        setState({
          submitting: false
        })
      })
  }

  /**
     * Get the entry info to use it in the Register Panel
     * @param { Object } temporalEntry - The object that will be updated in the state
     *
     */
  const updateTemporalRegisterPicks = (temporalEntry) => {
    console.log('updateTemporalRegisterPicks', temporalEntry)
    setState({ temporalRegisterPicks: temporalEntry })
  }

  /**
   * This function handles the clicked pick and updates the state of the entry
   * @param {Number} weekId - this is the key to update the pick
   * @param {Number} teamId - this is the new value of the pick
   */

  const handlePick = (teamId, weekId, fixtureId) => {
    // ------
    console.log('%c Handle Pick ', 'font-size:2em; color: #05acee;')
    const temporalEntry = state.myEntry
    const temporalAvTeams = state.availableTeams

    if (
      !temporalEntry.survivor_picks.length < 1 ||
      !temporalEntry.survivor_picks[weekId] ||
      temporalEntry.survivor_picks[weekId].pick === teamId
    ) {
      return
    }

    const avTeamsKeys = Object.keys(temporalAvTeams)

    /**
     * Deselect the previous week selected
     */
    avTeamsKeys.forEach((teamKey) => {
      if (temporalAvTeams[teamKey].week_id === weekId && temporalAvTeams[teamKey].selected) {
        temporalAvTeams[teamKey].selected = false
        temporalAvTeams[teamKey].week_id = null
      }
    })

    temporalAvTeams[teamId].selected = true
    temporalAvTeams[teamId].week_id = weekId

    temporalEntry.survivor_picks[weekId].pick = teamId
    temporalEntry.survivor_picks[weekId].fixture_id = fixtureId

    setState(
      {
        myEntry: temporalEntry,
        availableTeams: temporalAvTeams
      },
      () => {
        if (updateTemporalRegisterPicks) {
          updateTemporalRegisterPicks(state.myEntry)
        }
      }
    )

    guardarPicksSurvivor('handle')
  }

  const resetPicks = (e) => {
    if (e) {
      e.preventDefault()
    }

    const currentEntry = !props.selectedEntryData ? null : props.selectedEntryData

    // Esta funcion debe devolver el id del pick de match seleccionado si es el primer
    // partido y el usuario lo selecciono o si selecciono otro que no es el primero y ya
    // paso la fecha del fixture.time_left === 0 si alguna de estas dos condiciones
    // no se cumplen devolver null
    const getSurvivorPicks = () => {
      return Object
        .entries(state.myEntry.survivor_picks)
        .map(([weekId, survivor_pick]) => {
          const pick = survivor_pick.pick
          const fixture = state.allFixtures.fixtures[weekId]
          const firstMatch = fixture[0]
          const isUserSelectedFirstMatch = firstMatch.home_team.id === pick || firstMatch.away_team.id === pick

          const isPassedDeadlineWeek = fixture?.time_left <= 0

          // Fecha de cuando paso el primer partido
          const isPassedFirstMatchDate = firstMatch?.survivor_time_left <= 0

          let isDisabled = isPassedDeadlineWeek

          // Soccer
          if (props.survivorInfo.sport_id === 1 && isPassedFirstMatchDate) {
            isDisabled = true
          } else {
            // NFL
            if (isPassedFirstMatchDate && isUserSelectedFirstMatch) {
              isDisabled = true
            } else if (!isUserSelectedFirstMatch && isPassedDeadlineWeek) {
              isDisabled = true
            }
          }

          return { ...survivor_pick, pick: isDisabled ? pick : null }
        })
        .reduce((a, b) => ({ ...a, [b.week_id]: b }), {})
    }

    const survivorPicks = getSurvivorPicks()

    const data = {
      survivor_entry_id: currentEntry.id,
      survivor_id: props.survivorInfo.id,
      survivor_picks: survivorPicks
    }

    if (currentEntry) {
      API.resetAllPicks(currentEntry.id, props.survivorInfo.id, data)
        .then(res => {})
    }

    state.myEntry = {
      ...state.myEntry,
      survivor_picks: survivorPicks
    }

    guardarPicksSurvivor('unset')
  }

  const removePick = (pickId, weekId) => {
    state.myEntry = {
      ...state.myEntry,
      survivor_picks: {
        ...state.myEntry.survivor_picks,
        [weekId]: {
          ...state.myEntry.survivor_picks[weekId],
          pick: null
        }
      }
    }

    const currentEntry = !props.selectedEntryData ? null : props.selectedEntryData

    const data = {
      survivor_id: props.survivorInfo.id,
      survivor_entry_id: currentEntry.id,
      pick_id: pickId
    }

    if (currentEntry) {
      API.unsetPick(pickId, currentEntry.id, props.survivorInfo.id, data)
        .then(res => {
          guardarPicksSurvivor('unset')
        })
    }
  }

  const initializeTeams = () => {
    const newTeams = {}

    state.survivorTeams.forEach((team) => {
      newTeams[team.id] = {
        selected: false,
        week_id: null
      }
    })

    setState(
      {
        availableTeams: newTeams
      },
      () => {
        initializePicks()
      }
    )
  }

  const handleRedirectToLobby = (entryToRedirect) => {
    console.log('Handle!', entryToRedirect)
    setState({
      redirectToLobby: true,
      redirectToEntry: entryToRedirect
    })
  }

  /**
   * This function initialize the model in the state
   * to handle correctly the pick selected
   * @param { Array } weeks - the array of the available weeks
   * @param { Object } entries - the array of the available entries
   */
  const initializePicks = () => {
    const weeks = state.survivorWeeks
    // console.log('%c Initialize Picks','color:#2E8CCF; font-size:2em;');
    // console.log('weeks',weeks);
    // console.log('entry',entries, props);
    const temporalPicks = {}
    const temporalAvTeams = state.availableTeams
    // myEntry: {
    //   id: null,
    //   survivor_id: null,
    //   survivor_entry_id: null,
    //   user_id: null,
    //   survivor_picks: null,
    // },
    const temporalEntry = state.myEntry

    /**
     * Create a pick for every Week
     */
    weeks.forEach((week, index) => {
      // console.log(week);
      let currentPick = null

      if (
        // props.withRegister &&
        props.selectedEntryData?.survivor_picks !== undefined &&
        props.selectedEntryData?.survivor_picks !== null
      ) {
        // console.log(typeof(props.selectedEntryData))
        if (typeof props.selectedEntryData === 'Array') {
          currentPick = !props.selectedEntryData?.survivor_picks[0]
            ? null
            : props.selectedEntryData?.survivor_picks[0].pick
        } else {
          currentPick = !props.selectedEntryData?.survivor_picks[week.id]
            ? null
            : props.selectedEntryData?.survivor_picks[week.id].pick
        }
        if (currentPick && temporalAvTeams) {
          console.log(currentPick)
          temporalAvTeams[currentPick].selected = true
          temporalAvTeams[currentPick].week_id = week.id
        }
      }

      temporalPicks[week.id] = {
        id: null,
        survivor_id: Number(props?.survivorInfo?.id),
        survivor_entry_id: props.selectedEntryData?.id,
        user_id: !props.userData ? null : props.userData.id,
        pick:
        // props.withRegister &&
            currentPick !== -1 &&
            currentPick !== null &&
            props.selectedEntryData?.survivor_picks[week.id] !== null
              ? props.selectedEntryData?.survivor_picks[week.id].pick
              : null,
        week_id: week.id,
        fixture_id:
        // props.withRegister &&
            currentPick !== -1 &&
            currentPick !== null &&
            props.selectedEntryData?.survivor_picks[week.id] !== null
              ? props.selectedEntryData?.survivor_picks[week.id].fixture_id
              : null
      }
    })

    temporalEntry.user_id = Number(!props.userData ? null : props.userData.id)
    temporalEntry.survivor_id = Number(props?.survivorInfo?.id)
    temporalEntry.survivor_picks = temporalPicks
    temporalEntry.survivor_entry_id = Number(props.selectedEntryData?.id)

    setState({
      myEntry: temporalEntry
    })
  }

  useEffect(() => {
    if (state.dataLoaded) {
      console.log(state)

      if (props.survivorInfo.sport_id === 1 || props.survivorInfo.sport_id === 2) {
        initializeTeams()
      }
    }
  }, [state.dataLoaded])

  /**
     * This function updates the State of the allFixtures
     * when the week just started (time_left === 0)
     * @param fixtureID
     */
  const updateFirstOfWeek = (firstOfWeek) => {
    setState(state => ({
      ...state,
      allFixtures: {
        ...state.allFixtures,
        firstsOfWeek: {
          ...state.allFixtures.firstsOfWeek,
          [firstOfWeek.week_id]: {
            ...state.allFixtures.firstsOfWeek[firstOfWeek.week_id],
            enabled: 0
          }
        }
      }
    }))
  }

  /**
     * This function updates the State
     * of the fixture thtat just started
     * @param fixtureID
     */
  const updateStartedFixture = (fixtureID, firstOfWeek) => {
    // Used for Soccer and NFL
    if (firstOfWeek) {
      return updateFirstOfWeek(firstOfWeek)
    }

    let indexStartedFixture
    let tempFixturesList = state.fixtures

    const currentPick = state.selectedEntryData[state.currentWeek]

    const startedFixt = tempFixturesList.find((fixt, i) => {
      if (fixt.id === fixtureID) {
        indexStartedFixture = i
        return fixt
      }
    })

    if (!startedFixt || !startedFixt.enabled) {
      return
    }

    startedFixt.sort_status = 0
    startedFixt.enabled = false
    startedFixt.enabled_survivor = false

    tempFixturesList[indexStartedFixture] = startedFixt

    if (currentPick && currentPick.fixture_id === fixtureID) {
      console.log('My pick is the same as the closed fixture')
      tempFixturesList = tempFixturesList.map((fixture) => {
        fixture.enabled = false
        fixture.enabled_survivor = false
      })
    }

    setState({
      fixtures: tempFixturesList
    })
  }

  /**
     * This function updates the State of the first match
     * when the first match just started
     * @param fixtureID
     */
  const updateStartedFirstMatchOfWeek = (firstMatch) => {
    setState(state => ({
      ...state,
      allFixtures: {
        ...state.allFixtures,
        fixtures: {
          ...state.allFixtures.fixtures,
          [firstMatch.week_id]: state.allFixtures.fixtures[firstMatch.week_id].map(fixture => {
            if (fixture.id === firstMatch.id) {
              return {
                ...fixture,
                survivor_time_left: 0,
                enabled_survivor: false
              }
            }

            return fixture
          })
        }
      }
    }))
  }

  // Regresa un Booleano si se debe mostrar o no el boton de borrar todos los picks
  // Basandose en si existen picks que el usurio puede eliminar (que no hayan comenzado)
  // Si tiene picks que ya pasaron el boton no se reveria de mosstrar
  const getShowRemovePicks = () => {
    const { myEntry, allFixtures } = state
    const { survivorInfo } = props

    if (!myEntry?.survivor_picks) return

    return Boolean(Object
      .entries(myEntry.survivor_picks)
      .map(([weekId, survivor_pick]) => {
        const pick = survivor_pick.pick
        const fixture = allFixtures.fixtures[weekId]
        const firstMatch = fixture[0]
        const isUserSelectedFirstMatch = firstMatch.home_team.id === pick || firstMatch.away_team.id === pick

        const isPassedDeadlineWeek = fixture?.time_left <= 0

        // Fecha de cuando paso el primer partido
        const isPassedFirstMatchDate = firstMatch?.survivor_time_left <= 0

        let isDisabled = isPassedDeadlineWeek

        // Soccer
        if (survivorInfo.sport_id === 1 && isPassedFirstMatchDate) {
          isDisabled = true
        } else {
          // NFL
          if (isPassedFirstMatchDate && isUserSelectedFirstMatch) {
            isDisabled = true
          } else if (!isUserSelectedFirstMatch && isPassedDeadlineWeek) {
            isDisabled = true
          }
        }

        return !isDisabled ? pick : null
      })
      .find(item => item))
  }

  const showRemovePicks = getShowRemovePicks()

  const showConfirmAll = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setShowConfirmDelete(true)
  }

  const onConfirm = () => {
    resetPicks()
    setShowConfirmDelete(false)
  }

  const onClose = () => {
    setShowConfirmDelete(false)
  }
  

  if (!state.dataLoaded) {
    return <Loading />
  }

  // Soccer y NFL
  if (props.survivorInfo.sport_id === 1 || props.survivorInfo.sport_id === 2) {
    return (
      <Panel data-pick-selector='true'>
        {/* Status Survivor */}
        <HeaderPicks>
          <span>SEMANA </span>
          <span>PICK </span>
  
          <ConfirmModal
            show={showConfirmDelete}
            onConfirm={onConfirm}
            onClose={onClose}
            title='REINICIAR PICKS'
            message='¿Estás seguro que deseas borrar tus picks?'
            message2='Se perderán todos tus picks y no podrás recuperarlos.'
          />
  
          <span>
            {showRemovePicks && (
              <ResetButton onClick={showConfirmAll}>BORRAR PICKS</ResetButton>
            )}
          </span>
  
          <span>CIERRE </span>
  
        </HeaderPicks>
        <PicksSoccerNFL
          survivorWeeks={state.survivorWeeks}
          allFixtures={state.allFixtures}
          handlePick={handlePick}
          removePick={removePick}
          currentEntry={props.selectedEntryData}
          teams={state.survivorTeams}
          pointFormat={props.survivorInfo.point_format}
          updateStartedFixture={updateStartedFixture}
          survivorInfo={props.survivorInfo}
          survivorEntryData={state.myEntry}
          currentWeek={state.currentWeek}
          previousWeek={state.previousWeek}
          updateStartedFirstMatchOfWeek={updateStartedFirstMatchOfWeek}
        />
  
        <ConfirmationOverlay
          isError
          showOverlay={state.errorSavePicks}
          label='Error'
          errorMessage={state.errorMessage}
        />
  
        <ConfirmationOverlay
          currentSavedPicks={state.currentsavedPicks}
          label='!Felicidades, tu pick de la semana se guardo con éxito!'
          showOverlay={state.guardarPicksStatus}
          type='survivor'
        />
      </Panel>
    )
  }

  // Golf y F1
  if (props.survivorInfo.sport_id === 6 || props.survivorInfo.sport_id === 7) {
    return (
      <div>
        <SurvivorContestUI
          isPopUp={false}
          players={state.players}
          survivor={props.survivorInfo}
          entry={props.selectedEntryData}
          currentContest={state.currentContest}
          updateUserInfo={props.updateUserInfo}
          showAbonarPanel={props.showAbonarPanel}
          updateTemporalRegisterPicks={updateTemporalRegisterPicks}
          refreshAfterGuardarInLobby={props.refreshAfterGuardarInLobby}
        />
      </div>
    )
  }

  return null
}

const Panel = styled.div`
  display: flex;
  flex-direction: column;
`
