import React, { useEffect, useState } from 'react';
import avatar from '../../assets/images/avatar-50.png';
import styled from 'styled-components';

const SurvivorContestPick = ({
  pick,
  currentContest,
  contest,
  players,
  eliminationContestId,
  alive,
  togglePlayerList,
}) => {
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [playerList, setPlayerList] = useState(players);

  useEffect(() => {
    if (pick !== undefined && pick.pick) {
      let player = playerList.find((player) => player.id === pick.pick);
      //let playerIdx = playerList.findIndex((player) => player.id === pick.pick);
      setSelectedPlayer(() => player);
    }
    // terrible way...
  }, [JSON.stringify(pick)]);

  useEffect(() => {
    setPlayerList(() => {
      return players;
    });

    if (pick !== undefined && pick.pick) {
      let player = playerList.find((player) => player.id === pick.pick);
      setSelectedPlayer(player);
    }
  }, [players]);

  const onRowClicked = () => {
    if (contest.status === 'upcoming') {
      togglePlayerList();
    }
  };

  let pickClass = null;
  if (pick) {
    if (contest.status !== 'upcoming' && pick.correct) {
      pickClass = 'correct';
    }

    if (contest.status !== 'upcoming' && !pick.correct) {
      // && ( !pick.strike || eliminationContestId === contest.id ) ) {
      pickClass = 'incorrect';
    }

    if (!pick.correct && pick.strike && contest.id !== eliminationContestId) {
      pickClass = 'strike';
    }
  }

  return (
    <div className="survivor-contest-pick" >
      {selectedPlayer === null ? (
        alive && contest.id === currentContest.id && contest.status === 'upcoming' ? (
          <div
            style={{ padding: '4px', display: 'flex', alignItems: 'center', minHeight: '47px' }}
            onClick={() => onRowClicked()}
          >
            <div className="flex-col">ELIGE JUGADOR</div>
          </div>
        ) : null
      ) : (
          <React.Fragment>
            {contest.id === currentContest.id ? (
              <div
                className={pickClass}
                style={{ padding: '4px', display: 'flex', alignItems: 'center', minHeight: '47px' }}
                onClick={() => onRowClicked()}
              >
                <div className="flex-col col-20 col-no-padding">
                  <img
                    className="player-avatar"
                    src={
                      selectedPlayer.avatar === null ? avatar : selectedPlayer.avatar
                    }
                  />
                </div>
                <div className="flex-col col-no-padding">
                  <div className="uppercase">
                    <i className={`flag ${selectedPlayer.logo}`}></i>
                    {selectedPlayer.name}
                  </div>
                  {/* LIVE */}
                  {contest.status !== 'upcoming' ? (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div className="flex-col col-no-padding">
                        <Strong>POS:</Strong>{' '}
                        <span>{getPosition(pick)}</span>
                      </div>
                      <div className="flex-col col-no-padding">
                        <Strong>SC:</Strong> {pick.pr.real_score}
                      </div>
                      <div className="flex-col col-no-padding">
                        <Strong>H:</Strong> {pick.pr.data.hole}
                      </div>
                      <div className="flex-col col-no-padding">
                        <Strong>HOY:</Strong> {pick.pr.data.today}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            ) : (
                // FINISHED
                <div
                  className={pickClass}
                  style={{ padding: '4px', display: 'flex', alignItems: 'center' }}
                >
                  <div className="flex-col col-20 col-no-padding">
                    <img
                      className="player-avatar"
                      src={pick.p.avatar === null ? avatar : pick.p.avatar}
                    />
                  </div>
                  <div className="flex-col col-no-padding">
                    <div className="uppercase">
                      <i className={`flag ${pick.p.logo}`}></i>
                      <span>{pick.p.name}</span>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div className="flex-col col-no-padding">
                        <Strong>POS:</Strong>
                        <span>{getPosition(pick)}</span>
                      </div>
                      <div className="flex-col col-no-padding">
                        <Strong>SC:</Strong>
                        <span>{pick.pr.real_score ?? ''}</span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
          </React.Fragment>
        )}
    </div>
  );
};


const getPosition = (pick) => {
  if (['CUT', 'WDQ', 'DQ'].includes(pick.pr.data.today)) {
    return 'CUT'
  }

  if (pick.pr.position === null) {
    return '-'
  }

  return pick.pr.position + 'º'
}

const Strong = styled.span`
  font-weight: bold;
  margin-right: 0.3rem;
`

export default SurvivorContestPick;
