import React from 'react'
import styled from 'styled-components'
import PageWrapper from '../PageWrapper'
import MarkdownParser from '../MarkdownParser'

const Terminos2025 = () => {
  return (
    <>
      <PageWrapper>
        <Panel className="responsive-sm flex-row">
          <MarkdownParser url='https://api.pickwin.mx/files/terminos.md' />
        </Panel>
      </PageWrapper>
    </>
  );
};


const Panel = styled.div`
  background: #000;
  margin: 5rem auto;
  max-width: 1200px;
  padding: 3rem;
  border-radius: 1rem;
`

export default Terminos2025