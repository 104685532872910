import API from '../../utils/api';

import utils from '../../utils/utils';
import branchUtils from '../../utils/branchUtils';
import moment from 'moment';

//import createHistory from 'history/createBrowserHistory';
import _ from 'underscore';
import { publish } from '../../utils/events';
//const history = createHistory();
const history = require('history').createBrowserHistory();

const apiError = { success: false, error: new Error('Something failed in the api') };

//const actionError = { success: false, error: new Error('Action Failed') };

const actions = {
  /**This method handles the submit property { Boolean } in the context
   * @param dispatch { fn }
   * @return { Object }     { result } { This shows if the dispatcher worked property or ocurred a error }
   */
  isSubmitting: (dispatch) => {
    const actionSuccess = { success: true };

    try {
      dispatch({ type: 'changeSubmit' });
      return actionSuccess;
    } catch (err) {
      dispatch({ type: 'isError', payload: { error: err } });
      return { success: false, error: err };
    }
  },
  fetchCopyPicks: async (dispatch, entryId) => {
    const actionSuccess = { success: true };
    let responseCopy = null;

    try {
      let result = await API.fetchCopyGroupPoolPicks(entryId);
      // console.log( 'copy', result );
      if (result) {
        dispatch({ type: 'handleOverlay', payload: { isCopy: true } });

        // dispatch( {  type: 'copyEntry', payload:{ myCopiedEntry: result }})
        responseCopy = actionSuccess;
        responseCopy['data'] = result.data;
      } else {
        dispatch({ type: 'isError', payload: { error: responseCopy } });
        responseCopy = { success: false, error: apiError };
      }

      return responseCopy;
    } catch (err) {
      console.error(err);
      dispatch({ type: 'isError', payload: { error: err } });
    }
  },
  getSimilarByPoolGroup: async (dispatch, poolId) => {
    console.log('%c Get similar Pools', 'font-size:2em;');
    const actionSuccess = { success: true };
    let responseStatusSimilarPools = null;

    try {
      let responseSimilar = await API.fetchSimilarByPoolGroups(poolId);
      // console.log('similar pools by pool:',responseSimilar);
      if (responseSimilar.data) {
        dispatch({ type: 'setSimilarPools', payload: { similarPools: responseSimilar.data } });
        responseStatusSimilarPools = actionSuccess;
        responseStatusSimilarPools['data'] = responseSimilar.data;
      } else {
        dispatch({ type: 'isError', payload: { error: responseSimilar } });
        responseStatusSimilarPools = { success: false, error: apiError };
      }
      return responseStatusSimilarPools;
    } catch (err) {
      console.error(err);
      dispatch({ type: 'isError', payload: { error: err } });
    }
  },
  getSimilarPools: async (dispatch, poolId) => {
    const actionSuccess = { success: true };
    let responseStatusSimilarPools = null;

    try {
      let responseSimilar = await API.fetchSimilarPoolGroups(poolId);
      // console.log('similar pools by entry:',responseSimilar);
      if (responseSimilar.data) {
        dispatch({ type: 'setSimilarPools', payload: { similarPools: responseSimilar.data } });
        responseStatusSimilarPools = actionSuccess;
        responseStatusSimilarPools['data'] = responseSimilar.data;
      } else {
        dispatch({ type: 'isError', payload: { error: responseSimilar } });
        responseStatusSimilarPools = { success: false, error: apiError };
      }
      return responseStatusSimilarPools;
    } catch (err) {
      console.error(err);
      dispatch({ type: 'isError', payload: { error: err } });
    }
  },
  siguientePagPositions: async (dispatch) => {
    const actionSuccess = { succces: true };
    console.log('next page');
    try {
      dispatch({ type: 'nextStandingPage' });
      return actionSuccess;
    } catch (err) {
      dispatch({ type: 'isError', payload: { error: err } });
      return { success: false, error: err };
    }
  },
  siguientePagGroupPicks: async (dispatch) => {
    const actionSuccess = { succces: true };
    console.log('next page');
    try {
      dispatch({ type: 'isSubmittingGroupPicks' });
      dispatch({ type: 'nextGroupPickPage' });
      return actionSuccess;
    } catch (err) {
      dispatch({ type: 'isError', payload: { error: err } });
      return { success: false, error: err };
    }
  },
  submittingGroupPicks: (dispatch) => {
    //  console.log('submitting');
    //const actionSuccess = { success: true };
    try {
      dispatch({ type: 'isSubmittingGroupPicks' });
    } catch (err) {
      console.error(err);
    }
  },
  getMoreGroupPicks: async (dispatch, poolId, queryParams, contestId) => {
    const actionSuccess = { success: true };

    // console.log('get Pool Details', poolGroupId);
    let responseStatusGroupPicks = null;
    try {
      let responseGroupPicks = await API.fetchPoolGroupGroupPicks(poolId, queryParams, contestId);
      // console.log( 'group Picks', responseGroupPicks );
      if (responseGroupPicks.data) {
        dispatch({ type: 'setMoreGroupPicks', payload: { groupPicks: responseGroupPicks.data } }); // console.log(actionSuccess)
        responseStatusGroupPicks = actionSuccess;
        responseStatusGroupPicks['data'] = responseGroupPicks.data;
      } else {
        dispatch({ type: 'isError', payload: { error: responseGroupPicks } });
        responseStatusGroupPicks = { success: false, error: apiError };
      }
      return responseStatusGroupPicks;
    } catch (err) {
      dispatch({ type: 'isError', payload: { error: err } });
      return { success: false, error: err };
    }
  },
  getMorePoolStandings: async (dispatch, poolId, queryParams) => {
    const actionSuccess = { success: true };

    let responseStatusStandings = null;
    try {
      let responseStandings = await API.fetchPoolGroupsStandings(poolId, queryParams);
      console.log('poolStndings more:', responseStandings);
      if (responseStandings.data.hasOwnProperty('items')) {
        dispatch({ type: 'setMoreStandings', payload: { standings: responseStandings.data } });
        responseStatusStandings = actionSuccess;
        responseStatusStandings['data'] = responseStandings.data;
      } else {
        dispatch({ type: 'isError', payload: { error: responseStandings } });
        responseStatusStandings = { success: false, error: apiError };
      }
      return responseStatusStandings;
    } catch (err) {
      dispatch({ type: 'isError', payload: { error: err } });
    }
  },
  getPoolStandings: async (dispatch, poolId, queryParams, contestId) => {
    const actionSuccess = { success: true };

    let responseStatusStandings = null;

    try {
      let responseStandings = await API.fetchPoolGroupsStandings(poolId, queryParams);
      //let responseStandings = await API.getContestStandings(poolId, contestId, queryParams);
      // console.log('poolStndings:',responseStandings);
      if (responseStandings.data.hasOwnProperty('items')) {
        queryParams['pages'] = responseStandings.data.pages;
        dispatch({ type: 'setStandings', payload: { standings: responseStandings.data } });
        dispatch({ type: 'updateQueryParams', payload: { queryParams: queryParams } });
        responseStatusStandings = actionSuccess;
        responseStatusStandings['data'] = responseStandings.data;
      } else {
        dispatch({ type: 'isError', payload: { error: responseStandings } });
        responseStatusStandings = { success: false, error: apiError };
      }
      return responseStatusStandings;
    } catch (err) {
      dispatch({ type: 'isError', payload: { error: err } });
    }
  },
  getPoolEntry: async (dispatch, entryId) => {
    const actionSuccess = { success: true };
    let responseStatusPoolEntry = null;
    try {
      let responseEntry = await API.fetchPoolGroupsEntry(entryId);
      if (responseEntry.data.hasOwnProperty('group_pool_entry')) {
        dispatch({
          type: 'updateInitialEntry',
          payload: { myEntriesFromServer: responseEntry.data },
        });
        responseStatusPoolEntry = actionSuccess;
        responseStatusPoolEntry['data'] = responseEntry.data;
      } else {
        dispatch({ type: 'isError', payload: { error: responseEntry } });
        responseStatusPoolEntry = { success: false, error: apiError };
      }
      return responseStatusPoolEntry;
    } catch (err) {
      dispatch({ type: 'isError', payload: { error: err } });
    }
  },
  updatePoolEntries: async (dispatch, remainingEntries) => {
    const actionSuccess = { success: true };
    console.log('Pool Entries', remainingEntries);
    let responseStatusPoolEntry = null;
    try {
      let allEntriesTemporal = {
        group_pool_entry: remainingEntries[0],
        other_entries: remainingEntries.filter((e, fIdx) => {
          if (fIdx !== 0) {
            return e;
          }
        }),
      };

      console.log('all EntriesTemporal', allEntriesTemporal);
      dispatch({
        type: 'updateInitialEntry',
        payload: { myEntriesFromServer: allEntriesTemporal },
      });
      responseStatusPoolEntry = actionSuccess;
      responseStatusPoolEntry['data'] = allEntriesTemporal;
      return responseStatusPoolEntry;
    } catch (err) {
      dispatch({ type: 'isError', payload: { error: err } });
    }
  },
  handleMultiContainerMaxHeigth: (dispatch, maxSize) => {},
  /**
   * This method fetch  the poolDetails from the server and store it in the context
   * @param { fn }         { dispatch } { the dispatcher of the context state }
   * @param { Number }     { poolGroupId } { This represent the pool Id that the user tried to access }
   * @return { Object }     { result } { This shows if the dispatcher worked property or ocurred a error }
   */
  getPoolDetails: async (dispatch, poolGroupId) => {
    const actionSuccess = { success: true };
    let responseStatusPoolDetail = null;

    try {
      // get pool detail
      let responsePoolDetail = await API.fetchPoolGroupsDetail(poolGroupId);

      //set available amount
      let available = responsePoolDetail.data.group_pool.available;
      if ( responsePoolDetail.data.group_pool.type == 'full' && responsePoolDetail.data.group_pool.weekly_prize ) {
        available = responsePoolDetail.data.group_pool.available_end;
      }

      //prizes shenanigans
      responsePoolDetail.data.prizes = responsePoolDetail.data.prizes.map((prize, idx) => {
        prize.percentage = prize.percentage;
        prize.amount     = available * (prize.percentage/10000);
        prize.modded     = true;
        return prize;
      });

      if (responsePoolDetail.data.hasOwnProperty('group_pool')) {
        // get players
        let players = await API.fetchPoolGroupPlayers(poolGroupId, responsePoolDetail.data.current_contest.id);

        if (responsePoolDetail.data.group_pool.sport_id === 7) {  //F1
          //console.log("POOLDETAILF1:", responsePoolDetail);
          //console.log("PLAYERSF1:", players);

          //get contest standings
          let contestStandings = await API.getContestStandings(poolGroupId, responsePoolDetail.data.current_contest.id);

          let buckets = {};
          let bucketKeys = [];
          let bucketPlayers = [];

          let temporalPlayers = {};
          _.forEach(players.data.players, (item, index) => {
            item.bucket = null;
            temporalPlayers[index] = item;
            bucketPlayers.push(item);
          });

          responsePoolDetail.data.group_pool.contests.map((contest) => {
            buckets[contest.id] = [];
            for (let i = 0; i < responsePoolDetail.data.group_pool.num_picks; i++) {
              const obj = {};
              obj['id'] = i+1;
              obj['players'] = bucketPlayers;
              obj['isOpen'] = false;
              obj['player'] = null;
              obj['pick'] = null;
              buckets[contest.id][i] = obj;
            }
          });

          dispatch({
            type: 'updatePoolDetails',
            payload: {
              poolInfo: responsePoolDetail.data,
              bucketKeys: bucketKeys,
              buckets: buckets,
              players: temporalPlayers,
              contestStandings: contestStandings.data
            },
          });

          responseStatusPoolDetail = actionSuccess;
          responsePoolDetail.data['players'] = temporalPlayers;
          responsePoolDetail.data['buckets'] = buckets;
          responsePoolDetail.data['bucketKeys'] = bucketKeys;
          responseStatusPoolDetail['data'] = responsePoolDetail.data;
        } else { //NORMAL
          //console.log("POOLDETAIL:", responsePoolDetail);
          //console.log("PLAYERS:", players);

          //get contest standings
          let contestStandings = await API.getContestStandings(poolGroupId, responsePoolDetail.data.current_contest.id);

          // let buckets = _.groupBy(players.data.players, 'bucket');
          // let bucketKeys = [];
          // buckets = _.map(buckets, (b, bIdx) => {
          //   // console.log(b);
          //   const obj = {};

          //   obj['id'] = b[0].bucket;
          //   obj['players'] = b;
          //   obj['isOpen'] = false;
          //   obj['player'] = null;
          //   obj['pick'] = null;
          //   return obj;
          // });

          let bucketKeys = [];
          let buckets = {};
          responsePoolDetail.data.group_pool.contests.map((contest) => {
            buckets[contest.id] = [];
            buckets[contest.id] = _.groupBy(players.data.players, 'bucket');
            buckets[contest.id] = _.map(buckets[contest.id], (b, bIdx) => {
              const obj = {};

              obj['id'] = b[0].bucket;
              obj['players'] = b;
              obj['isOpen'] = false;
              obj['player'] = null;
              obj['pick'] = null;
              return obj;
            });

            buckets[contest.id] = _.filter(buckets[contest.id], (b) => b.id <= responsePoolDetail.data.group_pool.num_picks);
            bucketKeys = _.map(buckets, (b, bIdx) => {
              return bIdx;
            });
          });


          let temporalPlayers = {};
          _.forEach(players.data.players, (item, index) => {
            temporalPlayers[index] = item;
          });

          dispatch({
            type: 'updatePoolDetails',
            payload: {
              poolInfo: responsePoolDetail.data,
              bucketKeys: bucketKeys,
              buckets: buckets,
              players: temporalPlayers,
              contestStandings: contestStandings.data
            },
          });

          responseStatusPoolDetail = actionSuccess;
          responsePoolDetail.data['players'] = temporalPlayers;
          responsePoolDetail.data['buckets'] = buckets;
          responsePoolDetail.data['bucketKeys'] = bucketKeys;
          responseStatusPoolDetail['data'] = responsePoolDetail.data;
        }

      } else {
        dispatch({ type: 'isError', payload: { error: responsePoolDetail } });
        responseStatusPoolDetail = { success: false, error: apiError };
      }
      return responseStatusPoolDetail;
    } catch (err) {
      dispatch({ type: 'isError', payload: { error: err } });
      return { success: false, error: err };
    }
  },
  /**
   * This method fetch  the user Data updated from the server and store it in the context
   * @param { fn }         { dispatch } { the dispatcher of the context state }
   * @return { Object }     { result } { This shows if the dispatcher worked property or ocurred a error }
   */
  getUserData: async (dispatch) => {
    const actionSuccess = { success: true };

    // console.log('get Pool Details', poolGroupId);
    let responseStatusUser = null;
    try {
      let responseUser = await API.fetchUserData();
      // console.log( 'UserData', responseUser );
      if (responseUser.data) {
        dispatch({ type: 'initializeUserInfo', payload: { userInfo: responseUser.data } }); // console.log(actionSuccess)
        responseStatusUser = actionSuccess;
        responseStatusUser['data'] = responseUser.data;
      } else {
        dispatch({ type: 'isError', payload: { error: responseUser } });
        responseStatusUser = { success: false, error: apiError };
      }
      return responseStatusUser;
    } catch (err) {
      dispatch({ type: 'isError', payload: { error: err } });
      return { success: false, error: err };
    }
  },
  getGroupPicks: async (dispatch, poolId, queryParams, contestId) => {
    const actionSuccess = { success: true };

    //console.log('get Pool Details', queryParams);
    let responseStatusGroupPicks = null;
    try {
      let responseGroupPicks = await API.fetchPoolGroupGroupPicks(poolId, queryParams, contestId);
      // console.log( 'group Picks', responseGroupPicks );
      if (responseGroupPicks.data) {
        dispatch({ type: 'setGroupPicks', payload: { groupPicks: responseGroupPicks.data } }); // console.log(actionSuccess)
        responseStatusGroupPicks = actionSuccess;
        responseStatusGroupPicks['data'] = responseGroupPicks.data;
      } else {
        dispatch({ type: 'isError', payload: { error: responseGroupPicks } });
        responseStatusGroupPicks = { success: false, error: apiError };
      }
      return responseStatusGroupPicks;
    } catch (err) {
      dispatch({ type: 'isError', payload: { error: err } });
      return { success: false, error: err };
    }
  },
  /**
   * This method fetch  the user Data updated from the server and store it in the context
   * @param { fn }         { dispatch } { the dispatcher of the context state }
   * @return { Object }     { result } { This shows if the dispatcher worked property or ocurred a error }
   */
  getUserTickets: async (dispatch, poolId) => {
    const actionSuccess = { success: true };

    // console.log('get Pool Details', poolGroupId);
    let responseStatusTickets = null;
    try {
      let responseTickets = await API.fetchTickets(poolId, 'group_pool');
      // console.log( 'UserTickets', responseTickets );
      if (responseTickets.data) {
        dispatch({ type: 'setTickets', payload: { tickets: responseTickets.data.tickets } });
        responseStatusTickets = actionSuccess;
        responseStatusTickets['data'] = responseTickets.data;
      } else {
        dispatch({ type: 'isError', payload: { error: responseTickets } });
        responseStatusTickets = { success: false, error: apiError };
      }
      return responseStatusTickets;
    } catch (err) {
      dispatch({ type: 'isError', payload: { error: err } });
      return { success: false, error: err };
    }
  },
  /**
   * This method calculate if the user has enough balance to play
   * @param { fn }              { dispatch } { the dispatcher of the context state }
   * @param { Number }          { userBalance } { userBalance }
   * @return { Object }         { result } { This shows if the dispatcher worked property or ocurred a error }
   *
   */
  updateStartedPool: (dispatch) => {
    console.log('updateStartedPool', dispatch);
    const actionSuccess = { success: true };

    // console.log( entryFee, entryCurrency, tickets, utils.getUserBalance());
    try {
      dispatch({ type: 'updateStartedPool' });
      return actionSuccess;
    } catch (err) {
      dispatch({ type: 'isError', payload: { error: err } });
      return { success: false, error: err };
    }
  },
  isBalanceEnough: (dispatch, entryFee, entryCurrency, tickets) => {
    // console.log( 'Calculate is enough?' );
    const actionSuccess = { success: true };

    // console.log( entryFee, entryCurrency, tickets, utils.getUserBalance());
    try {
      if (utils.isLogged()) {
        let balanceEnough = utils.isBalanceEnough(entryCurrency, entryFee);
        //   entryCurrency === 'real'
        // ?
        // utils.getUserBalance().balanceReal >= entryFee
        // :
        // utils.getUserBalance().balancePc >= entryFee;

        // console.log( 'is', balanceEnough );

        dispatch({
          type: 'updateEnoughBalance',
          payload: {
            balanceEnough: !balanceEnough && tickets && tickets.length < 1 ? false : true,
          },
        });
      } else {
        dispatch({ type: 'updateEnoughBalance', payload: { balanceEnough: false } });
      }
      return actionSuccess;
    } catch (err) {
      dispatch({ type: 'isError', payload: { error: err } });
      return { success: false, error: err };
    }
  },
  /**
   * This method fetch the entry of the poolDetail and the entryId from the server and store it in the context
   * @param { fn }         { dispatch } { the dispatcher of the context state }
   * @param { Number }     { poolGroupId } { This represent the pool Id that the user tried to access }
   * @param { Number }     { entryId } { This represent the pool Id that the user tried to access }
   * @return { Object }     { result } { This shows if the dispatcher worked property or ocurred a error }
   *
   */
  getPoolGroupEntry: async (dispatch, poolGroupId, entryId) => {
    // console.log('get Pool Entry');
    const actionSuccess = { success: true };

    let responseStatusEntry = {};
    try {
      let responseEntry = await API.fetchPoolGroupsEntry(poolGroupId, entryId);

      // console.log( 'ResponseEntry', responseEntry );

      if (responseEntry.data.success) {
        dispatch({
          type: 'updateInitialEntry',
          payload: { myEntriesFromServer: responseEntry.data },
        });
        responseStatusEntry = actionSuccess;
        responseStatusEntry['data'] = responseEntry.data;
        // console.log( responseStatus );
      } else {
        // console.error(apiError);
        dispatch({ type: 'isError', payload: { error: responseEntry } });
        responseStatusEntry = { success: false, error: apiError };
      }
      return responseStatusEntry;
    } catch (err) {
      dispatch({ type: 'isError', payload: { error: err } });
      return { success: false, error: err };
    }
  },
  closeAllBuckets: () => {
    //const actionSuccess = { success: true };
    try {
      //dispatch({ type: 'closeAllBuckets' });
    } catch (err) {
      //dispatch({ type: 'isError', payload: { error: err } });
    }
  },
  /**
   * This method fetch the Buckets of the poolDetail from the server and store it in the context
   * @param { fn }         { dispatch } { the dispatcher of the context state }
   * @param { Number }     { poolGroupId } { This represent the pool Id that the user tried to access }
   * @param { Array }      { buckets } { ThSub represent the pool Id that the user tried to access }
   * @param { Object }     { ties } { This represent the pool Id that the user tried to access }
   * @param { Boolean }     { withRegister } { This represent the pool Id that the user tried to access }
   * @return { Object }     { result } { This shows if the dispatcher worked property or ocurred a error }
   */
  initializeEntry: async (
    dispatch,
    poolId,
    buckets,
    bucketKeys,
    ties,
    entry,
    withRegister,
    players,
    currentContest,
    entryCurrency,
    contests,
  ) => {
    try {
      let entryId = withRegister ? entry.id : null;
      let entryPicks = {};
      let entryTies = {};

      contests.map((contest) => {
        entryPicks[contest.id] = {};
        _.each(buckets[contest.id], (b, bIdx) => {
          const pickModel = {
            id: null,
            group_pool_id: poolId,
            bucket_number: null,
            user_id: utils.isLogged() ? utils.getUserInfo().id : null,
            pick: null,
            contest_id: contest.id,
          };

          if (withRegister && entry.group_pool_picks) {
            if (entry.group_pool_picks[contest.id] && entry.group_pool_picks[contest.id].length < 1) {
              // console.log('empty picks');
              pickModel.pick = null;
            } else {
              // console.log('Find!')
              let currentPick = _.find(entry.group_pool_picks[contest.id], (pick) => {
                // console.log( pick.bucket_number, bIdx );
                if (pick.bucket_number === bIdx + 1) {
                  return pick;
                }
              });

              //console.log( 'pick exist in entry? ', currentPick );

              if (currentPick) {
                // console.log('exist');
                // console.log( entry.group_pool_entry.picks[b.id ] );
                b.pick = currentPick.pick;
                b.player = players[currentPick.pick];
                pickModel.id = currentPick.id;
                pickModel.pick = currentPick.pick;
                pickModel.bucket_number = currentPick.bucket_number;
              } else {
                // console.log('dont exist');
                pickModel.pick = null;
              }
            }

            entryPicks[contest.id][bIdx + 1] = pickModel;
          } else {
            pickModel.pick = null;
            entryPicks[contest.id][bIdx + 1] = pickModel;
          }
        });
      });

      if (ties) {
        console.log('%c Initialize  Ties', 'color:rgb(255, 199, 48); font-size:2em;');
        _.each(ties, (t, contestId) => {
          entryTies[contestId] = {};
          _.each(t, (t, tI) => {
            const tieModel = {
              group_pool_entry_id: null,
              group_pool_id: poolId,
              group_pool_tiebreaker_id: null,
              value: null,
              contest_id: contestId,
            };

            if (withRegister) {
              tieModel.group_pool_entry_id = entry.id;
              tieModel.group_pool_tiebreaker_id = t.id;
              if (entry.group_pool_user_tiebreakers && (!entry.group_pool_user_tiebreakers[contestId] || entry.group_pool_user_tiebreakers[contestId].length < 1) ) {
                tieModel.value = null;
              } else {
                if (entry.group_pool_user_tiebreakers) {
                  let currentTie = _.find(entry.group_pool_user_tiebreakers[contestId], (tie) => tie.group_pool_tiebreaker_id === t.id);
                  if (currentTie) {
                    tieModel.value = currentTie.value;
                    tieModel.group_pool_tiebreaker_id = currentTie.group_pool_tiebreaker_id;
                  } else {
                    console.log('dont exist');
                    tieModel.value = null;
                  }
                }
              }

              entryTies[contestId][t.id] = tieModel;
            } else {
              tieModel.group_pool_tiebreaker_id = t.id;
              tieModel.value = null;
              entryTies[contestId][t.id] = tieModel;
            }
          });
        });
      }

      let entryModel = {
        group_pool_entry_id: entryId,
        num_entries: 1,
        entry_payment: entryCurrency,
        use_same_picks: false,
        group_pool_id: poolId,
        group_pool_picks: entryPicks,
        contest_standings: withRegister && entry.contest_standings ? entry.contest_standings : {},
        group_pool_user_tiebreakers: entryTies,
        group_pool_standing: withRegister ? entry.group_pool_standing : {},
        group_pool_user_prize: withRegister ? entry.group_pool_user_prize : {},
        number: withRegister ? entry.number : null,
      };

      // console.log( entryModel );
      //dispatch({ type: 'closeAllBuckets' });
      dispatch({
        type: 'updateEntry',
        payload: { entryUpdated: entryModel, withRegister: withRegister },
      });

      dispatch({type: 'closeAllBuckets'});
      return { status: 'success', data: entryModel };
    } catch (err) {
      console.error(err);
      dispatch({ type: 'isError', payload: { error: err } });
      return { success: false, error: err };
    }
  },
  initializeCountdownPool: (dispatch, poolDetails) => {
    console.log('Initialize countdown', poolDetails.data);
    let actionSuccess = { success: true };
    try {
      let leftTime;
      let pastDate = moment(poolDetails.data.group_pool.deadline);
      console.log(pastDate);
      let now = moment();
      let diff = pastDate / 1000 - moment() / 1000;
      leftTime = pastDate.diff(now, 'miliseconds');

      if (leftTime > 0) {
        if (leftTime > 0x7fffffff) {
          leftTime = 0x7fffffff;
        }
      }

      console.log(leftTime);
      console.log(diff);
      console.log(poolDetails.data.group_pool.time_left);
      setTimeout(() => {
        console.log('Pool started!');
        dispatch({ type: 'updateStartedPool' });
      }, leftTime);
      return actionSuccess;
    } catch (err) {
      // console.error( err );
      dispatch({ type: 'isError', payload: { error: err } });
      return { success: false, error: err };
    }
  },
  initializeBucketsState: (dispatch, buckets, myEntry, currentContest) => {
    //console.log( 'INITBUCKET:', myEntry, currentContest );
    let temporalNewBuckets = _.map(buckets[currentContest.id], (b) => {
      if (myEntry.group_pool_picks[currentContest.id] && myEntry.group_pool_picks[currentContest.id].length < 1) {
        // console.log('empty picks');
        b.isOpen = false;
      } else {
        if (myEntry.group_pool_picks[currentContest.id] && myEntry.group_pool_picks[currentContest.id][b.id - 1]) {
          // console.log('Found');
          b.isOpen = false;
        } else {
          b.isOpen = true;
        }
      }
      return b;
    });

    // console.log( temporalNewBuckets);

    dispatch({ type: 'initializeBuckets', payload: { newBuckets: temporalNewBuckets } });
  },
  closeAddConfirmation: (dispatch) => {
    dispatch({ type: 'closeAddConfirmation' });
  },
  openAddConfirmation: (dispatch, userToAdd) => {
    console.log('Open Confirmation', userToAdd);
    dispatch({ type: 'openAddConfirmation', payload: { userToAdd: userToAdd } });
  },
  updatePicksToCopy: async (dispatch, copiedEntry, myEntry, myEntryId, withRegister) => {
    //const actionSuccess = { success: true };
    let temporalEntry = myEntry;
    console.log('copiedEntry ', copiedEntry);
    console.log('myEntry ', myEntry);

    try {
      _.each(copiedEntry.group_pool_picks, (p) => {
        // console.log( temporalEntry.group_pool_picks, p.bucket_number);
        if (temporalEntry.group_pool_picks[p.bucket_number]) {
          // console.log('replace pick', p.pick);
          temporalEntry.group_pool_picks[p.bucket_number].pick = p.pick;
          temporalEntry.group_pool_picks[p.bucket_number].bucket_number = p.bucket_number;
        }
      });
      _.each(copiedEntry.group_pool_user_tiebreakers, (t) => {
        console.log('replace tie', t, temporalEntry.group_pool_user_tiebreakers);
        if (temporalEntry.group_pool_user_tiebreakers[t.group_pool_tiebreaker_id]) {
          temporalEntry.group_pool_user_tiebreakers[t.group_pool_tiebreaker_id].value = t.value;
        }
      });
      setTimeout(() => {
        dispatch({
          type: 'handleOverlay',
          payload: { isCopy: false, isError: false, isSuccess: false },
        });
      }, 1200);
      // console.log( temporalEntry);
      dispatch({ type: 'closeAllBuckets' });
      dispatch({
        type: 'updateEntry',
        payload: { entryUpdated: temporalEntry, withRegister: withRegister },
      });
    } catch (err) {
      console.error(err);
      dispatch({ type: 'isError', payload: { error: err } });
      return { success: false, error: err };
    }
  },
  redirectTo: (dispatch, path) => {
    dispatch({ type: 'redirect', payload: { to: path } });
  },
  // postSavePicks: async ( dispatch, entryData ) => {
  //   const actionSuccess = { "success": true };

  //   console.log('PostSavePicks', entryData );
  //   let responseStatusSavePicks = {};
  //   try {
  //     dispatch( {type:'changeSubmit' } );
  //     let responseSave = await API.savePoolGroup( entryData );
  //     console.log( responseSave );
  //     if( responseSave.data.success ){
  //       console.log( responseSave );
  //       dispatch( { type:'changeSubmit' } );
  //       responseStatusSavePicks = actionSuccess;
  //       responseStatusSavePicks['data'] = responseSave.data;
  //       dispatch( {type: 'handleOverlay', payload:{ isError: !responseSave.data.success , isSuccess: responseSave.data.success }} )
  //     }
  //     else {
  //       dispatch( { type:'isError',payload: { error: responseSave } } );
  //       responseStatusSavePicks = {"success":false, error: apiError }
  //     }
  //     return responseStatusSavePicks
  //   }
  //   catch(err){
  //     dispatch( { type:'isError',payload: { error: err} } );
  //     return {"success":false, error: err }
  //   }
  // },
  postSavePicks: async (dispatch, entryData, poolInfo) => {
    const actionSuccess = { success: true };

    let responseStatusSavePicks = {};
    try {
      dispatch({ type: 'changeSubmit' });
      let responseSave = await API.savePoolGroup(entryData);

      if (responseSave.data.success) {
        dispatch({ type: 'changeSubmit' });
        responseStatusSavePicks = actionSuccess;
        responseStatusSavePicks['data'] = responseSave.data;

        publish('POOL_LOBBY:REFRESH_DATA')
      } else {
        dispatch({ type: 'isError', payload: { error: responseSave } });
        responseStatusSavePicks = { success: false, error: apiError };
      }

      return responseStatusSavePicks;
    } catch (err) {
      dispatch({ type: 'isError', payload: { error: err } });
      return { success: false, error: err };
    }
  },
  handleMessage: (dispatch, e) => {
    console.log(e.target.value);
    const actionSuccess = { success: true };

    try {
      dispatch({ type: 'handleMessage', payload: { message: e.target.value } });
      return actionSuccess;
    } catch (err) {
      console.error(err);
    }
  },
  addRegistersToStandings: (dispatch, entriesToAdd) => {
    const actionSuccess = { success: true };
    console.log('AddingRegisters to Standings ', entriesToAdd);
    try {
      dispatch({ type: 'addRegistersToStandings', payload: { entriesToAdd: entriesToAdd } });
      return actionSuccess;
    } catch (err) {
      console.error(err);
      dispatch({ type: 'isError', error: err });
      return { status: false, error: err };
    }
  },
  removeCancelEntriesToStandings: (dispatch, entriesCancelled) => {
    const actionSuccess = { success: true };
    console.log('remove cancel entries to Standings ', entriesCancelled);
    try {
      dispatch({
        type: 'removeCancelEntriesToStandings',
        payload: { entriesToDelete: entriesCancelled },
      });
      return actionSuccess;
    } catch (err) {
      console.error(err);
      dispatch({ type: 'isError', error: err });
      return { status: false, error: err };
    }
  },
  /**
   * This method handles the state of the bucket
   * @param { fn }         { dispatch } { the dispatcher of the context state }
   * @return { Object }     { result } { This shows if the dispatcher worked property or ocurred a error }
   */
  handleBucket: (dispatch, bucketId) => {
    const actionSuccess = { success: true };
    try {
      console.log('handle bucket');
      dispatch({ type: 'handleBucket', payload: { bucketId: bucketId } });
      return actionSuccess;
    } catch (err) {
      dispatch({ type: 'handleError' });
      return { status: false, error: err };
    }
  },
  handleBucketF1: (dispatch, bucketId) => {
    const actionSuccess = { success: true };
    try {
      console.log('handle bucket F1');
      dispatch({ type: 'handleBucketF1', payload: { bucketId: bucketId } });
      return actionSuccess;
    } catch (err) {
      dispatch({ type: 'handleError' });
      return { status: false, error: err };
    }
  },
  followUser: async (dispatch, userToAdd) => {
    console.log('Follow this ', userToAdd);

    try {
      let result = await API.followUser(userToAdd);
      console.log(result);
      if (result.data.message === 'ok') {
        dispatch({ type: 'updateInviteStatus', payload: { status: 'success' } });
      } else {
        dispatch({
          type: 'updateInviteStatus',
          payload: { status: 'error', message: 'Surgio un error enviar la invitación' },
        });
      }
    } catch (err) {
      dispatch({
        type: 'updateInviteStatus',
        payload: { status: 'error', message: 'Surgio un error enviar la invitación' },
      });
    }
  },
  cancelRegister: async (formData) => {
    console.log('cancel this', formData);
    const actionSuccess = { success: true };
    let responseCancel = null;
    try {
      let result = await API.postCancelRegisterPoolGroup(formData);
      console.log(result);
      if (result) {
        console.log(result);
        // dispatch( { type:'changeSubmit' } );
        responseCancel = actionSuccess;
        responseCancel['data'] = result.data;
      } else {
        //dispatch({ type: 'isError', payload: { error: result } });
        responseCancel = { success: false, error: apiError };
      }

      return responseCancel;
    } catch (err) {
      console.error(err);
    }
  },
  /**
   * This method handles the state of the bucket
   * @param { fn }         { dispatch } { the dispatcher of the context state }
   * @return { Object }     { result } { This shows if the dispatcher worked property or ocurred a error }
   */
  handleOverlay: (dispatch, isError, isSuccess) => {
    const actionSuccess = { success: true };
    try {
      console.log('handle overlay');
      dispatch({ type: 'handleOverlay', payload: { isError: isError, isSuccess: isSuccess } });
      return actionSuccess;
    } catch (err) {
      dispatch({ type: 'handleError' });
      return { status: false, error: err };
    }
  },
  /**
   * This method handles the state if an error just happend
   * @param { fn }         { dispatch } { the dispatcher of the context state }
   * @return { Object }     { result } { This shows if the dispatcher worked property or ocurred a error }
   */
  handleError: (dispatch) => {
    const actionSuccess = { success: true };

    try {
      console.log('HandleError');
      dispatch({ type: 'handleError' });
      return actionSuccess;
    } catch (err) {
      dispatch({ type: 'handleError' });
      return { status: false, error: err };
    }
  },
  /**
   * This method handles the registration popup show property
   * @param  { fn }         { dispatch } { the dispatcher of the context state }
   * @return { Object }     { result } { This shows if the dispatcher worked property or ocurred a error }
   */
  handleRegistration: (dispatch) => {
    const actionSuccess = { success: true };

    try {
      console.log('Handle Registration');
      dispatch({ type: 'handleRegistration' });
      return actionSuccess;
    } catch (err) {
      dispatch({ type: 'handleError' });
      return { success: false, error: err };
    }
  },
  showSendMail: (dispatch) => {
    console.log('showMailPanel');
    try {
      dispatch({ type: 'showMailPanel' });
    } catch (err) {
      dispatch({ type: 'handleError' });
    }
  },
  /**
   * This method handles the abonar popup show property
   * @param  { fn }         { dispatch } { the dispatcher of the context state }
   * @return { Object }     { result } { This shows if the dispatcher worked property or ocurred a error }
   */
  handleOpenAbonar: (dispatch) => {
    const actionSuccess = { success: true };

    try {
      console.log('Handle Abonar');
      dispatch({ type: 'handleOpenAbonar' });

      return actionSuccess;
    } catch (err) {
      dispatch({ type: 'handleError' });
      return { success: false, error: err };
    }
  },
  whatsAppInvitation: (branchLink, poolInfo) => {
    console.log('WhatsApp invitation', poolInfo);
    branchUtils.redirectToWhatsApp(branchLink, 'open', poolInfo.id);
  },
  /**
   * This method handles the re - registration popup show property
   * @param  { fn }         { dispatch } { the dispatcher of the context state }
   * @return { Object }     { result } { This shows if the dispatcher worked property or ocurred a error }
   */
  handleReRegistration: (dispatch) => {
    console.log(dispatch);
    const actionSuccess = { success: true };

    try {
      console.log('Handle ReRegistration');
      dispatch({ type: 'handleReRegistration' });
      return actionSuccess;
    } catch (err) {
      dispatch({ type: 'handleError' });
      return { success: false, error: err };
    }
  },
  updateBranchLink: (dispatch, url) => {
    try {
      console.log('Update branch link', url);
      dispatch({ type: 'updateBranchLink', payload: { url: url } });
      const actionSuccess = { success: true };
      return actionSuccess;
    } catch (err) {
      dispatch({ type: 'handleError' });
      return { success: false, error: err };
    }
  },
  /**
   * This method handles the invite popup show property
   * @param  { fn }         { dispatch } { the dispatcher of the context state }
   * @return { Object }     { result } { This shows if the dispatcher worked property or ocurred a error }
   */
  handleInvitePanel: (dispatch) => {
    const actionSuccess = { success: true };

    try {
      console.log('Handle Invite Panel');
      dispatch({ type: 'handleInvitePanel' });
      return actionSuccess;
    } catch (err) {
      dispatch({ type: 'handleError' });
      return { success: false, error: err };
    }
  },
  handlePopUpReglas: (dispatch) => {
    const actionSuccess = { success: true };

    try {
      // console.log('Handle PopUpReglas');
      dispatch({ type: 'handlePopUpReglas' });
      return actionSuccess;
    } catch (err) {
      dispatch({ type: 'handleError' });
      return { success: false, error: err };
    }
  },
  /**
   * This method handles the registration popup show property
   * @param  { fn }         { dispatch } { the dispatcher of the context state }
   * @return { Number }     { bucketId } { It handles the bucket that will be modified }
   * @return { Number }     { selectedValue } { It represents the new value } 436
   *
   */
  handlePick: (dispatch, bucketId, selectedValue, currentContestId) => {
    const actionSuccess = { success: true };

    try {
      console.log('Handle Pick');
      console.log(bucketId, selectedValue, currentContestId);
      dispatch({ type: 'handlePick', payload: { bucketId: bucketId, value: selectedValue, currentContestId: currentContestId } });
      setTimeout(() => {
        dispatch({ type: 'handleBucket', payload: { bucketId: bucketId - 1 } });
      }, 250);
      return actionSuccess;
    } catch (err) {
      dispatch({ type: 'handleError' });
      return { success: false, error: err };
    }
  },
  handlePickF1: (dispatch, bucketId, selectedValue, currentContestId, poolBuckets) => {
    const actionSuccess = { success: true };

    try {
      console.log('Handle Pick F1');
      console.log(bucketId, selectedValue, currentContestId);
      dispatch({ type: 'handlePickF1', payload: { bucketId: bucketId, value: selectedValue, currentContestId: currentContestId } });
      setTimeout(() => {
        dispatch({ type: 'handleBucketF1', payload: { bucketId: bucketId - 1 } });
      }, 250);

      return actionSuccess;
    } catch (err) {
      dispatch({ type: 'handleError' });
      return { success: false, error: err };
    }
  },
  /**
   * This method handles the registration popup show property
   * @param  { fn }         { dispatch } { the dispatcher of the context state }
   * @return { Number }     { tieId } { It handles the bucket that will be modified }
   * @return { Number }     { selectedValue } { It represents the new value }
   */
  handleTie: (dispatch, tieId, selectedValue, currentContest) => {
    const actionSuccess = { success: true };

    try {
      console.log('Handle Tie');
      console.log(tieId, selectedValue);
      dispatch({ type: 'handleTie', payload: { tieId: tieId, value: selectedValue, currentContestId: currentContest.id } });
      return actionSuccess;
    } catch (err) {
      dispatch({ type: 'handleError' });
      return { success: false, error: err };
    }
  },
  addNewEntriesToStandings: (dispatch, newEntries) => {
    //const actionSuccess = { success: true };
    try {
      console.log('Add this entries to Standings', newEntries);
      dispatch({ type: 'addNewEntriesToStandings', payload: { newEntries } });
    } catch (err) {
      console.error(err);
    }
  },
  /**
   * This method updates the location shown in the address bar
   * @param  { fn }         { dispatch } { the dispatcher of the context state }
   * @return { Object }     { result } { This shows if the dispatcher worked property or ocurred a error }
   */
  updateLocation: (slug, poolId, selectedEntry, isPopUp, match, location) => {
    const actionSuccess = { success: true };
    try {
      if (selectedEntry) {
        if (match && selectedEntry === match.params.entryId && poolId === match.params.poolGroupId ) {
          // console.log('update mypage');
          history.push({
            pathname: `/quinielaGrupo/${slug}/${poolId}/${selectedEntry}`,
          });
        } else {
          console.log('update location');
          if( match && selectedEntry === match.params.entryId && poolId === match.params.poolId ){
            console.log('Update');
            history.push({
              pathname: `/quinielaGrupo/${ slug }/${ poolId }/${selectedEntry}`
            });
          }
          window.location =  `/quinielaGrupo/${ slug }/${ poolId }/${ selectedEntry }`;
        }
        // history.push({
        //   pathname: `/quinielaGrupo/${ slug }/${ poolId }/${ selectedEntry }`
        // });
      } else {
        if (isPopUp) {
          history.push({
            pathname: `/lobby/quinielaGrupoPop/${slug}/${poolId}`,
          });
        } else {
          history.push({
            pathname: `/quinielaGrupo/${slug}/${poolId}`,
          });
        }
      }
      return actionSuccess;
    } catch (err) {
      console.error(err);
      return { success: false, error: err };
    }
  },
  setTickets: (dispatch, tickets) => {
    const actionSuccess = { success: true };

    try {
      dispatch({ type: 'setTickets', payload: { tickets: tickets } });
      return actionSuccess;
    } catch (err) {
      dispatch({ type: 'handleError' });
      return { success: false, error: err };
    }
  },
  setPoolPrizes: (dispatch, poolPrizes) => {
    const actionSuccess = { success: true };

    try {
      dispatch({ type: 'setPrizes', payload: { prizes: poolPrizes.prizes } });
      return actionSuccess;
    } catch (err) {
      dispatch({ type: 'handleError' });
      return { success: false, error: err };
    }
  },
  /**
   * This method updates user balance
   * @param  { fn }         { dispatch } { the dispatcher of the context state }
   * @return { Object }     { result } { This shows if the dispatcher worked property or ocurred a error }
   */
  updateUserBalance: (dispatch, newBalance) => {
    const actionSuccess = { success: true };

    try {
      dispatch({ type: 'updateBalance', payload: { newBalance: newBalance } });
      return actionSuccess;
    } catch (err) {
      dispatch({ type: 'handleError' });
      return { success: false, error: err };
    }
  },
  updateEnoughBalanceOnly: (dispatch) => {
    dispatch({ type: 'updateEnoughBalanceOnly' });
  },
  initializeUserBalance: (dispatch, balance) => {
    const actionSuccess = { success: true };

    try {
      dispatch({ type: 'initializeUserBalance', payload: { balance: balance } });
      return actionSuccess;
    } catch (err) {
      dispatch({ type: 'handleError' });
      return { success: false, error: err };
    }
  },
  initializeUserInfo: (dispatch, userInfo) => {
    const actionSuccess = { success: true };

    try {
      dispatch({ type: 'initializeUserInfo', payload: { userInfo: userInfo } });
      return actionSuccess;
    } catch (err) {
      dispatch({ type: 'handleError' });
      return { success: false, error: err };
    }
  },
  /**
   * This method updates user info
   * @param  { fn }         { dispatch } { the dispatcher of the context state }
   * @return { Object }     { result } { This shows if the dispatcher worked property or ocurred a error }
   */
  updateUserInfo: (dispatch, userData) => {
    const actionSuccess = { success: true };

    try {
      console.log('update User Info', userData);

      return actionSuccess;
    } catch (err) {
      dispatch({ type: 'handleError' });
      return { success: false, error: err };
    }
  },
  /**
   * This method handles the cancellation popup show property
   * @param  { fn }         { dispatch } { the dispatcher of the context state }
   * @return { Object }     { result } { This shows if the dispatcher worked property or ocurred a error }
   */
  handleCancellation: (dispatch, result) => {
    const actionSuccess = { success: true };
    try {
      console.log(dispatch);
      console.log('Handle Cancellation', result);
      dispatch({ type: 'handleCancellation', result: result });
      return actionSuccess;
    } catch (err) {
      //dispatch({ type: 'handleError' });
      return { success: false, error: err };
    }
  },
  getSearchableStandings: _.debounce(async (dispatch, queryParams, poolId) => {
    // console.log('searchable standings', queryParams );
    let responseStatusStandings = null;
    const actionSuccess = { success: true };

    try {
      let responseStandings = await API.fetchPoolGroupsStandings(poolId, queryParams);
      // console.log('poolStndings:',responseStandings);
      if (responseStandings.data.hasOwnProperty('items')) {
        dispatch({ type: 'setStandings', payload: { standings: responseStandings.data } });
        responseStatusStandings = actionSuccess;
        responseStatusStandings['data'] = responseStandings.data;
      } else {
        dispatch({ type: 'isError', payload: { error: responseStandings } });
        responseStatusStandings = { success: false, error: apiError };
      }
      return responseStatusStandings;
    } catch (err) {
      dispatch({ type: 'isError', payload: { error: err } });
    }
  }, 500),
  changeSection: (dispatch, value) => {
    console.log('clicked value');
    console.log(value);
    const actionSuccess = { success: true };
    try {
      dispatch({ type: 'changeSection', payload: { section: value } });
      return actionSuccess;
    } catch (err) {
      console.error(err);
      dispatch({ type: 'isError', payload: { error: err } });
    }
  },
  changeSearchNick: (dispatch, value) => {
    // console.log('change nick', value);
    const actionSuccess = { success: true };
    try {
      dispatch({ type: 'changeSearchNick', payload: { value: value } });
      return actionSuccess;
    } catch (err) {
      dispatch({ type: 'handleError' });
      return { success: false, error: err };
    }
  },
  resetNickValue: (dispatch, value) => {
    const actionSuccess = { success: true };
    try {
      dispatch({ type: 'changeSearchNick', payload: { value: '' } });
      return actionSuccess;
    } catch (err) {
      dispatch({ type: 'handleError' });
      return { success: false, error: err };
    }
  },
  /**
   * Socket actions
   */
  updatePlayersSocket: async (dispatch, playersUpdated) => {
    //console.log("PLAYERUPDATE:", playersUpdated);
    try {
      dispatch({ type: 'updatePlayers', payload: { newPlayers: playersUpdated } });
      return { success: true };
    } catch (err) {
      // console.error(err);
      dispatch({ type: 'handleError' });
      return { success: false, error: err };
    }
  },
  updatePoolClose: async (dispatch, poolCloseData) => {
    // console.log( dispatch, poolCloseData);
    try {
      dispatch({ type: 'updatePoolClose', payload: { poolCloseData: poolCloseData } });
      dispatch({
        type: 'mapSTandignsDataToPickGroup',
        payload: { standingsUpdate: poolCloseData },
      });
      return { success: true, data: poolCloseData };
    } catch (err) {
      // console.error(err);
      dispatch({ type: 'handleError' });
      return { success: false, error: err };
    }
  },
  updateRaceResultsSoket: async (dispatch, raceRasultsData) => {
    try {
      dispatch({ type: 'updatePlayersF1Socket', payload: { raceResults: raceRasultsData } });
      return { success: true, data: raceRasultsData };
    } catch (err) {
      dispatch({ type: 'handleError' });
      return { success: false, error: err };
    }
  },
  updateContestStandingsSoket: async (dispatch, contestStandingsData) => {
    try {
      dispatch({ type: 'updateContestStandingsSoket', payload: { contestStandings: contestStandingsData } });
      dispatch({ type: 'updateStandingsAganarContest', payload: { newStandings: contestStandingsData } });
      return { success: true, data: contestStandingsData };
    } catch (err) {
      dispatch({ type: 'handleError' });
      return { success: false, error: err };
    }
  },
  updateStandingsSocket: async (dispatch, standingsUpdate) => {
    //console.log("UPDATESTANDING:", standingsUpdate);
    try {
      await dispatch({
        type: 'updateStandingsSocket',
        payload: { standingsUpdate: standingsUpdate },
      });
      console.log(1);
      await dispatch({
        type: 'mapSTandignsDataToPickGroup',
        payload: { standingsUpdate: standingsUpdate },
      });
      console.log(2);
      await dispatch({ type: 'updateStandingsAganar', payload: { newStandings: standingsUpdate } });
      console.log(3);
    } catch (err) {
      dispatch({ type: 'handleError' });
      return { success: false, error: err };
    }
  },
  updateStandingsAmount: async (dispatch, newStandings) => {
    //console.log( 'actions.updateStandingsAmount', newStandings   );
    if (!newStandings || !newStandings.standings) {
      return { success: true, data: { message: 'newStandings is Empty' } };
    }
    try {
      dispatch({ type: 'updateStandingsAganar', payload: { newStandings: newStandings } });
      return { success: true };
    } catch (err) {
      console.error(err);
      dispatch({ type: 'handleError' });
      return { success: false, error: err };
    }
  },
  updateStandingsAmountContest: async (dispatch, newStandings) => {
    console.log( 'actions.updateStandingsAmountContest', newStandings   );
    if (!newStandings || !newStandings.standings) {
      return { success: true, data: { message: 'newStandings is Empty' } };
    }
    try {
      dispatch({ type: 'updateStandingsAganarContest', payload: { newStandings: newStandings } });
      return { success: true };
    } catch (err) {
      console.error(err);
      dispatch({ type: 'handleError' });
      return { success: false, error: err };
    }
  },
  handleShowModalPicksUnsave: async (dispatch) => {
    dispatch({ type: 'handleShowModalPicksUnsave' });
    return { success: true };
  },
  handlerStatusPicksUnsave: async (dispatch) => {
    dispatch({ type: 'handlerStatusPicksUnsave' });
    return { success: true };
  }
};

export default actions;
