import React from 'react';
import {
  Button,
  Modal,
  ControlLabel,
  ButtonToolbar,
  ToggleButtonGroup,
  ToggleButton
} from 'react-bootstrap/lib';
import Formsy from 'formsy-react';
import numeral from 'numeral';
import axios from 'axios';

import API from '../../utils/api';
import utils from '../../utils/utils';
import Loading from '../Loading/Loading';
import RetirarFormsy from '../RetirarFormsy/RetirarFormsy';
import { ErrorMessageValidation } from '../../utils/quinielasComponents';

import idBack from '../../assets/images/id-back.png';
import idFront from '../../assets/images/id-front.png';
import passportBack from '../../assets/images/pasaporte-back.png';
import passportFront from '../../assets/images/pasaporte-front.png';

import * as Sentry from '@sentry/browser';
import $ from 'jquery';
import _ from 'underscore';

/**
*This functional component renders the REsponse of the user after the cashout was sent
* @param showCashoutResponse
* @param closeCashoutResopnse
* @param responseData
@return FucntionalComponent
**/
const ResponseCashout = ({
  showCashoutResponse,
  closeCashoutResopnse,
  responseData,
}) => {
  let MessageResponse = null;
  if (!responseData) {
    return null;
  }
  let isError = false;
  /************************
   * Not Successfull Cashout
   *************************/
  if (!responseData.success && responseData !== 'error') {
    let errors = responseData.messages;
    let errorsList = [];

    _.forEach(errors, (error, key) => {
      errorsList.push((
        <strong style={{ smarginRight: '3px' }}>
          {error}
        </strong>
      ));
    });

    MessageResponse = () => {
      return (
        <div
          className="message-deposit-response col-xs-10 col-xs-offset-1"
          style={{ marginBottom: '20px' }}
        >
          <div className="text-centered flex-container column transaction-success">
            <i className="ion-close-circled icon-failed" />
            {errorsList.map((error, i) => { return(<li key={i}>{error}</li>) })}
          </div>
        </div>
      );
    };
  } else if (responseData === 'error') {
    isError = true;
    MessageResponse = () => {
      return (
        <div className="message-deposit-response col-xs-10 col-xs-offset-1">
          <div className="text-centered flex-container column transaction-success">
            <i className="ion-close-circled icon-failed" />
            Sucedió un error inesperado, por favor inténtalo nuevamente.
          </div>
        </div>
      );
    };
  } else {
    isError = true;
    /*****************
     * Successfull Cashout
     ******************/
    MessageResponse = () => {
      return (
        <div className="message-deposit-response col-xs-10 col-xs-offset-1">
          <div className="text-centered flex-container column transaction-success">
            <i className="ion-checkmark-circled icon-success ion-ios-timer checkout" />
            <span className="text-container text-center">
              En breve te notificaremos sobre el estado de tu retiro.
            </span>
            <br />
            <span className="text-container text-center">Tu saldo actual es:</span>
            <br />
            <span className="text-container text-center">
              <strong>
                {' '}
                {numeral(responseData.balance / 100).format('$0,000.00')} <small>MXN</small>
              </strong>
            </span>
            <br />
            <span className="text-container text-center">
              <i className="ion-paper-airplane" /> Si tienes alguna duda o comentario contactános en
              :
            </span>
            <span className="text-container text-center" style={{ marginBottom: '20px' }}>
              <strong>
                <a href="mailto:soporte@pickwin.net">soporte@pickwin.net</a>
              </strong>
            </span>
          </div>
        </div>
      );
    };
  }

  const nick = utils.getUserInfo().hasOwnProperty('nick') ? utils.getUserInfo().nick : 'nick';

  return (
    <Modal
      show={showCashoutResponse}
      className="responseCashoutModal"
      dialogClassName="responseCashoutDialog"
      backdropClassName="responseCashoutBackdrop"
    >
      <div className="transactionPanel">
        <div
          id="panelTitle"
          className={`panel-abonar-titulo tex-center ${
            !responseData || !responseData.success ? 'failed' : ''
            }`}
        >
          {responseData && responseData.success ? (
            <span className="text-center">!{nick} tu solicitud esta en proceso!</span>
          ) : (
              <span className="text-center">{nick} tenemos un problema.</span>
            )}
          <button
            className="cerrar-panel"
            onClick={() => {
              closeCashoutResopnse(isError);
            }}
          >
            <div className="btn-icon-close">
              <i className="ion-ios-close-empty" />
            </div>
          </button>
        </div>
        <MessageResponse />
      </div>
    </Modal>
  );
};

class FormCashout extends React.Component {
  constructor(props) {
    super(props);
    this.userCashoutData = {
      account: '',
      bank: '',
      bank_code: '',
      document: { upload_front: null, upload_reverse: null, type: '' },
      last_name: '',
      method: '',
      password: '',
      user: '',
    };

    this.state = {
      account: '',
      amount: '',
      bank: '',
      bankCode: '',
      banksList: '',
      canSubmit: false,
      cardNumber: '',
      document: { upload_front: null, upload_reverse: null, type: '' },
      email: '',
      errorFileSizeFront: false,
      feeniciaCardId: '',
      errorFileSizeBack: false,
      errorFileTypeFront: false,
      errorFileTypeBack: false,
      error: false,
      formFileUploadFront: null,
      formFileUploadBack: null,
      idType: '',
      lastName: '',
      method: '',
      showResponseModal: false,
      mounted: false,
      password: '',
      phoneNumber: '',
      readyToSubmit: false,
      responseCashoutData: null,
      speiNumber: '',
      submitting: false,
      userName: '',
      missingValues: [],
      saveAccount: false,
      cashoutAccounts: null,
      cashAccountSelected: null,
      userData: null,
      showModalDeleteAccount: false,
      cashAccountDeleted: null,
    };

    //console.log( this.props.availableForCashout );
    /***************
     * Form Handlers
     ***************/
    this.handleFeeniciaCardId = (value) => {
      console.log('handleCard', value);
      this.setState({ feeniciaCardId: value });
    };
    this.handleNameUser = (e) => {
      this.setState({ userName: e.target.value });
    };
    this.handleAmount = (e) => {
      this.setState({ amount: e.target.value });
    };
    this.handlePassword = (e) => {
      this.setState({ password: e.target.value });
    };
    this.handleLastName = (e) => {
      this.setState({ lastName: e.target.value });
    };
    this.handlePhone = (e) => {
      this.setState({ phoneNumber: e.target.value });
    };
    this.handleEmail = (e) => {
      this.setState({ email: e.target.value });
    };
    this.handleMethod = (e) => {
      this.setState({ method: e.target.value });
    };
    // Add here the bank code after getting bank data
    this.handleCardNumber = (e) => {
      this.setState({ cardNumber: e.target.value });
    };
    this.handleSpeiNumber = (e) => {
      this.setState({ speiNumber: e.target.value });
    };
    this.handleIdType = (e) => {
      this.setState({ idType: e.target.value });
    };
    this.handleBank = (e) => {
      let bankName = this.state.banksList.find((bank) => parseInt(bank.bank_code) === parseInt(e.target.value));
      this.setState({
        bank: !bankName ? null : bankName.bank,
        bankCode: e.target.value,
      });
    };

    this.handleFrontDocument = (e) => {
      const file = e.target.files[0];
      if (!file) {
        return;
      }
      let required = 12;
      let size = file.size / Math.pow(1024, 2);
      let type = file.type.match(/image\/(jpeg|jpg|png)/i) || file.type.match(/application\/pdf/i);
      if (size <= Number(required)) {
        // let val = e.target.files[0  ];
        console.log('FileFrontUp', file);
        if (type) {
          this.setState({
            formFileUploadFront: file,
            errorFileSizeFront: false,
            errorFileTypeFront: false,
          });
        } else {
          this.setState({
            formFileUploadFront: null,
            errorFileTypeFront: true,
            errorFileSizeFront: false,
          });
        }
      } else {
        this.setState({
          formFileUploadFront: null,
          errorFileSizeFront: true,
          errorFileTypeFront: false,
        });
      }
    };

    this.handleBackDocument = (e) => {
      const file = e.target.files[0];
      if (!file) {
        return;
      }
      let required = 12;
      let size = file.size / Math.pow(1024, 2);
      let type = file.type.match(/image\/(jpeg|jpg|png)/i) || file.type.match(/application\/pdf/i);
      if (size <= Number(required)) {
        // let val = e.target.files[0  ];
        console.log('FileUp', size);
        if (type) {
          this.setState({
            formFileUploadBack: file,
            errorFileSizeBack: false,
            errorFileTypeBack: false,
          });
        } else {
          this.setState({
            formFileUploadBack: null,
            errorFileTypeBack: true,
            errorFileSizeBack: false,
          });
        }
      } else {
        this.setState({
          formFileUploadBack: null,
          errorFileSizeBack: true,
          errorFileTypeBAck: false,
        });
      }
    };
    this.enableButton = () => {
      this.setState({ canSubmit: true });
    };
    this.disableButton = () => {
      this.setState({ canSubmit: false });
    };

    this.showResponseModal = () => {
      this.setState((prevState) => {
        return { showResponseModal: !prevState.showResponseModal };
      });
    };
    this.closeResponseModal = (isSuccess) => {
      this.setState({ showResponseModal: false }, () => {
        if (isSuccess) {
          $('input').val('');
          $('select').val('');
          if (this.state.responseCashoutData !== 'error') {
            this.resetForm();
          }
          document.getElementById("cashout-form").reset();
        }
      });
    };

    this.getMissingFormValues = () => {
      let stateKeys = Object.keys(this.state);
      let missingVals = [];
      stateKeys.forEach((keyItem) => {
        if (
          keyItem === 'showResponseModal' ||
          keyItem === 'readyToSubmit' ||
          keyItem === 'responseCashoutData' ||
          keyItem === 'submitting' ||
          keyItem === 'missingValues' ||
          keyItem === 'canSubmit' ||
          keyItem === 'error' ||
          keyItem === 'errorFileSizeFront' ||
          keyItem === 'errorFileSizeBack' ||
          keyItem === 'errorTypeFileFront' ||
          keyItem === 'errorTypeFileBack'
        ) {
          return;
        }
        if (this.state[`${keyItem}`] === null || this.state[`${keyItem}`] === '') {
          missingVals.push(keyItem);
        }
      });
      console.log('MissingValues', missingVals);
      return missingVals;
    };

    this.isMissing = (type) => {
      if (this.state.missingValues.length === 0) {
        return;
      }
      let isMissing = this.state.missingValues.find((value) => value === type);
      // console.log('isMissing?',isMissing);
      return !isMissing ? false : true;
    };

    this.resetForm = () => {
      this.setState({
        account: '',
        amount: '',
        bank: '',
        bankCode: '',
        canSubmit: false,
        cardNumber: '',
        document: { upload_front: null, upload_reverse: null, type: '' },
        email: '',
        errorFileSizeFront: false,
        errorFileSizeBack: false,
        errorFileTypeFront: false,
        errorFileTypeBack: false,
        error: false,
        formFileUploadFront: null,
        formFileUploadBack: null,
        idType: '',
        lastName: '',
        method: '',
        password: '',
        phoneNumber: '',
        showVerificationID: false,
        readyToSubmit: false,
        speiNumber: '',
        submitting: false,
        userName: '',
        missingValues: [],
        showModalDeleteAccount: false,
        cashAccountDeleted: null,
        saveAccount: false,
      });
    };

    this.handleVerification = () => {
      this.setState({ showVerificationID: !this.state.showVerificationID });
    };

    this.validateForm = () => {
      this.setState({ submitting: true });
      if (this.state.canSubmit && this.state.amount > 99) {
        this.setState({
          missingValues: [],
        });
        this.submitCashout();
      } else {
        this.setState({
          submitting: false,
          missingValues: this.getMissingFormValues(),
        });
      }
    };

    this.validateInput = (type) => {
      // console.log('Type:', type);
      // console.log('Value:', this.state[type]);
      /**
       * If th type has a different value than null of
       * it is valid
       */
      if (this.state[type] !== null && this.state[type] !== '') {
        // console.log('ValidInput');
        let tempMissingVals = this.state.missingValues;

        if (type === 'bank' || type === 'bankCode') {
          let inputIdxBank = _.findIndex(tempMissingVals, (misVal) => misVal === 'bank');
          let inputIdxBankCode = _.findIndex(tempMissingVals, (misVal) => misVal === 'bankCode');
          if (!inputIdxBank || !inputIdxBankCode) {
            return;
          }
          tempMissingVals.splice(inputIdxBank, 1);
          tempMissingVals.splice(inputIdxBankCode, 1);
        } else {
          let inputIdx = _.findIndex(tempMissingVals, (misVal) => misVal === type);
          if (inputIdx === -1) {
            return;
          }
          tempMissingVals.splice(inputIdx, 1);
        }

        this.setState({ missingValues: tempMissingVals });
      } else {
        /**
         * Value is null
         */
        console.log('InvalidInput');
        let tempMissingVals = this.state.missingValues;
        console.log(tempMissingVals);
        if (type === 'bank' || type === 'bankCode') {
          if (tempMissingVals.length === 0) {
            tempMissingVals.push('bank');
            tempMissingVals.push('bankCode');
          } else {
            let bankExist = tempMissingVals.find((misVal) => misVal === 'bank');
            let bankCodeExist = tempMissingVals.find((misVal) => misVal === 'bankCode');
            if (
              bankCodeExist !== -1 &&
              bankExist !== -1 &&
              bankCodeExist !== undefined &&
              bankExist !== undefined
            ) {
              return;
            }
          }
        } else {
          let typeExist = tempMissingVals.find((misVal) => misVal === type);
          console.log('TypeExist ,', typeExist);
          if (typeExist !== -1 && typeExist !== undefined) {
            console.log('It exist');
            return;
          } else {
            console.log('It doesnt exist');
            tempMissingVals.push(type);
          }
        }
        console.log(tempMissingVals);
        this.setState({
          missingValues: tempMissingVals,
        });
      }
    };

    this.submitCashout = () => {
      let form_data = new FormData();
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      //incode variation
      let variation = "normal";
      if (this.props.incodeActive === true) {
        variation = "incode";
      }

      console.log("variation:", variation);
      let tempAccount = null;
      if (this.state.method === 'card') {
        tempAccount = this.state.cardNumber;
      } else if (this.state.method === 'spei') {
        tempAccount = this.state.speiNumber;
      } else {
        tempAccount = this.state.email;
      }
      console.log('submit with new Dociment ');
      form_data.append('variation', variation);
      form_data.append('amount', this.state.amount);
      form_data.append('password', this.state.password);
      if (this.state.cashAccountSelected !== null) {
        form_data.append('cashout_account_id', this.state.cashAccountSelected);
      }
      form_data.append('phone', (this.state.userData.phone === '' || this.state.userData.phone === null) ? this.state.phoneNumber : this.state.userData.phone);
      if (this.state.cashAccountSelected === null) {
        form_data.append('name', this.state.userName);
        form_data.append('last_name', this.state.lastName);
        form_data.append('bank', this.state.bank);
        form_data.append('bank_code', this.state.bankCode);
        form_data.append('method', this.state.method);
        form_data.append('account', tempAccount);
        form_data.append('save_account', this.state.saveAccount);
      }
      if (!this.props.validDoc) {
        form_data.append('document[upload_front]', this.state.formFileUploadFront);
        form_data.append('document[upload_reverse]', this.state.formFileUploadBack);
        form_data.append('document[type]', this.state.idType);
      }

      API.postCashout(form_data, config)
        .then((response) => {
          this.cashoutCallback(response.data);
          if (response.data.success) {
            if (this.state.saveAccount) {
              this.getCashoutAccounts();
            }
            // Hacemos refetch cashouts
            // Lo hacemos por callback por que este componente es de clase
            if (typeof this.props.refetchCahouts === 'function') {
              this.props.refetchCahouts()
            }
          }
        })
        .catch((error) => {
          Sentry.captureException(error);
          this.setState({
            submitting: false,
            showResponseModal: true,
            responseCashoutData: 'error',
          });
        });
    };

    this.cashoutCallback = (data) => {
      this.setState({
        showResponseModal: true,
        responseCashoutData: data,
        submitting: false,
      });
    };

    this.handleSaveAccount = () => {
      this.setState({saveAccount: !this.state.saveAccount});
    }

    this.deleteCashoutAccount = (id) => {
      API.deleteCashoutAccount(id).then((response) => {
        if (response.data.success) {
          let tempAccounts = this.state.cashoutAccounts.filter(item => item.id !== id );
          let selected = this.state.cashAccountSelected;
          if (selected === id) {
            if (tempAccounts.length > 0) {
              selected = tempAccounts[0].id;
            } else {
              selected = null;
            }
          }
          this.setState({cashoutAccounts: tempAccounts, cashAccountSelected: selected, showModalDeleteAccount: false});
        }
      });

    }

    this.handleChangeCashoutAccount = (id) => {
      this.setState({cashAccountSelected: id, method: ''});
    }

    this.handleModalDeleteAccount = () => {
      this.setState({showModalDeleteAccount: !this.state.showModalDeleteAccount});
    }

    this.setDeleteAccount = (id) => {
      let tempAccount = this.state.cashoutAccounts.find(item => item.id === id );
      this.setState({cashAccountDeleted: tempAccount, showModalDeleteAccount: true});
    }

    this.getCashoutAccounts = () => {
      API.getCashoutAccounts().then((response) => {
        let accounts = response.data.accounts;
        let accountSelected = this.state.cashAccountSelected;
        if (accounts.length > 0 && accountSelected === null) {
          accountSelected = accounts[0].id;
        }
        this.setState({cashoutAccounts: accounts, cashAccountSelected: accountSelected});
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log(prevState.responseCashoutData, this.state.responseCashoutData);
    if (this.state.responseCashoutData !== prevState.responseCashoutData) {
      if (!this.state.responseCashoutData.success) {
        return;
      }
      console.log('Update Balance');
      // let userData = utils.getUserInfo();
      this.props.updateUserBalance(this.state.responseCashoutData.balance);
      // this.props.updateUserInfo(userData);
    }
  }

  componentDidMount() {
    axios.all([API.fetchCashoutBanks(), API.fetchFeeniciaCards()]).then(
      axios.spread((banks, cards) => {
        this.setState(
          {
            banksList: banks.data.banks,
            cardsList: cards.data.cards,
            mounted: true,
          },
          () => { }
        );
      })
    );

    this.setState({userData: utils.getUserInfo()});
    this.getCashoutAccounts();
  }

  render() {
    let isReady = false;
    // If you have documents stored, dont validate and show the file inputs and typeFile field
    //Else check if the documents are valid and meke the valid form
    if (Number(this.state.amount) > 99) {
      isReady = true;
    }
    if (this.state.mounted) {
      return (
        <div className=" flex-col col-no-padding col-80 col-offset-10">
          <Modal
            show={this.state.submitting}
            className="responseDepositModal absoluteModal"
            dialogClassName="responseDepositDialog"
            backdropClassName="responseDepositBackdrop"
          >
            <div className="flex-container justify align column">
              <i className="ion-load-a loading"> </i>
              <div className="flex-row row-center">
                <div className="flex-col col-center text-center">
                  <div className="flex-col col-center">
                    <strong className="flex-justify align center">No cierres esta ventana,</strong>
                  </div>
                  <div className="flex-col col-center">
                    <strong className="flex-justify align center">
                      Estamos procesando tu petición...
                    </strong>
                  </div>
                  <div className="flex-col col-center">
                    <strong className="flex-justify align center">
                      Espera un momento porfavor...
                    </strong>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
          <Modal
            dialogClassName="modal-dialog-xxxs"
            className=""
            show={this.state.showModalDeleteAccount}
            style={{marginTop: '5rem', zIndex: '1065'}}
          >
            <div className="transactionPanel">
              <div id="panelTitle" className="panel-abonar-titulo failed">
                <span>Borrar cuenta de retiro</span>
                <button className="cerrar-panel" onClick={this.handleModalDeleteAccount}>
                  <div className="btn-icon-close">
                    <i className="ion-ios-close-empty"></i>
                  </div>
                </button>
              </div>
              <div className="flex-row">
                <div className='flex-col text-center'>
                  <h5>¿Esta seguro que desea eliminar la cuenta de retiro <strong>{this.state.cashAccountDeleted && this.state.cashAccountDeleted.name}</strong>?</h5>
                </div>
              </div>
              <div className="flex-row" style={{marginBottom: '1rem', width: '50%', margin: '0 auto'}}>
                <div className="flex-container center around">
                  <div className='flex-col'>
                    <Button className="btn-pick btn-default btn btn-rojo flex-col" onClick={() => {this.deleteCashoutAccount(this.state.cashAccountDeleted && this.state.cashAccountDeleted.id)}}>
                      Eliminar
                    </Button>
                  </div>
                  <div className='flex-col'>
                    <Button className="btn-pick btn-default btn flex-col" onClick={this.handleModalDeleteAccount}>
                      Cancelar
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
          <div className="text-container ">
            <div className="flex-row">
              <div className="flex-col important-message">
                <strong className="flex-row" style={{ fontStyle: 'uppercase' }}>
                  Importante leer estos 4 puntos.
                </strong>
                <div className="flex-row">
                  <ol>
                    <li>
                      Los datos de tu identificación y los que ingreses aquí, deben coincidir con
                      los de tu cuenta de Pickwin.
                    </li>
                    <li>
                      Los retiros se realizarán a la última tarjeta que hayas utilizado para abonar
                      saldo. En caso de que ésta haya sido de débito.
                    </li>
                    <li>Los retiros pueden tardar hasta 7 días hábiles.</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div className={`form-wrapper flex-container`}>
            <Formsy
              className="flex-col col-no-padding"
              onValid={this.enableButton}
              onInvalid={this.disableButton}
              id="cashout-form"
            >
              <div className='flex-row'>
                <div className='flex-col'>
                  <ButtonToolbar className="cantidad-abonar-toolbar flex-row row-no-padding" style={{margin: '0'}}>
                    <ToggleButtonGroup
                      className="flex-row"
                      type="radio"
                      name="savedCard"
                      value={this.state.cashAccountSelected}
                      onChange={this.handleChangeCashoutAccount}
                      style={{flexWrap: 'wrap', margin: '0'}}
                    >
                      {this.state.cashoutAccounts !== null && this.state.cashoutAccounts.map(item => {
                        return (
                          <ToggleButton key={item.id} value={item.id} className="btn-formulario flex-container around align col-50">
                            <div className='flex-container around align' style={{fontSize: '10px'}}>
                              {item.type === 'card' &&
                                <i className="icon ion-card" style={{fontSize: '16px'}}></i>
                               ||
                                <i className="icon ion-document-text" style={{fontSize: '16px'}}></i>
                              }
                              <span>{item.name}</span>
                              <i className="icon ion-trash-a" onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.setDeleteAccount(item.id) }} style={{fontSize: '18px', color: '#d6242b'}}></i>
                            </div>
                          </ToggleButton>
                        )
                      })}
                    </ToggleButtonGroup>
                  </ButtonToolbar>
                </div>
              </div>
              {(this.state.cashoutAccounts && this.state.cashoutAccounts.length > 0) &&
                <div className='flex-row'>
                  <Button
                    className="btn-pick flex-col"
                    style={{border: 'none', boxShadow: 'none', borderRadius: '0px !important'}}
                    onClick={() => { this.setState({cashAccountSelected: null}); }}
                  >
                    Nueva Cuenta
                  </Button>
                </div>
              }

              {this.state.cashAccountSelected === null &&
                <>
                  <div className="flex-row">
                    <div className="flex-col col-50 col-no-padding">
                      <RetirarFormsy
                        label="Nombre"
                        name="userName"
                        validateInput={this.validateInput}
                        inputHandler={this.handleNameUser}
                        type="USERNAME"
                        minLength={1}
                        validations="minLength:1"
                        validationError="Tù nombre no puede estar vacìo."
                        required
                      />
                      {this.isMissing('userName') ? (
                        <ErrorMessageValidation label="Llena este campo para poder continuar" />
                      ) : (
                          ''
                        )}
                    </div>
                    <div className="flex-col col-50 col-no-padding">
                      <RetirarFormsy
                        label="Apellidos"
                        name="lastName"
                        validateInput={this.validateInput}
                        inputHandler={this.handleLastName}
                        type="LASTNAME"
                        validations="minLength:1"
                        validationError="El apellido no puede estar vacío."
                        required
                      />
                      {this.isMissing('lastName') ? (
                        <ErrorMessageValidation label="Llena este campo para poder continuar" />
                      ) : (
                          ''
                        )}
                    </div>
                  </div>

                  {(this.state.userData.phone === '' || this.state.phone === null) &&
                    <div className="flex-row">
                      <div className="flex-col col-no-padding">
                        <RetirarFormsy
                          label="Teléfono"
                          name="phone"
                          validateInput={this.validateInput}
                          inputHandler={this.handlePhone}
                          type="PHONE"
                          validations={('isNumeric', { minLength: 8, maxLength: 32 })}
                          validationError={'Debes de ingresar un número de teléfono válido.'}
                          required
                        />
                        {this.isMissing('phoneNumber') ? (
                          <ErrorMessageValidation label="Llena este campo para poder continuar" />
                        ) : (
                            ''
                          )}
                      </div>
                    </div>
                  }

                  <div className="flex-row">
                    <RetirarFormsy
                      cardsSaved={this.state.cardsList.length > 0}
                      label="Dinos dónde quieres recibir tu pago."
                      name="cashoutMethod"
                      validateInput={this.validateInput}
                      inputHandler={this.handleMethod}
                      type="RETIRO"
                      required
                    />
                  </div>
                  <div className="flex-row">
                    {this.isMissing('method') ? (
                      <ErrorMessageValidation label="Llena este campo para poder continuar" />
                    ) : (
                        ''
                      )}
                  </div>
                </>
              }
              {(this.state.method !== '' && this.state.method === 'spei') ||
                this.state.method === 'card' ? (
                  <React.Fragment>
                    <div className="flex-row">
                      <RetirarFormsy
                        label="Banco"
                        name="bank"
                        validateInput={this.validateInput}
                        inputHandler={this.handleBank}
                        type="BANKS"
                        banksList={this.state.banksList}
                        required
                      />
                    </div>
                    <div className="flex-row">
                      {this.isMissing('bank') ? (
                        <ErrorMessageValidation label="Llena este campo para poder continuar" />
                      ) : (
                          ''
                        )}
                    </div>
                  </React.Fragment>
                ) : null}
              {this.state.method !== '' && this.state.method === 'card' ? (
                <React.Fragment>
                  <div className="flex-row">
                    <RetirarFormsy
                      label="Número de tú Tarjeta de débito"
                      name="cardNumber"
                      validateInput={this.validateInput}
                      inputHandler={this.handleCardNumber}
                      type="CARD_NUMBER"
                      validations={('isNumeric', { maxLength: 16 })}
                      validationError={'El número debe de tener entre 13 y 16 carácteres.'}
                      required
                    />
                  </div>
                  <div className="flex-row help-note important-message ">
                    <i className="ion-alert-circled" />
                    <h5 className="text-center ">
                      Asegúrate de que sea la misma tarjeta con la que abonaste saldo.
                    </h5>
                  </div>
                  {this.isMissing('cardNumber') ? (
                    <ErrorMessageValidation label="Llena este campo para poder continuar" />
                  ) : (
                      ''
                    )}
                </React.Fragment>
              ) : null}

              {this.state.method !== '' && this.state.method === 'spei' ? (
                <React.Fragment>
                  <div className="flex-row">
                    <RetirarFormsy
                      label="Número de tu cuenta CLABE"
                      name="cardNumber"
                      validateInput={this.validateInput}
                      inputHandler={this.handleSpeiNumber}
                      type="SPEI_NUMBER"
                      validations={{
                        isNumeric: true,
                        isBetween: (values, value) => {
                          let re = /[0-9]{18,}/;
                          return re.test(value);
                        },
                      }}
                      validationError={'El número debe de tener 18 carácteres.'}
                      required
                    />
                  </div>
                  <div className="flex-row">
                    {this.isMissing('speiNumber') ? (
                      <ErrorMessageValidation label="Llena este campo para poder continuar" />
                    ) : (
                        ''
                      )}
                  </div>
                </React.Fragment>
              ) : null}
              <div className="flex-row">
                <RetirarFormsy
                  label="Cantidad a retirar"
                  name="amount"
                  validateInput={this.validateInput}
                  inputHandler={this.handleAmount}
                  type="AMOUNT"
                  validations={{
                    isNumeric: true,
                    isBetween: (values, value) => {
                      return (
                        value >= 100 && value <= this.props.availableForCashout.available / 100
                      );
                    },
                  }}
                  validationError={`Puedes retirar de $100 hasta $${this.props.availableForCashout
                    .available / 100}.`}
                  required
                />
              </div>
              <div className="flex-row">
                {this.isMissing('amount') ? (
                  <ErrorMessageValidation label="Llena este campo para poder continuar" />
                ) : (
                    ''
                  )}
              </div>

              {this.props.incodeActive === false && (
                <React.Fragment>
                  {this.props.validDoc ? null : (
                  <div className="flex-row">
                    <div className="flex-col col-no-padding">
                      <RetirarFormsy
                        label="Tipo de identificación"
                        name="idType"
                        validateInput={this.validateInput}
                        inputHandler={this.handleIdType}
                        type="ID_TYPE"
                        required
                      />
                      {this.isMissing('idType') ? (
                        <ErrorMessageValidation label="Llena este campo para poder continuar" />
                      ) : (
                          ''
                        )}
                    </div>
                  </div>
                )}
                {this.state.idType !== null && this.state.idType !== '' ? (
                  <div className="flex-col col-no-padding">
                    <ControlLabel className="flex-row">
                      <span className="flex-col text-center">Verifica tú identidad</span>
                    </ControlLabel>
                    <div className=" flex-row">
                      <div className=" flex-col ">
                        <div className="img-container flex-container justify align">
                          {this.state.idType !== 'pasaporte' ? (
                            <img src={idFront} className="id-info img-responsive" alt="" />
                          ) : (
                              <img src={passportFront} className="id-info img-responsive" alt="" />
                            )}
                        </div>
                        <RetirarFormsy
                          label="Frente"
                          name="document[upload_front]"
                          validateInput={this.validateInput}
                          fileData={this.state.formFileUploadFront}
                          inputHandler={this.handleFrontDocument}
                          type="FILE"
                          required
                          help={[
                            'Sube una copia del lado frontal de tu identificación oficial, en dónde se pueda ver tú fotografía',
                            'Tamaño máximo del archivo de 12 MB.',
                          ]}
                        />
                        {this.state.errorFileTypeFront ? (
                          <div className="error-formulario">
                            <strong>
                              Tu archivo debe de tener alguno de los siguientes formatos
                              png/jpg/jpeg/pdf.
                            </strong>
                          </div>
                        ) : null}
                        {this.state.errorFileSizeFront ? (
                          <div className="error-formulario">
                            <strong>El tamaño de tu archivo no debe de ser superior a 12 MB </strong>.
                          </div>
                        ) : null}
                        {this.isMissing('formFileUploadFront') ? (
                          <ErrorMessageValidation label="Sube un archivo para poder continuar" />
                        ) : (
                            ''
                          )}
                      </div>
                      <div className="flex-col ">
                        <div className="img-container flex-container justify align">
                          {this.state.idType !== 'pasaporte' ? (
                            <img src={idBack} className="id-info img-responsive" alt="" />
                          ) : (
                              <img src={passportBack} className="id-info img-responsive" alt="" />
                            )}
                        </div>
                        <RetirarFormsy
                          label="Reverso"
                          name="document[upload_reverse]"
                          validateInput={this.validateInput}
                          fileData={this.state.formFileUploadBack}
                          inputHandler={this.handleBackDocument}
                          type="FILE"
                          required
                          help={[
                            'Sube una copia del lado trasero de tu identificación oficial para ver la información',
                            'Tamaño máximo del archivo de 12 MB.',
                          ]}
                        />
                        {this.state.errorFileTypeBack ? (
                          <div className="error-formulario">
                            <strong>
                              Tu archivo debe de tener alguno de los siguientes formatos
                              png/jpg/jpeg/pdf.
                            </strong>
                          </div>
                        ) : null}
                        {this.state.errorFileSizeBack ? (
                          <div className="error-formulario">
                            <strong>El tamaño de tu archivo no debe de ser superior a 12 MB </strong>.
                          </div>
                        ) : null}
                        {this.isMissing('formFileUploadBack') ? (
                          <ErrorMessageValidation label="Sube un archivo para poder continuar" />
                        ) : (
                            ''
                          )}
                      </div>
                    </div>
                  </div>
                ) : null}
              </React.Fragment>
              )}

              <div className="flex-row">
                <RetirarFormsy
                  label="Contraseña"
                  name="password"
                  validateInput={this.validateInput}
                  inputHandler={this.handlePassword}
                  type="PASSWORD"
                  validations={{ minLength: 1 }}
                  validationError={'Tú contraseña no puede estar vacía.'}
                  required
                />
              </div>
              <div className="flex-row">
                {this.isMissing('password') ? (
                  <ErrorMessageValidation label="Llena este campo para poder continuar" />
                ) : (
                    ''
                  )}
              </div>

              {this.state.cashAccountSelected === null &&
                <div className='flex-row'>
                  <RetirarFormsy
                    type={'SAVE_INFO'}
                    name={'saveCard'}
                    label={'Guardar Cuenta'}
                    inputHandler={this.handleSaveAccount}
                  />
                </div>
              }
              <div className="flex-container column">
                <div className="flex-row">
                  <Button
                    onClick={this.validateForm}
                    type="submit"
                    disabled={this.state.submitting}
                    className="callToAction btn-form btn-pick"
                  >
                    {this.state.submitting ? (
                      <div className="flex-container justify align">
                        <i className="ion-load-a loading small" />
                      </div>
                    ) : (
                        <span className="btn-text">
                          Solicitar Retiro
                        </span>
                      )}
                  </Button>
                </div>
              </div>
            </Formsy>
          </div>
          <ResponseCashout
            showCashoutResponse={this.state.showResponseModal}
            closeCashoutResopnse={this.closeResponseModal}
            responseData={this.state.responseCashoutData}
            updateUserInfo={this.props.updateUserInfo}
          />
        </div>
      );
    } else {
      return <Loading label="Espera un momento porfavor..." />;
    }
  }
}

export default FormCashout;
