import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { marked } from 'marked'

const MarkdownParser = ({ url }) => {
  const [markdown, setMarkdown] = useState('')
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchMarkdown = async () => {
      try {
        const response = await axios.get(url)
        setMarkdown(response.data)
        setError(null)
      } catch (err) {
        setError('Error al cargar el contenido')
        console.error('Error:', err)
      } finally {
        setLoading(false)
      }
    }

    fetchMarkdown()
  }, [url])

   marked.setOptions({
    breaks: true,
    sanitize: true,
  })


  if (loading) {
    return <div>Cargando...</div>
  }

  if (error) {
    return <div>{error}</div>
  }

  return (
    <div className="markdown-content">
      <div dangerouslySetInnerHTML={{ __html: marked(markdown) }} />
    </div>
  )
}

export default MarkdownParser