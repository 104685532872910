import React from 'react';
import axios from 'axios';
import moment from 'moment';
import { Redirect, Link } from 'react-router-dom';
import { Button } from 'react-bootstrap/lib/';

import API from '../../utils/api';
import Loading from '../Loading/Loading';
import Utils from '../../utils/survivorUtils';
import utils from '../../utils/utils';
import Futbol from '../../assets/images/FutbolBall-50.png';
import Americano from '../../assets/images/AmericanoBall-50.png';
import Basketball from '../../assets/images/BasketballBall-50.png';
import Baseball from '../../assets/images/BaseballBall-50.png';
import Golf from '../../assets/images/GolfBall-50.png';
import Tenis from '../../assets/images/TennisBall-50.png';
import Box from '../../assets/images/box.png';
import F1 from '../../assets/images/Volante-50.png';
import Hockey from '../../assets/images/Hockey.png';
import closeW from '../../assets/images/close.png';

import numeral from 'numeral';

import SubscriptionRxs from '../../utils/subscriptions';
import TablaPosiciones from '../TablaPosiciones/TablaPosiciones';
import * as Sentry from '@sentry/browser';
import $ from 'jquery';
import _ from 'underscore';
import { PickSelector } from './PickSelector';

class MiSurvivorPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      entriesInfo: [],
      grouStandings: [],
      dataPicksLoaded: false,
      loaded: false,
      activeStage: 0,
      numeroEntries: this.props.numeroEntries,
      liveFixturesData: this.props.liveFixturesData,
      survivorInfo: [],
      poolWeekSelected: null,
      posActualPage: 1,
      prizes: [],
      quinaIndex: null,
      redirectLobby: false,
      selectedWeekfixtures: [],
      searchNick: null,
      tiesDistribution: null,
      selectedEntryData: null,
      standings: [],
      submitting: false,
      activeSort: {
        posiciones: false,
        jugadores: false,
        alive: false,
        premios: false,
      },
      queryParams: {
        page: 1,
        limit: 100,
        nick: '',
        sort: [],
        order: null,
      },
    };

    /*********************
     * Survivor Utilities*
     *********************/
    this.getAganar = Utils.getAganar.bind(this);
    this.handleFixtureUpdateSocket = Utils.handleFixtureUpdateSocket.bind(this);
    this.handleCloseSurvivor = Utils.handleCloseSurvivor.bind(this);
    this.fixtureCloseSocket = Utils.fixtureCloseSocket.bind(this);
    this.standingsUpdateSocket = Utils.standingsUpdateSocket.bind(this);
    this.updatePrizeDistribution = Utils.updatePrizeDistribution.bind(this);
    this.updateStandingsComplete = Utils.updateStandingsComplete.bind(this);
    this.updateStandingsAganar = Utils.updateStandingsAganar.bind(this);
    this.updateStandingsPosition = Utils.updateStandingsPosition.bind(this);

    /*********************
     * Component methods *
     ********************/
    this.handleConfirmation = this.handleConfirmation.bind(this);
    this.handleCopiarPicks = this.handleCopiarPicks.bind(this);
    this.handleWeeklyActive = this.handleWeeklyActive.bind(this);
    this.redirectLobby = this.redirectLobby.bind(this);
    this.selectLayout = this.selectLayout.bind(this);
    this.setLoaded = this.setLoaded.bind(this);
    this.setSport = this.setSport.bind(this);
    this.updateData = this.updateData.bind(this);
    this.updateStandings = this.updateStandings.bind(this);
    this.changeStatusPool = this.changeStatusPool.bind(this);
    this.updateSelectedEntryData = (selectedEntryData) => this.setState({ selectedEntryData });
    // console.log('MiSurvivorPanel');
    this.queryParams = {
      page: 1,
      limit: 100,
      nick: '',
      sort: [],
      order: null,
    };

    /**
     * This function handles the Sort order in the TablasPostions
     * @param type
     */
    this.sortPositionList = (type) => {
      console.log('%c Sort PositionList', 'color:rgb(254, 86, 150); font-size:1.2em;', type);
      this.setState({
        submittingSearch: true,
      });
      // console.log(type);
      let temporalQueryParams = this.state.queryParams;
      //let tempPositions = this.state.standings;
      let tempActiveSort = this.state.activeSort;
      //let tempItems = this.state.standings.items;

      let orderStatus;
      switch (type) {
        case 'posiciones':
          orderStatus = this.state.activeSort.posiciones ? -1 : 1;
          temporalQueryParams.sort = 'rank';
          // console.log(type, orderStatus);
          tempActiveSort.posiciones = !tempActiveSort.posiciones;
          if (tempActiveSort.posiciones) {
            tempActiveSort.jugadores = false;
            tempActiveSort.aciertos = false;
            tempActiveSort.premios = false;
          }
          break;
        case 'jugadores':
          orderStatus = this.state.activeSort.jugadores ? -1 : 1;
          temporalQueryParams.sort = 'nick';
          // console.log(type, orderStatus);
          tempActiveSort.jugadores = !tempActiveSort.jugadores;
          if (tempActiveSort.jugadores) {
            tempActiveSort.posiciones = false;
            tempActiveSort.alive = false;
            tempActiveSort.premios = false;
          }
          break;
        case 'alive':
          orderStatus = this.state.activeSort.alive ? -1 : 1;
          temporalQueryParams.sort = 'alive';
          if (this.state.survivorInfo.survivor.status === 'closed') {
            // console.log(type, orderStatus);
            tempActiveSort.alive = !tempActiveSort.alive;
            if (tempActiveSort.alive) {
              tempActiveSort.jugadores = false;
              tempActiveSort.posiciones = false;
              tempActiveSort.premios = false;
            }
          } else {
            // console.log(type, orderStatus);
            tempActiveSort.alive = !tempActiveSort.alive;
            if (tempActiveSort.alive) {
              tempActiveSort.jugadores = false;
              tempActiveSort.posiciones = false;
              tempActiveSort.premios = false;
            }
          }
          break;
        case 'premios':
          orderStatus = this.state.activeSort.premios ? -1 : 1;
          temporalQueryParams.sort = 'prize';
          if (tempActiveSort.premios) {
            tempActiveSort.jugadores = false;
            tempActiveSort.posiciones = false;
            tempActiveSort.alive = false;
          }
          tempActiveSort.premios = !tempActiveSort.premios;
          break;
        default:
          return;
      }
      temporalQueryParams.order = orderStatus === -1 ? 'DESC' : 'ASC';
      temporalQueryParams.page = 1;
      this.setState(
        (prevState) => {
          console.log(tempActiveSort);
          console.log(prevState.activeSort);
          return {
            activeSort: tempActiveSort,
            sortChanged: !prevState.sortChanged,
            queryParams: temporalQueryParams,
            liveFixturesData: Date.now(),
            actualPage: 1,
            // standings: tempPositions,
          };
        },
        () => {
          API.fetchSurvivorStandings(this.state.survivorInfo.survivor.id, this.state.queryParams)
            .then((response) => {
              console.log('Response', response.data);
              this.callbackSort(response.data);
            })
            .catch((error) => {
              console.log(error);
              Sentry.captureException(error, {
                extra: 'Error in quiniela fetching standings',
              });
            });
          // this.queryParams.sort = [];
        }
      );
    };

    /**
     * This functon reset the nick Value Data of the Search
     * and returns the standings
     */
    this.resetNickValue = () => {
      if (!this.state.survivorInfo.survivor) {
        return;
      }

      this.setState(
        {
          searchNick: '',
          submittingSearch: true,
        },
        () => {
          this.queryParams.nick = '';
          API.fetchSurvivorStandings(this.state.survivorInfo.survivor.id, this.queryParams)
            .then((response) => {
              try {
                utils.checkAxiosAllErrors([response]);
              } catch (e) {
                console.error(e);
                // Sentry.captureException( e );
              }
              this.callbackSearchUser(response.data);
            })
            .catch((e) => {
              Sentry.captureException(e);
            });
        }
      );
    };

    /**
     * This function update the entry data after an update in the Panel
     *
     */
    this.updateEntryData = () => {
      console.log('updateEntryData');
      API.getEntriesInfo(this.state.selectedEntryData.id).then((dataEntriesInfo) => {
        this.setState(
          {
            entriesInfo: dataEntriesInfo.data,
            selectedEntryData: dataEntriesInfo.data.entry,
            // similarPools          : dataSimilarEntries.data,
          },
          () => {
            setTimeout(() => {
              this.handleConfirmation();
            }, 500);
            // this.props.afterUpdatePanels();
            // this.setLoaded();
            // console.log('data Updated !');
          }
        );
      });
    };

    /**
     * This Function handles my entry row in the table to be synced with the standings data
     * @params standingsUpdate { Object }
     */
    this.handleMyEntryStanding = (standingsUpdate) => {
      // console.log('%c Handle My EntrySanding Update','color:#2e8ccf; font-size:2em;');
      // console.log(standingsUpdate);
      let temporalSelectedEntry = this.state.selectedEntryData;

      if (!temporalSelectedEntry) {
        return;
      }

      let myUpdatedPosition = standingsUpdate.standings.find(
        (standing) => standing.survivor_entry_id === temporalSelectedEntry.id
      );

      if (!myUpdatedPosition || myUpdatedPosition !== -1) {
        this.setState({
          selectedEntryData: temporalSelectedEntry,
        });
        return;
      } else {
        // console.log( myUpdatedPosition );
        temporalSelectedEntry.survivor_standing.rank = myUpdatedPosition.rank;
        temporalSelectedEntry.survivor_standing.alive = myUpdatedPosition.alive;
        temporalSelectedEntry.survivor_standing.points = myUpdatedPosition.points;
        temporalSelectedEntry.survivor_standing.user_prize = myUpdatedPosition.user_prize;
        temporalSelectedEntry.user_prize = myUpdatedPosition.user_prize;
        temporalSelectedEntry.survivor_user_prize = myUpdatedPosition.user_prize;
        this.setState({
          selectedEntryData: temporalSelectedEntry,
        });
      }
    };

    this.handleMyEntryStatus = (liveFixtures) => {
      return;
      // console.log('%c Handle My EntryStatus','color:#2e8ccf; font-size:2em;');
      // console.log(liveFixtures);

      const currentWeek = this.state.survivorInfo.currentWeek.id;
      let myCurrentPick = !this.state.selectedEntryData.survivor_picks
        ? null
        : this.state.selectedEntryData.survivor_picks[currentWeek];
      if (!myCurrentPick) {
        console.error('SurvivorPicks not available');
        return;
      }

      let myFixture = liveFixtures.live_fixtures.find(
        (liveFixture) => liveFixture.id === myCurrentPick.fixture_id
      );
      if (!myFixture) {
        console.error('Fixture Not Available');
        return;
      }
      // console.log('%c My pick is from This fixture','color:#fec830; font-size:1em;')
      // console.log(myFixture)
      // myFixture.winning_pick
    };

    /**
     * This function changes the search nick in the state
     * @param ev { Object }
     */
    this.changeSearchNick = (ev) => {
      console.log('changeNick');
      this.queryParams.page = 1;
      this.setState({
        searchNick: ev.target.value,
      });
    };

    /**
     * This function fetch the standings for the new standings
     * @param ev { Object }
     * @param obj { Object }
     */
    this.fetchSearchableStandings = (ev, obj) => {
      if (!this.state.survivorInfo.survivor === undefined) {
        return;
      }
      this.setState({ submittingSearch: true });
      // if(this.state.submittingSearch === true ) { return;}
      // console.log('%c Search users', 'color:rgb(110, 223, 85);font-size:1.5em;');
      // console.log(ev.target.value);
      // console.log(ev.target);
      this.queryParams.nick = this.state.searchNick;
      API.fetchSurvivorStandings(this.state.survivorInfo.survivor.id, this.queryParams)
        .then((response) => {
          // console.log(response);
          this.callbackSearchUser(response.data);
        })
        .catch((error) => console.log(error));
    };
    this.fetchSearchableStandings = _.debounce(this.fetchSearchableStandings, 500);
    /**
     * This function handles the callback of the new fetched standings
     * @params data { Object }
     */
    this.callbackSearchUser = (data) => {
      console.log(data);
      //let newStandings = data;

      this.setState({
        emptyList: data.items.length === 0 ? true : false,
        standings: data,
        submittingSearch: false,
        // liveFixtureData: Date.now(),
        // sortChanged: true,
      });
    };

    /**
     * this function handles the callback of the Sort standings
     * @params data { Object }
     */
    this.callbackSort = (data) => {
      let newStandings = data;
      let newUpdatedPositions = newStandings.items;
      if (this.state.survivorInfo.survivor.status === 'live') {
        newUpdatedPositions = this.updateStandingsAganar(
          newStandings.items,
          this.state.survivorInfo.survivor.prize_currency,
          this.state.tiesDistribution,
          this.state.prizes,
          this.state.survivorInfo.survivor
        );
      }

      console.log('newstandings', newStandings);

      this.setState(
        {
          emptyList: data.items.length === 0 ? true : false,
          standings: newStandings,
          submittingSearch: false,
          sortChanged: true,
        },
        () => {
          this.setState({
            sortChanged: false,
          });
        }
      );
    };

    /**
     * This function handles a call to the API
     * to get the new page of the positions
     */
    this.siguientePagPositions = (eventKey) => {
      console.log('Next Positions...');
      let loaderElement = $('#loading');
      let tempActiveSort = null;
      // console.log(loaderElement);
      if (loaderElement !== undefined && loaderElement.length > 0) {
        loaderElement[0].style.display = 'flex';
      }
      // loading.style.position('flex');
      let temporalPositions = this.state.standings.items;
      // console.log('siguiente');
      let val = eventKey;

      this.queryParams.page = this.queryParams.page + 1;
      if (this.queryParams.page > this.state.standings.pages) {
        this.queryParams.page = this.queryParams.page - 1;
        return;
      }
      if (this.state.submitting) {
        return;
      }

      API.fetchSurvivorStandings(this.state.survivorInfo.survivor.id, this.queryParams).then(
        (response) => {
          this.setState(
            (prevState) => {
              console.log(tempActiveSort);
              let newPosiciones = response.data;

              let newItems = response.data.items !== null ? response.data.items : null;

              newItems.forEach((item) => {
                temporalPositions.push(item);
              });

              // console.log('NEWPos',temporalPositions);
              newPosiciones.items = temporalPositions;
              return {
                standings: newPosiciones,
                posActualPage: this.queryParams + 1,
              };
            },
            () => {
              // if(this.props.sortPositionList !== undefined){
              //   setTimeout( () => {
              //     console.log(tempActiveSort);
              //     this.props.sortPositionList( tempActiveSort, true );
              //   }, 150);
              // }
              if (this.updateScrollPositions !== undefined) {
                this.updateScrollPostions(this.state.posiciones);
              }
              if (loaderElement !== undefined && loaderElement.length > 0) {
                loaderElement[0].style.display = 'none';
                let avatars = $('.score-avatars').height('auto');
              }
            }
          );
        }
      );
    };

    this.initializeSocket = (survivorId) => {
      API.subscribeSurvivorSocketLiveStandings(
        survivorId,
        (standingsUpdatedData) => {
          // let tempQueryParams = this.state.queryParams;
          // tempQueryParams.page = this.state.standings.pages;

          // this.setState({ queryParams: tempQueryParams });

          this.handleMyEntryStanding(standingsUpdatedData);
          this.standingsUpdateSocket(standingsUpdatedData);
        },
        (survivorClose) => {
          this.handleCloseSurvivor(survivorClose);
        }
      );
    };

    /**
     * this function handles the table height of the panel
     */
    this.handleTableHeight = () => {
      // console.log('%c Handles Table Pos Height', 'background: rgb(255, 199, 48);');
      // console.log( this.props.maxHeight );
      let panelElements = $(`.panelMisQuinielas.survivor`);
      let myPanel = null;
      if (panelElements.length !== 0) {
        // console.log('elements',panelElements)
        _.each(panelElements, (el, elIdx) => {
          if (
            el.attributes.hasOwnProperty('data-entry') &&
            el.attributes['data-entry'].value === this.props.entry.id
          ) {
            myPanel = el;
            // console.log( 'myElement', myPanel );
          }
        });
      }
      if (myPanel) {
        setTimeout(() => {
          $(myPanel)
            .find('.position-list .overflow-360')
            .attr('style', '');

          let maxHeight =
            this.props.maxHeight < 360 ? $('.control-registros').height() : this.props.maxHeight;
          // console.log('calculate table size...', $(myPanel).height() );
          let titlePanelHeight = $(myPanel)
            .find('.title-quiniela.main')
            .height();
          let titleButtonsHeight = $(myPanel)
            .find('.titulo-mis-picks')
            .height();
          let positionsList = $(myPanel).find('.lista-posiciones');
          let titlePositionsList = $(myPanel)
            .find('.position-list .title-quiniela')
            .height();
          let searchFieldHeight = $(myPanel)
            .find('#search-field-nick')
            .height();
          let headerPositionsHeight = $(myPanel)
            .find('.position-list .position-header')
            .height();
          // console.log( maxHeight, titlePanelHeight , titleButtonsHeight , titlePositionsList , searchFieldHeight , headerPositionsHeight);
          let positionListHeight = Math.ceil(
            maxHeight -
              30 -
              (titlePanelHeight +
                titleButtonsHeight +
                titlePositionsList +
                searchFieldHeight +
                headerPositionsHeight)
          );

          // console.log(positionListHeight)
          if (positionListHeight < 0 || positionListHeight === NaN) {
            return;
          }

          positionsList.height(positionListHeight - 25);
          $(myPanel)
            .find('.position-list .overflow-360')
            .animate({ height: positionListHeight }, '100');
        }, 300);
      }
    };
  }

  changeStatusPool(poolId) {
    let tempAvailablePools = this.state.poolInfo;
    console.log('changeStatus Pool', tempAvailablePools);
    console.log('changeStatus Pool', poolId);
    tempAvailablePools.pool.status = 'live';
    tempAvailablePools.pool.upcoming = false;
    tempAvailablePools.pool.time_left = 0;
    let tempFixtures = this.state.fixtures;
    tempFixtures[0].enabled = false;
    // tempFixtures[0].sort_status = 0;
    let tempFixturesProps = [];
    this.state.fixtureProps.forEach((fixtProps) => {
      if (fixtProps.fixture_id === tempFixtures[0].id) {
        fixtProps.enabled = false;
        fixtProps.fixture.enabled = false;
        fixtProps.fixture.time_left = 0;
        // fixtProps.sort_status = 0;
        tempFixturesProps.push(fixtProps);
      } else {
        tempFixturesProps.push(fixtProps);
      }
    });
    this.setState(
      {
        changePoolStatus: true,
        poolInfo: tempAvailablePools,
        fixtures: tempFixtures,
        liveFixturesData: Date.now(),
      },
      () => {
        this.setState({
          changePoolStatus: false,
          liveFixturesData: Date.now(),
        });
      }
    );
  }
  /**
   * This function calls the appi to reload the Fixtures of the selected Week
   * @param weekID
   */
  changeWeek(weekID) {
    console.log('WEeks Updated', weekID);
    this.setState({
      loaded: false,
    });
    let temporalWeeklyStandings, temporalWeekFixtures;
    axios
      .all([
        API.fetchWeekFixtures(this.state.poolInfo.pool.id, weekID),
        API.fetchWeeklyStandings(this.state.poolInfo.pool.id, weekID),
      ])
      .then(
        axios.spread((temporalWeekFixtures, temporalWeeklyStandings) => {
          console.log('WEeks Updated', temporalWeeklyStandings, temporalWeekFixtures, weekID);
          this.setState({
            loaded: true,
            poolWeekSelected: weekID,
            fixtures: temporalWeekFixtures.data.fixtures,
            weeklyStandings: temporalWeeklyStandings.data,
          });
        })
      )
      .catch((error) => {
        Sentry.captureException(error, {
          extra: 'Error fetching Axios all in Mi survivor',
        });
      });
  }

  /**
   * Change Type of Tablas de Posicones
   * @param eventKey
   */
  handleWeeklyActive(eventKey) {
    console.log(eventKey);
    if (eventKey === 1) {
      this.setState({
        weeklyStandingsActive: false,
      });
    } else if (eventKey === 2) {
      this.setState({
        weeklyStandingsActive: true,
      });
    }
  }

  /**
   * This function sets a timeout to hide the overlay of saved picks
   */
  handleCopyConfirmation() {
    console.log('handleConfirmationCopy');
    setTimeout(() => {
      this.setState({
        copiarPicksExito: null,
      });
    }, 1800); // this.setState({
    //     showConfirmation:true,
    // })
  }

  /**
   * This function sets a timeout to hide the overlay of saved picks
   */
  handleConfirmation() {
    // console.log("handleConfirmation");
    setTimeout(() => {
      this.setState(
        {
          showOverlay: true,
        },
        () => {
          setTimeout(() => {
            this.props.updatePanels(this.props.entry.id);
            this.setState({
              showOverlay: false,
            });
          }, 1800);
        }
      );
    }, 500);
  }

  /**
   * This function sets a timeout to handle the overlay of copied picks
   */
  handleCopiarPicks() {
    console.log('handleCopiarPicks');
    setTimeout(() => {
      this.setState({
        copiarPicksExito: null,
      });
    }, 1800); // this.setState({
    //     showConfirmation:true,
    // })
  }

  /**
   * This function change the redirection state
   */
  redirectLobby() {
    this.setState({
      redirectLobby: true,
    });
  }

  /**
   * This function gets the sport  icon of the current quiniela
   * @param sport_id
   * @returns img tag
   */
  setSport(sport_id) {
    return (
      <img
        className="title-ball-icon"
        src={
          sport_id === 1
            ? Futbol
            : sport_id === 2
            ? Americano
            : sport_id === 3
            ? Basketball
            : sport_id === 4
            ? Baseball
            : sport_id === 5
            ? Tenis
            : sport_id === 6
            ? Golf
            : sport_id === 7
            ? F1
            : sport_id === 8
            ? Box
            : sport_id === 9
            ? Hockey
            : null
        }
      />
    );
  }

  setLoaded() {
    // console.log('Set Loaded Survivor');
    this.setState(
      {
        loaded: true,
        liveFixturesData: this.props.liveFixturesData,
      },
      () => {
        this.props.loadedPanel(
          this.props.entry.survivor_id,
          this.props.entry.id,
          'survivor',
          this.props.entry.survivor
        );
      }
    );
  }

  selectLayout(status) {
    // console.log('Selecting Layout...');
    // console.log('Status...', status);
    // console.log('Full...', isFull);
    // console.log('Closed...',isClosed);
    // console.log('Mixed...', isMixed);
    let moneyFormat =
      this.state.survivorInfo.survivor.entry_currency === 'real' ? '$0,000' : '0,000';

    // console.log(moneyFormat);
    let conditionalComponent = null;
    conditionalComponent = (
      <div
        key={this.props.entry.id}
        ref="picksQuinielas"
        id={`picksContainer-${this.props.entry.id}`}
        className={`flex-col dashboard-item ${status}`}
      >
        <div
          className={`panelMisQuinielas survivor border-gradient`}
          data-entry={this.props.entry.id}
          style={
            this.props.maxHeight ? { height: this.props.maxHeight + 5, marginBottom: '15px' } : {}
          }
        >
          <div className=" flex-row title-quiniela main">
            <div className="col-xs-10 title-container-mis-quinielas">
              {this.setSport(this.state.survivorInfo.survivor.sport_id)}
              <div
                className={`${this.state.survivorInfo.survivor.tournament_instances[0].tournament.flag_class} miquiniela`}
              />
              <span>
                {' '}
                {this.state.survivorInfo.survivor.name_replaced}{' '}
                {numeral(this.state.survivorInfo.survivor.entry_fee / 100).format(moneyFormat)}{' '}
                {this.state.survivorInfo.survivor.entry_currency === 'real'
                  ? 'MXN'
                  : this.state.survivorInfo.survivor.entry_currency === 'pickcoin'
                  ? 'PC'
                  : 'Rf'}
              </span>
              <span style={{ fontSize: 'small', margin: '0 3px' }}>
                {this.props.entry.number === null ? null : `[${this.props.entry.number}]`}
              </span>
            </div>
            <div className=" col-xs-2 btn-close">
              <Button
                className="closeQuinaBtn"
                onClick={() => {
                  // this.deleteData();
                  this.props.closeMiQuina(this.props.index);
                  API.unSubscribeSurvivorSocketLiveStandings(this.state.survivorInfo.survivorId);
                }}
              >
                {/*<span><i className="glyphicon glyphicon-remove"></i></span>*/}
                <div className="btn-icon-close">
                  <img src={closeW} className={'closeIcon'} />
                </div>
              </Button>
            </div>
          </div>
          <div className="flex-row titulo-mis-picks panel-quiniealas">
            <div className="flex-col">
              <div className="flex-row">
                <div className=" flex-col col-no-padding boton-titulo-dash">
                  <Link
                    className="flex-row row-no-padding"
                    style={{ marginTop: 4 }}
                    to={`/survivor/${this.state.survivorInfo.survivor.slug}/${this.state.survivorInfo.survivor.id}/${this.state.selectedEntryData.id}`}
                  >
                    <Button className="ir-quiniela flex-container">Ir al survivor</Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
            <PickSelector
              players={this.state.players}
              userData={utils.getUserInfo()}
              survivorInfo={this.state.survivorInfo?.survivor}
              selectedEntryData={this.state.selectedEntryData}
              onDataLoaded={() => this.setState({ dataPicksLoaded: true })}
              updateSelectedEntryData={this.updateSelectedEntryData}
            />
          <div className="flex-row row-no-padding">
            <div className="flex-col col-no-padding">
              <TablaPosiciones
                dataPicksLoaded={this.state.dataPicksLoaded}
                activeStage={this.state.activeStage}
                actualPage={this.state.posActualPage}
                activeSort={this.state.activeSort}
                changeSearchNick={this.changeSearchNick}
                entry={this.state.selectedEntryData}
                entryId={this.state.selectedEntry}
                fetchSearchableStandings={this.fetchSearchableStandings}
                getAganar={this.getAganar}
                isVisible={true}
                loaded={this.state.loaded}
                nextPageStandings={this.nextPageStandings}
                poolInfo={this.state.survivorInfo.survivor}
                posiciones={this.state.standings}
                premios={this.state.prizes}
                resetNickValue={this.resetNickValue}
                selectedEntry={this.state.selectedEntry}
                siguientePagPositions={this.siguientePagPositions}
                sortChanged={this.state.sortChanged}
                sortPositionList={this.sortPositionList}
                standings={this.state.standings}
                submittingSearch={this.state.submittingSearch}
                tiesDistribution={this.state.tiesDistribution}
                type="survivor"
                userData={utils.getUserInfo()}
              />
            </div>
          </div>
        </div>
      </div>
    );
    return conditionalComponent;
  }

  updateStandings(newPositions) {
    console.log('Updating Positions');
    console.log(newPositions);
    // console.log(newPositions[0].total_possibly_correct);
    console.log('prize_currency', this.state.poolInfo.pool.prize_currency);
    if (newPositions[0].pool_id !== this.state.poolInfo.pool.id) {
      return;
    }
    let tempStandings = this.state.standings;
    let tempSelectedEntryData = this.state.selectedEntryData;

    newPositions = this.updateStandingsPosition(newPositions);
    newPositions = this.updateStandingsAganar(
      newPositions,
      this.state.poolInfo.pool.prize_currency
    );
    tempStandings['items'] = newPositions;
    console.log('newPositions', newPositions);
    newPositions.forEach((position) => {
      if (position.entry_id === this.state.selectedEntryData.id) {
        console.log('THIS');
        console.log(position);
        tempSelectedEntryData['standing'].rank = position.rank;
        tempSelectedEntryData['standing'].points = position.total_possibly_correct;
        tempSelectedEntryData['standing'].total_possibly_correct = position.total_possibly_correct;
        tempSelectedEntryData['standing'].user_prize = position.user_prize;
        tempSelectedEntryData['user_prize'] = position.user_prize;
        console.log(tempSelectedEntryData);
      }
    });
    // console.log('Update Standings');
    if (this.isMounted) {
      this.setState({
        selectedEntryData: tempSelectedEntryData,
        standings: tempStandings,
      });
    }
  }

  updateData() {
    console.log('Update data');
    const entryID = this.props.entry.id;
    const poolID = this.props.entry.pool_id;
    const type = this.props.entry.type;
    const format = this.props.entry.format;
    if (this.state.poolInfo.pool.closed) {
      return;
    }

    if (this.props.updatedId === this.props.entry.id) {
      // console.log('This Component was updated', this.props.updatedId, this.props.entry.id);
      axios
        .all([
          API.getEntriesInfo(entryID),
          // API.fetchFixtures(poolID),
          // API.fetchIndividualPool(poolID),
          // API.fetchPoolPrizes(poolID),
          // API.fetchStandings(poolID),
          // API.fetchStandingsGroups(poolID),
          API.fetchSimilarEntries(entryID),
          // API.fetchWeek(poolID),
          // API.fetchWeekFixtures(poolID,  type === 'full' ? this.props.entry.week_current_id : this.props.entry.week_id),
          // type === 'full'
          //   ? API.fetchWeeklyStandings(poolID,  type === 'full' ? this.props.entry.week_current_id : this.props.entry.week_id)
          //   : null
        ])
        .then(
          axios.spread((dataEntriesInfo, dataSimilarEntries) => {
            // console.log(dataSimilarEntries, this.state.poolInfo.pool.id);
            this.setState(
              {
                entriesInfo: dataEntriesInfo.data,
                selectedEntryData: dataEntriesInfo.data.entry,
                similarPools: dataSimilarEntries.data,
              },
              () => {
                this.props.afterUpdatePanels();
                this.setLoaded();
                // console.log('data Updated !');
              }
            );
          })
        );
    } else {
      console.log('This Component wasnt updated', this.props.updatedId, this.props.entry.id);
      API.fetchSimilarEntries(entryID).then((response) => {
        // console.log(dataSimilarEntries, this.state.poolInfo.pool.id);
        this.setState(
          {
            similarPools: response.data,
          },
          () => {
            this.props.afterUpdatePanels();
            this.setLoaded();
            // console.log('data Updated !');
          }
        );
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.refs.picksQuinielas && this.state.loaded !== prevState.loaded) {
      // console.log('First Update PicksREady' ,'with different Entry');
      this.handleTableHeight();
      // this.props.resetPanelsHeight();
    }
    if (this.props.maxHeight !== prevProps.maxHeight) {
      this.handleTableHeight();
    }
    // if (this.refs.picksQuinielas && this.state.loaded !== prevState.loaded
    //     && this.state.poolWeekSelected !== prevState.poolWeekSelected) {
    //     // console.log('Change WEek and ready to MAxHeight');
    //     this.props.resetHeight();
    //     this.props.handleMaxHeight();
    // }
    if (
      this.refs.picksQuinielas &&
      this.state.loaded !== prevState.loaded &&
      this.props.update !== prevProps.update
    ) {
      console.log('Update after mod other panel');
      this.handleTableHeight();
    }
    if (
      this.refs.picksQuinielas &&
      prevState.weeklyStandingsActive !== this.state.weeklyStandings
    ) {
      // console.log('WEekly Standings CHange');
      this.handleTableHeight();
    }
    if (this.props.update !== prevProps.update) {
      if (this.props.update === true) {
        // console.log('update This Panel Finnaly',this);
        this.updateData();
      }
    }
    if (this.props.liveFixturesData !== prevProps.liveFixturesData) {
      console.log('liveFixtData');
      this.setState({
        liveFixturesData: this.props.liveFixturesData,
      });
    }
  }

  /**
   * Get all the  data of the selected entry
   * -- Entry Info
   * -- Fixtures Info
   * -- Pool Info
   * -- Pool Prizes
   * -- Standings
   * -- Group Standings
   * -- similar Pools
   * -- Week Info
   * -- Week Fixtures
   *
   */
  componentDidMount() {
    /**
     * Init SubscriptionRxjs for each panel
     * @type {SubscriptionRx | ISubscriptionRx | AnonymousSubscriptionRx | * | TeardownLogic}
     */
    this.fixturesSubscriptionRx = SubscriptionRxs.fixtureReceived$.subscribe((updatedFixtures) => {
      this.handleMyEntryStatus(updatedFixtures);
      this.handleFixtureUpdateSocket(updatedFixtures);
    });

    /****************************
     ******* FixtureClosed Received
     *****************************
     * This subscription handles the state when a fixture was closed from the live pools
     * @param fixtureID
     */
    this.fixtureCloseSubscription = SubscriptionRxs.fixtureCloseReceived$.subscribe(
      (fixtureClose) => {
        this.fixtureCloseSocket(fixtureClose);
      }
    );

    const survivorEntryId = this.props.entry.id;
    const survivorId = this.props.entry.survivor_id;
    // const type = this.props.entry.type;
    // const format = this.props.entry.format;
    let temporalEntriesInfo,
      temporalFixtures,
      temporalPoolInfo,
      temporalPremios,
      temporalStandings,
      temproalGroupStandings,
      termpoalSimilarEntries,
      temporalPoolWeeks,
      temporalSelectedWeekFixtures,
      temporalWeelkyStandings;

    axios
      .all([
        API.fetchSurvivorDetails(survivorId),
        API.fetchSurvivorEntry(survivorEntryId),
        API.fetchUserData(),
        API.fetchSurvivorStandings(survivorId, this.queryParams),
      ])
      .then(
        axios.spread(
          (survivorInfoResponse, survivorEntryResponse, userData_, survivorStandingsResponse) => {
            const survivorInfoData = survivorInfoResponse.data;
            const survivorEntryData = survivorEntryResponse.data;
            const updatedUserData = userData_.data;
            const survivorStandingsData = survivorStandingsResponse.data;

            let myTemporalEntry = survivorEntryData.survivor_entry;
            myTemporalEntry.user_prize = myTemporalEntry.survivor_user_prize;

            this.setState({
              survivorInfo: survivorInfoData,
              prizes: survivorInfoData.survivor_prizes,
              isLogged: true,
              tiesDistribution: survivorInfoData.ties_distribution,
              selectedEntry: survivorEntryId,
              selectedEntryData: myTemporalEntry,
              standings: survivorStandingsData,
              userData: updatedUserData,
              withRegister: true,
            });

            // console.log(survivorInfoData);
            if (survivorInfoData.survivor.sport_id !== 6 && survivorInfoData.survivor.sport_id !== 7) {
              API.fetchSurvivorFixtures(survivorInfoResponse.data.currentWeek.id).then(
                (responseFixtures) => {
                  this.setState(
                    {
                      fixtures: responseFixtures.data.fixtures,
                      firstOf: responseFixtures.data.firstOf,
                      loaded: true,
                      mounted: true,
                    },
                    () => {
                      this.initializeSocket(survivorInfoData.survivor.id);
                      this.setLoaded();
                    }
                  );
                }
              );
            } else {
              // Golf y F1
              this.setState({
                loaded: true,
                mounted: true,
              })
            }
          }
        )
      )
      .catch((error) => {
        console.log(error);
        Sentry.captureException(error, {
          extra: 'Error Fetching All in Misurvivor Panel',
        });
        this.props.closeMiQuina(this.props.index);
        this.props.closedPanel(this.props.entry.id, this.props.entry.survivor_id, 'survivor');
      });
  }

  componentWillUnmount() {
    this.props.closedPanel(this.props.entry.id, this.props.entry.survivor_id, 'survivor');

    if (this.fixturesSubscription) {
      // console.log('Closed Subscritpio in fixtures');
      this.fixturesSubscription.unsubscribe();
    }
    if (this.fixtureCloseSubscription) {
      // console.log('Closed Subscritpio in fixtureClose');
      this.fixtureCloseSubscription.unsubscribe();
    }
  }

  render() {
    // console.log('MisQuinielasPanel');
    let selectedWeek;
    let itPassed;
    let isFull;
    let isMixed;
    let now = moment();
    let endWeek;
    let poolStatus;
    let conditionalComponent = null;
    if (this.state.loaded) {
      // console.log(this);
      // isMixed = this.state.poolInfo.pool.format === 'mixed' ? true : false;
      // endWeek = moment(this.state.poolInfo.pool.registration_deadline);
      poolStatus = this.state.survivorInfo.survivor.status;
      // console.log(poolStatus, itPassed, selectedWeek, isFull);
      if (this.state.redirectLobby) {
        conditionalComponent = (
          <Redirect
            push
            to={{
              pathname: `/survivor/${this.state.survivorInfo.survivor.slug}/${this.state.survivorInfo.survivor.id}/${this.state.selectedEntryData.id}`,
            }}
          />
        );
      } else {
        // console.log('poolStatus', poolStatus);
        conditionalComponent = this.selectLayout(poolStatus);
      }
    } else {
      conditionalComponent = (
        <div className={`col-xs-12 col-md-6 col-lg-4 col-fhd-3 dashboard-item upcoming`}>
          <div className="panelMisQuinielas border-gradient">
            <Loading />
          </div>
        </div>
      );
    }
    return conditionalComponent;
  }
}
export default MiSurvivorPanel;
