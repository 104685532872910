import React from 'react';

import axios from 'axios';
import numeral from 'numeral';
import Loading from '../Loading/Loading';
import { Nav, NavItem } from 'react-bootstrap/lib';

import TablaPosiciones from '../TablaPosiciones';
import Premios from '../Premios';

import API from '../../utils/api';
import utils from '../../utils/utils';
import Utils from '../../utils/survivorUtils';

import MainHeaderSurvivor from '../MainHeaderSurvivor';
import PicksGrupo from '../PicksGrupo';
import Marcadores from '../Marcadores';
import ChatPanel from '../ChatPanel';
import SurvivorContestGroupPicks from '../SurvivorContestGroupPicks/SurvivorContestGroupPicks';
import SurvivorContestGroupPicksF1 from '../SurvivorContestGroupPicks/SurvivorContestGroupPicksF1';
import SurvivorLeaderBoard from '../SurvivorLeaderBoard/SurvivorLeaderBoard';

import { refresh } from '../../assets/images/botonREFRESHVerde-100.png';
import * as Sentry from '@sentry/browser';
import $ from 'jquery';

const MainPanel = ({
  active,
  actualPage,
  tiesDistribution,
  cambiarEntradas,
  activeSort,
  currentEntry,
  changeSearchNick,
  fetchSearchableStandings,
  loaded,
  nextPageStandings,
  standings,
  prizes,
  survivor,
  currentWeek,
  resetNickValue,
  selectedEntryData,
  siguientePagPositions,
  sortPositionList,
  standingsChanged,
  sortChanged,
  standGroups,
  submittingSearch,
  tipoPremio,
  type,
  userData,
  availableMessages,
  survivorEntries,
  survivorWeeks,
  handleMessage,
  handleMaxHeight,
  resetMessage,
  newMessage,
  sendMessage,
  getAganar,
  withRegister,
}) => {
  // console.log('%c Main Panel', 'font-size:2em; color:#DF4B26;')
  // console.log(standings)
  return (
    <div className="multiple-quiniela-info principal flex-row row-no-padding responsive-lg">
      <div className="flex-row responsive-lg">
        <div className="first-section flex-col">
          <div className="flex-row">
            <div className="flex-col">
              <TablaPosiciones
                activeSort={activeSort}
                actualPage={actualPage}
                cambiarEntradas={cambiarEntradas}
                changeSearchNick={changeSearchNick}
                entry={currentEntry}
                entryId={currentEntry}
                fetchSearchableStandings={fetchSearchableStandings}
                getAganar={getAganar}
                isVisible={true}
                loaded={loaded}
                nextPageStandings={nextPageStandings}
                poolInfo={survivor}
                poolWeekSelected={currentWeek}
                posiciones={standings}
                premios={prizes}
                resetNickValue={resetNickValue}
                selectedEntry={!currentEntry ? null : currentEntry.id}
                siguientePagPositions={siguientePagPositions}
                sortChanged={standingsChanged}
                sortPositionList={sortPositionList}
                standGroups={standGroups}
                standings={standings}
                submittingSearch={submittingSearch}
                tiesDistribution={tiesDistribution}
                tipoPremio={tipoPremio}
                type={'survivorLobby'}
                userData={userData}
              />
            </div>
          </div>
          <div className="flex-row">
            <Premios
              weeks={survivorWeeks}
              loaded={loaded}
              poolInfo={survivor}
              poolWeekSelected={currentWeek}
              premios={prizes}
              standGroups={standGroups}
              standings={standings}
            />
          </div>
        </div>
        <div className="flex-col second-section" style={{marginTop: '2px'}}>
          {utils.isLogged() ? (
            <ChatPanel
              gameType="survivor"
              entries={survivorEntries}
              handleMessage={handleMessage}
              handleMaxHeight={handleMaxHeight}
              loaded={loaded}
              logged={utils.isLogged()}
              newMessage={newMessage}
              selectedEntry={currentEntry}
              resetMessage={resetMessage}
              quinielaID={survivor.id}
              sendMessage={sendMessage}
              userAvatar={utils.isLogged() ? utils.getUserInfo() : null}
              userNick={utils.isLogged() ? utils.getUserInfo().nick : null}
              withRegister={withRegister}
            />
          ) : (
            <div className="message-not-logged"> Necesitas Ingresar para poder usar el chat</div>
          )}
        </div>
      </div>
    </div>
  );
};

/**
 *This component handles the different sections
 of the right panel

 *
 * @class SurvivorMultipleContainer
 * @extends {React.Component}
 */
class SurvivorMultipleContainer extends React.Component {
  constructor(props) {
    console.dir('SurvivorMultiple');
    console.dir(props);
    super(props);
    this.state = {
      selection: 1,
      mounted: false,
      picksGrupo: null,
      loading: false,
      queryParamsPicksGrupo: {
        page: 1,
        limit: 100,
        nick: '',
        sort: [],
      },
    };

    this.getAganar = Utils.getAganar.bind(this);

    /**
     * this function handles the message action to send it
     * @param e
     */
    this.handleMessage = (e) => {
      console.log('handleMessage', e.target.value);
      this.setState({
        newMessage: e.target.value,
      });
    };
    /**
     * This function reset to null the satate of the message
     */
    this.resetMessage = () => {
      console.log('resetMEssage');
      this.setState({
        newMessage: '',
      });

      // console.log('SurvivorMultipleContainer', this.props);
    };

    /**
     * This function changes the section to show
     * of the MultipleContainer
     */
    this.changeSection = (id) => {
      // console.log('changeSection');
      this.setState({
        selection: Number(id),
      });
    };

    /**
     * This function handles a call to the API
     * to get the new page of the positions
     * @param eventKey
     */
    this.siguientePagPositions = (eventKey) => {
      console.log('Next Positions...');
      let loaderElement = $('#loading');
      let tempActiveSort = null;
      // console.log(loaderElement);
      if (loaderElement !== undefined && loaderElement.length > 0) {
        loaderElement[0].style.display = 'flex';
      }
      // loading.style.position('flex');
      let temporalPositions = this.props.standings.items;
      // console.log('siguiente');
      let val = eventKey;

      let newPage = this.state.queryParams.page + 1;

      API.fetchSurvivorStandings(this.state.survivor.id, newPage)
        .then((response) => {
          this.setState(
            (prevState) => {
              // console.log( tempActiveSort );
              let newPosiciones = response.data;

              let newItems = response.data.items !== null ? response.data.items : null;

              if (newItems !== null) {
                newItems.forEach((item) => {
                  temporalPositions.push(item);
                });
              }

              if (this.props.survivor.status === 'live') {
                this.updateStandingsAganar(temporalPositions);
              }

              newPosiciones.items = temporalPositions;

              return {
                posiciones: newPosiciones,
                posActualPage: newPage,
              };
            },
            () => {
              if (this.updateScrollPositions !== undefined) {
                this.updateScrollPostions(this.state.standings);
              }
              if (loaderElement !== undefined && loaderElement.length > 0) {
                loaderElement[0].style.display = 'none';
                let avatars = $('.score-avatars').height('auto');
              }
            }
          );
        })
        .catch((error) => {
          Sentry.captureException(error, { extra: 'Error Fetching next Standings' });
          // window.location.assign('/')
        });
    };
    /**
     * This function gets the available messges in the socket
     * @param messages - Array
     *
     */
    this.getAllMessages = (messages) => {
      console.log('%c Get All Messages', messages);
      this.setState({
        availableMessages: messages,
      });
    };
    this.sendMessage = (message) => {
      console.log('%c SendMessage', message);
    };
  }
  componentDidMount() {
    //  API.subscribeSocket(
    //    'survivor',
    //    this.props.survivorInfo.id,
    //     utils.getUserInfo().user.id,
    //     utils.getUserInfo().user.nick,
    //     utils.getToken(),
    //     getAllMessages => {
    //       console.log(getAllMessages);
    //       this.setState({
    //         availableMessages: getAllMessages,
    //       })
    //     },
    //     this.sendMessage())
  }

  render() {
    // console.log('MultipleContainer',this.props)
    let conditionalComponent = null;
    if (this.state.selection === 1) {
      conditionalComponent = (
        <MainPanel
          active={true}
          activeSort={this.props.activeSort}
          actualPage={this.state.actualPage}
          availableMessages={this.state.availableMessages}
          cambiarEntradas={this.props.cambiarEntradas}
          changeSearchNick={this.props.changeSearchNick}
          currentEntry={this.props.selectedEntryData}
          currentWeek={this.props.currentWeek}
          fetchSearchableStandings={this.props.fetchSearchableStandings}
          getAganar={Utils.getAganar}
          handleMessage={this.handleMessage}
          loaded={this.props.loaded}
          newMessage={this.state.newMessage}
          nextPageStandings={this.props.nextPageStandings}
          prizes={this.props.prizes}
          refreshAfterGuardarInLobby={this.props.refreshAfterGuardarInLobby}
          resetMessage={this.resetMessage}
          resetMEssage={this.resetMessage}
          resetNickValue={this.props.resetNickValue}
          siguientePagPositions={this.props.siguientePagPositionsStandings}
          sortChanged={this.props.sortChanged}
          sortPositionList={this.props.sortPositionList}
          standGroups={this.props.standGroups}
          standings={this.props.standings}
          standingsChanged={this.props.standingsChanged}
          submittingSearch={this.props.submittingSearch}
          survivor={this.props.survivor}
          survivorWeeks={this.props.survivorWeeks}
          tiesDistribution={this.props.tiesDistribution}
          tipoPremio={this.props.tipoPremio}
          type={'lobby'}
          userData={utils.getUserInfo()}
          withRegister={this.props.withRegister}
        />
      );
    }

    if (this.state.selection === 2 && this.props.survivor.sport_id !== 6 && this.props.survivor.sport_id !== 7) {
      conditionalComponent = (
        <div className="multiple-quiniela-info grupo flex-row ">
          <div className="upper-section">
            <div className="container-picks">
              <div className="upper-table">
                <div className="upper-table-category headers border-gradient">
                  <MainHeaderSurvivor
                    survivorEntries={this.props.selectedEntryData}
                    withRegister={this.props.withRegister}
                    selectedEntryData={this.props.selectedEntryData}
                    fixturesInfo={this.props.fixtures}
                    survivorWeeks={this.props.survivorWeeks}
                    format={this.props.survivor.point_format}
                    survivorInfo={this.state.survivor}
                    selectedWeek={this.props.selectedWeek}
                    currentWeek={this.props.currentWeek}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="lower-section" style={{marginTop: '5px'}}>
            <PicksGrupo
              updateMyPicks={this.props.updateMyPicks}
              withRegister={this.props.withRegister}
              nextPicks={this.props.nextPicks}
              currentWeek={this.props.currentWeek}
              type={'survivor'}
              selectedEntry={!this.props.selectedEntryData ? null : this.props.selectedEntryData.id}
              entriesInfo={this.props.survivorEntries}
              fixtures={this.props.fixtures}
              handleMaxHeight={this.props.handleMaxHeight}
              handleUpdateReset={this.props.handleUpdateReset}
              liveClosedFixtures={this.props.liveClosedFixtures}
              liveFixtureData={this.props.liveFixturesData}
              logged={utils.isLogged()}
              loadedPicks={this.props.loadedPicks}
              poolInfo={this.props.survivor}
              poolWeeks={this.props.survivorWeeks}
              survivorTeams={this.props.survivorTeams}
              poolWeekSelected={this.props.selectedWeek}
              queryParamsPicksGrupo={this.state.queryParamsPicksGrupo}
              survivorID={this.props.survivor.id}
              standings={this.props.standings}
              updatedStandings={this.props.updatedStandings}
            />
          </div>
        </div>
      );
    }
    if (this.state.selection === 3 && this.props.survivor.sport_id !== 6 && this.props.survivor.sport_id !== 7) {
      conditionalComponent = (
        <div className="multiple-quiniela-info marcadores">
          <div className="first-section ">
            <Marcadores
              liveFirstureData={Date.now()}
              loaded={this.props.loadedPicks}
              handleMaxHeight={this.props.handleMaxHeight}
              fixtures={this.props.fixtures}
              poolWeekSelected={this.selectedWeek}
              poolInfo={this.props.survivor}
              userData={this.props.userData}
            />
          </div>
        </div>
      );
    }

    if (this.state.selection === 2 && (this.props.survivor.sport_id === 6 || this.props.survivor.sport_id === 7)) {
      switch (this.props.survivor.sport_id) {
        case 7: //F1
          conditionalComponent = (
            <SurvivorContestGroupPicksF1
              survivor={this.props.survivor}
              currentContest={this.props.currentContest}
              entryId={!this.props.selectedEntryData ? null : this.props.selectedEntryData.id}
              entry={this.props.survivorEntries}
              players={this.props.players}
            />
          );
          break;

        default:
          conditionalComponent = (
            <SurvivorContestGroupPicks
              survivor={this.props.survivor}
              currentContest={this.props.currentContest}
              entryId={!this.props.selectedEntryData ? null : this.props.selectedEntryData.id}
              entry={this.props.survivorEntries}
            />
          );
          break;
      }
    }

    if (this.state.selection === 3 && (this.props.survivor.sport_id === 6 || this.props.survivor.sport_id === 7)) {
      conditionalComponent = (
        <SurvivorLeaderBoard
          survivor={this.props.survivor}
          myEntry={this.props.survivorEntries}
          players={this.props.players}
          contest={this.props.currentContest}
        />
      );
    }

    return (
      <div
        id="multiple-quiniela-container"
        className={`flex-col survivor ${
          this.props.withRegister || (utils.isLogged() && utils.isSuPick())
            ? 'col-67'
            : this.props.survivor.status !== 'upcoming'
            ? ''
            : 'col-67'
        }`}
      >
        <Nav bsStyle="tabs" bsClass="tab-container nav flex-row " onSelect={this.changeSection}>
          <NavItem
            eventKey="1"
            className={`trapezoid flex-col col-no-padding col-center ${
              this.state.selection === 1 ? 'active' : null
            }`}
          >
            PRINCIPAL
          </NavItem>
          <NavItem
            eventKey="2"
            className={`trapezoid flex-col col-no-padding col-center ${
              this.state.selection === 2 ? 'active' : null
            }`} // disabled={this.state.entriesInfo === null ? true: false}
            style={
              this.props.survivor.closed || (utils.isLogged() && utils.isSuPick())
                ? { display: 'initial' }
                : this.props.survivor.status === 'upcoming' && !this.props.withRegister
                ? { display: 'none' }
                : { display: 'initial' }
            }
          >
            PICKS DE GRUPO
          </NavItem>
          <NavItem
            eventKey="3"
            className={`trapezoid flex-col col-no-padding col-center ${
              this.state.selection === 3 ? 'active' : null
            }`} // disabled={this.state.entriesInfo === null ? true: false}
            style={
              this.props.survivor.closed || utils.isSuPick()
                ? { display: 'initial' }
                : this.props.survivor.status === 'upcoming' && !this.props.withRegister
                ? { display: 'none' }
                : { display: 'initial' }
            }
          >
            {this.props.survivor.sport_id !== 6 && this.props.survivor.sport_id !== 7 ? 'MARCADORES' : 'LEADERBOARD'}
          </NavItem>
        </Nav>
        {conditionalComponent}
      </div>
    );
  }
}

export default SurvivorMultipleContainer;
