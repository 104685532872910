import React from 'react';

import axios from 'axios';
import numeral from 'numeral';
import InfiniteScroll from 'react-infinite-scroller';
import Loading from '../Loading/Loading';
import {
  Form,
  Radio,
  Button,
  FormGroup,
  FormControl,
  HelpBlock,
  Nav,
  NavItem,
  DropdownButton,
  MenuItem,
  Modal,
} from 'react-bootstrap/lib';
import SurvivorUI from '../SurvivorUI';
import SurvivorMultipleContainer from '../SurvivorMultipleContainer';
import AbonarSaldo from '../AbonarSaldo/AbonarSaldo';

import utils from '../../utils/utils';
import SurvivorContestUI from '../SurvivorContestUI/SurvivorContestUI';

/**
 * This component contains all the info and setions of the
 * survivor lobby
 * It handles the state of the sections
 * and distribute the data to all the components
 *
 * @class SurvivorInfoContainer
 * @extends {React.Component}
 *
 */
class SurvivorInfoContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      actualPAgeStandings: 1,
      cambiarEntradas: null,
      enoughBalance: null,
      entries: null,
      entriesInfo: null,
      errorCopiar: null,
      firstUpdate: false,
      fixtures: null,
      guardarPicksExito: false,
      item: null,
      loaded: true,
      logged: null,
      myRegister: {
        id: null,
        survivor_id: null,
        survivor_entry_id: null,
        user_id: null,
        survivor_picks: null,
      },
      selectedEntryData: null,
      survivor: null,
      survivorWeeks: null,
      survivorWeekSelected: null,
      premios: null,
      selection: 1,
      showTutorial: false,
      showPanelRegister: false,
      showAbonar: false,
      showConfirmation: false,
      standGroups: null,
      standings: null,
      submitting: false,
      userData: false,
    };
    /**
     * This handles the abonar Panel
     */
    this.showAbonarPanel = () => {
      this.setState({
        showAbonar: true,
      });
    };
    this.hideAbonarPanel = () => {
      this.props.isBalanceEnough();

      this.setState({
        showAbonar: false,
      });

      this.setState({
        showAbonar: false,
      });
    };
    /**
     * This handles the section
     */
    this.handleSection = (id) => {
      this.setState({
        seciton: id,
      });
    };
  }
  render() {
    let conditionalComponent = null;
    if (this.state.loaded) {
      conditionalComponent = (
        <div id="quiniela-lobby-container">
          <div className=" lobby col-lg-12  col-fhd-10 col-fhd-offset-1 col-rt-10 col-rt-offset-1">
            <div className="flex-row responsive-lg quinielas-info survivor">
              <AbonarSaldo
                type="modal"
                handleTutorialStep={this.props.handleTutorialStep}
                showAbonarSaldo={this.state.showAbonar}
                updatePanelSate={this.hideAbonarPanel}
                showAbonarPop={this.showAbonarPanel}
                updateUserInfo={this.props.updateUserInfo}
              />
              {!this.props.withRegister &&
              this.props.survivor.status !== 'upcoming' &&
              !utils.isSuPick() ? null : (
                <div style={{ position: 'relative' }} className={` flex-col col-34 col-no-padding`}>
                  {/* Soccer y NFL */}
                  {this.props.survivor.sport_id !== 6 && this.props.survivor.sport_id !== 7 ? (
                    <SurvivorUI
                      handleTutorialStep={this.props.handleTutorialStep}
                      updateStartedFixture={this.props.updateStartedFixture}
                      firstOf={this.props.firstOf}
                      showAbonarPanel={this.showAbonarPanel}
                      hideAbonarPanel={this.hideAbonarPanel}
                      showAbonar={this.state.showAbonar}
                      refreshAfterGuardarInLobby={this.props.refreshAfterGuardarInLobby}
                      enoughBalance={this.props.enoughBalance}
                      currentWeek={this.props.currentWeek}
                      selectedWeek={this.props.selectedWeek}
                      changeSelectedWeek={this.props.changeSelectedWeek}
                      entriesInfo={this.props.entriesInfo}
                      fixtures={this.props.fixtures}
                      loadedPicks={this.props.loadedPicks}
                      selectedEntryData={this.props.selectedEntryData}
                      survivorInfo={this.props.survivor}
                      survivorId={this.props.survivorId}
                      survivorWeeks={this.props.survivorWeeks}
                      survivorTeams={this.props.survivorTeams}
                      standings={this.props.standings}
                      survivorWeekSelected={this.props.survivorWeekSelected}
                      userData={this.props.userData}
                      updateTemporalRegisterPicks={this.props.updateTemporalRegisterPicks}
                      updateUserInfo={this.props.updateUserInfo}
                      withRegister={this.props.withRegister}
                      allFixtures={this.props.allFixtures}
                      previousWeek={this.props.previousWeek}
                      updateStartedFirstMatchOfWeek={this.props.updateStartedFirstMatchOfWeek}
                    />
                  ) : (
                    // Golf y F1
                    <SurvivorContestUI
                      key={this.props.selectedEntryId}
                      survivor={this.props.survivor}
                      currentContest={this.props.currentContest}
                      players={this.props.players}
                      updateTemporalRegisterPicks={this.props.updateTemporalRegisterPicks}
                      entry={this.props.selectedEntryData}
                      enoughBalance={this.props.enoughBalance}
                      isPopUp={false}
                      updateUserInfo={this.props.updateUserInfo}
                      showAbonarPanel={this.showAbonarPanel}
                      refreshAfterGuardarInLobby={this.props.refreshAfterGuardarInLobby}
                    />
                  )}
                </div>
              )}
              <SurvivorMultipleContainer
                activeSort={this.props.activeSort}
                changeSearchNick={this.props.changeSearchNick}
                currentWeek={this.props.currentWeek}
                fetchSearchableStandings={this.props.fetchSearchableStandings}
                firstOf={this.props.firstOf}
                fixtures={this.props.fixtures}
                handleMaxHeight={this.props.handleMaxHeight}
                hideAbonarPanel={this.hideAbonarPanel}
                loadedPicks={this.props.loadedPicks}
                prizes={this.props.prizes}
                refreshAfterGuardarInLobby={this.props.refreshAfterGuardarInLobby}
                resetNickValue={this.props.resetNickValue}
                selectedEntryData={this.props.selectedEntryData}
                selectedWeek={this.props.selectedWeek}
                showAbonar={this.state.showAbonar}
                showAbonarPanel={this.showAbonarPanel}
                siguientePagPositionsStandings={this.props.siguientePagPositionsStandings}
                sortChanged={this.props.sortChanged}
                sortPositionList={this.props.sortPositionList}
                standings={this.props.standings}
                standingsChanged={this.props.standingsChanged}
                submittingSearch={this.props.submittingSearch}
                survivorEntries={this.props.entriesInfo}
                survivor={this.props.survivor}
                survivorTeams={this.props.survivorTeams}
                survivorWeeks={this.props.survivorWeeks}
                tiesDistribution={this.props.tiesDistribution}
                updateMyPicks={this.props.updateMyPicks}
                withRegister={this.props.withRegister}
                currentContest={this.props.currentContest}
                players={this.props.players}
              />
            </div>
          </div>
        </div>
      );
    } else {
      let conditionComponent = <Loading label="Cargando información del survivor" />;
    }
    return conditionalComponent;
  }
}

export default SurvivorInfoContainer;
