import React,{ useState } from 'react'
import styled from 'styled-components'
import { PopupCancelCashout } from './PopupCancelCashout'

export const CancellTrigger = ({ cashout, closeAfterCancel }) => {
  const [showConfirm, setShowConfirm] = useState(false)

  const show = () => {
    setShowConfirm(true)
  }

  const onClose = () => {
    setShowConfirm(false)
  }

  return (
    <>
      <PopupCancelCashout
        onClose={onClose}
        show={showConfirm}
        title='Cancelar Retiro'
        cashout={cashout}
        closeAfterCancel={closeAfterCancel}
      />

      {cashout.status === 'pending' && (
        <Button onClick={show}>Cancelar</Button>
      )}
    </>
  )
}

const Button = styled.button`
  background-color: #a40707;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
`