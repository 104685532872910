import React, { useEffect, useState } from 'react'
import numeral from 'numeral'
import { Button, FormControl, Modal } from 'react-bootstrap/lib'

// Components
import FormCashout from '../FormCashout'
import ExtraFinancialData from './ExtraFinancialData'
import { CashoutsTable } from './CahoutsTable'

// Utils
import API from '../../utils/api'
import utils from '../../utils/utils'
import FireBaseUtils from '../../utils/FireBaseUtils'

// Assets
import flechas from '../../assets/images/flechasIconBlanco-50.png'
import retirarIcon from '../../assets/images/retirarIconBlanco.png'
import { useCashouts } from './use-cashouts'
import { useCashoutsPending } from './use-cashouts-pending'

/**
*This functional component renders the available cash of the user
* @param cashoutInfo
* @param balanceReal
@return FucntionalComponent
**/
const RowPossibleBalance = ({ cashoutInfo, balanceReal }) => {
  return (
    <div className='col-xs-12 text-center'>
      <div className='row possible-balance'>
        <div className='col-xs-6 retirable'>
          <strong>Saldo disponible para retiro:</strong>
          <span>{numeral(cashoutInfo.available / 100).format('$0,000.00')}</span>
        </div>
        <div className='col-xs-6 no-retirable'>
          <strong>Saldo no retirable:</strong>
          <span>{numeral(cashoutInfo.unavailable / 100).format('$0,000.00')}</span>
        </div>
      </div>
      <div className='availableBalance'>
        <span>
          El monto mínimo para retirar saldo es de{' '}
          <strong className='text-pickwin'>$100.00 MXN</strong>
        </span>
        <br />
        <span className='myBalance'>
          Tu balance{' '}
          <strong className='text-pickwin'>
            {numeral(balanceReal / 100).format('$0,000.00')} MXN
          </strong>
        </span>
      </div>
    </div>
  )
}

/**
*This functional component renders
* @param successfullRequest
* @param balanceReal
@return FucntionalComponent
**/
const CashoutInfo = ({ successfullRequest }) => {
  return (
    <div className='info-retirar'>
      <div className='titulo-user-info'>
        <img src={retirarIcon} className='title-icon' />
        <span>Retirar</span>
      </div>
      <div className='mensaje'>
        {successfullRequest
          ? (
            <div className='flex-row'>
              <div className='flex-col'>
                En breve estaremos en contacto contigo, tu transacción esta siendo procesada...
              </div>
            </div>
            )
          : (
            <span>
              Hola Pickwinero, para que sea posible retirar fondos de tu cuenta por favor facilitanos
              la siguiente información.
              <br />
              Te recordamos que para poder retirar tus fondos tendrás que haber jugado al menos el{' '}
              <strong>100%</strong> de lo que hayas depositado la última vez.
              <br />
              Los retiros sólo podrán solicitarse cada <strong>48hrs.</strong>
              <br />
              Después de solicitar tu retiro, el equipo de Pickwin analizará tu petición y te
              informaremos por correo electrónico sobre el resultado de tú petición.
              <br />
            </span>
            )}
      </div>
    </div>
  )
}

const Retirar = ({ 
  userData, 
  documentos, 
  availableCashout, 
  userBalance,
  updateUserInfo,
  updateUserBalance
}) => {
  const { refetch } = useCashouts()
  const pendingCashouts = useCashoutsPending()

  // States
  const [state, setState] = useState({
    responseCashoutData: null,
    matiVerificationStatus: false,
    showVerificationID: false,
    itNeedsVerification: true,
    showCashoutResponse: false,
    phone: null,
    smsSubmit: false,
    smsButtonText: 'Envíar SMS',
    smsCounter: 120,
    smsGetResult: false,
    smsInterview: null,
    cashOutVerified: userData.cashouts_verified,
    smsResultStatus: null,
    smsResultStatusClass: null,
    resultIncodeButton: false,
    showIncodeResultModal: false,
    incodeActive: null,
    initializedFinancial: false,
    needFinancialData: false,
    financialData: null,
    financialGetError: null,
    successfullRequest: false,
    showResponseModal: false
  })

  // Handlers
  const handleCashoutResponse = () => {
    setState(prev => ({ ...prev, showCashoutResponse: !prev.showCashoutResponse }))
  }

  const closeResponseModal = () => {
    setState(prev => ({ ...prev, showResponseModal: false }))
  }

  const handleVerification = () => {
    setState(prev => ({ ...prev, showVerificationID: !prev.showVerificationID }))
  }

  const matiVerificationEvent = () => {
    const receiveMessage = (event) => {
      if (event.origin !== 'https://signup.getmati.com') return
      
      const data = JSON.parse(event.data)
      if (['mati-signup-0::exitedSdk', 'mati-signup-0::userFinishedSdk'].includes(data.action)) {
        handleVerification()
      }
    }

    window.addEventListener('message', receiveMessage, false)
    return () => window.removeEventListener('message', receiveMessage)
  }

  const metadataVerification = () => {
    const userData = utils.getUserInfo()
    return JSON.stringify({
      userId: userData.id,
      nick: userData.nick
    })
  }

  const handlePhone = (e) => {
    const phone = e.target.value
    setState(prev => ({ ...prev, phone }))
  }

  const sendSms = () => {
    setState(prev => ({ ...prev, smsSubmit: true }))

    const phone = (userData.phone === '' || userData.phone === null) ? state.phone : userData.phone
    if (phone !== '' || phone !== null) {
      const onBoarding = API.getIncodeOnBoarding()
      const confId = process.env.REACT_APP_INCODE_CONF

      onBoarding.createSession('MX', null, { configurationId: confId })
        .then((token) => {
          const interviewSession = token
          const params = { type: 'cashout', session: interviewSession }
          
          API.incodeSaveSession(params).then((response) => {
            if (response.data.success) {
              const params = { phone, session: interviewSession }

              API.sendIncodeSms(params).then((response) => {
                if (response.data.res.success) {
                  setState(prev => ({ ...prev, smsInterview: interviewSession }))
                  
                  const interval = setInterval(() => {
                    setState(prev => {
                      const newCounter = prev.smsCounter - 1
                      if (newCounter === 0) {
                        clearInterval(interval)
                        return {
                          ...prev,
                          smsCounter: newCounter,
                          smsGetResult: true,
                          smsButtonText: `Esperando la validación vía SMS: ${newCounter} seg.`
                        }
                      }
                      return {
                        ...prev,
                        smsCounter: newCounter,
                        smsButtonText: `Esperando la validación vía SMS: ${newCounter} seg.`
                      }
                    })
                  }, 1000)
                } else {
                  console.log('Error send sms')
                }
              }).catch(console.error)
            } else {
              console.log('Error to save session')
            }
          })
        })
    } else {
      alert('Debes ingresar un número de teléfono')
    }
  }

  const getIncodeResults = () => {
    setState(prev => ({ ...prev, resultIncodeButton: true }))
    
    API.getIncodeResults(state.smsInterview).then((response) => {
      let smsResultStatus = ''
      let smsResultStatusClass = ''
      
      switch (response.data.result) {
        case null:
          smsResultStatus = 'No se pudo realizar la verificación, intentalo de nuevo'
          smsResultStatusClass = 'failed'
          break
        case 'OK':
          smsResultStatus = 'Tu verificación ha sido exitosa'
          break
        case 'MANUAL':
        case 'WARN':
          smsResultStatus = 'No pudimos verificar tus documentos, el equipo de pickwin revisará tu caso. para cualquier aclaración comunicate a soporte@pickwin.net'
          smsResultStatusClass = 'failed'
          break
        case 'FAIL':
          smsResultStatus = 'No se pudo verificar tu cuenta, por favor comunicate a soporte@pickwin.net o intentalo de nuevo'
          smsResultStatusClass = 'failed'
          break
        default:
          smsResultStatus = 'No pudimos verificar tu identidad, intentalo nuevamente'
          smsResultStatusClass = 'failed'
      }

      setState(prev => ({
        ...prev,
        smsResultStatus,
        smsResultStatusClass,
        smsSubmit: false,
        resultIncodeButton: false,
        smsGetResult: false,
        smsButtonText: 'Envíar SMS',
        smsCounter: 120,
        cashOutVerified: response.data.result === 'OK' ? 4 : prev.cashOutVerified,
        showIncodeResultModal: true
      }))
    })
  }

  const showIncodeResultModal = (value) => {
    setState(prev => ({ ...prev, showIncodeResultModal: value }))
  }

  const getFinancialData = async () => {
    try {
      const res = await API.extraFinancialData()
      if (res.data.userData) {
        setState(prev => ({
          ...prev,
          needFinancialData: getIsNeedFinancialData(res.data.userData),
          initializedFinancial: true,
          financialData: res.data.userData
        }))
      } else {
        setState(prev => ({ ...prev, financialGetError: true }))
      }
    } catch (error) {
      console.error(error)
      setState(prev => ({ ...prev, financialGetError: true }))
    }
  }

  const getIsNeedFinancialData = (userData) => {
    const { rfc, curp, address, financial_activity } = userData
    return (!rfc || (rfc && rfc.value.length < 13)) ||
           (!curp || (curp && curp.value.length < 18)) ||
           (!address || (address && address.value.length < 5)) ||
           (!financial_activity || (financial_activity && financial_activity.value.length < 5))
  }

  const successFinancialConfirmation = async () => {
    setState(prev => ({
      ...prev,
      financialData: null,
      needFinancialData: false,
      initializedFinancial: true
    }))
  }

  // Effects
  useEffect(() => {
    const fileref = document.createElement('script')
    fileref.setAttribute('type', 'text/javascript')
    fileref.setAttribute('src', 'https://sdk.incode.com/sdk/onBoarding-1.48.0.js')
    document.getElementsByTagName('head')[0].appendChild(fileref)

    FireBaseUtils.init().then(() => {
      const remoteConfig = FireBaseUtils.data()
      setState(prev => ({ ...prev, incodeActive: remoteConfig.data.incode_enabled }))
    })
    
    getFinancialData()
  }, [])

  const refreshCashouts = () => {
    refetch()
    pendingCashouts.refetch()
  }

  // Render logic
  const isValid = !documentos ? null : documentos.find((document) => document.valid)
  const { initializedFinancial, needFinancialData, financialData } = state

  return (
    <div className='user-categories-container col-xs-12 col-md-9'>
      <Modal
        show={state.showIncodeResultModal}
        className=''
        dialogClassName='modal-dialog-xxxs'
        backdropClassName=''
        style={{ marginTop: '30vh', zIndex: '1070' }}
      >
        <>
          <div id='panelTitle' className={`panel-abonar-titulo ${state.smsResultStatusClass}`}>
            <span>Resultados de verficación de identidad</span>
            <button
              className='cerrar-panel'
              onClick={() => showIncodeResultModal(false)}
            >
              <div className='btn-icon-close'>
                <i className='ion-ios-close-empty' />
              </div>
            </button>
          </div>
          <div className='panel-pickwin' style={{ marginBottom: '15px' }}>
            <div className='flex-col col-80 col-offset-10 col-no-padding text-center transaction-success'>
              <div className='flex-row text-center'>
                <strong className='font18'>{state.smsResultStatus}</strong>
              </div>
            </div>
          </div>
        </>
      </Modal>

      <div className='category-user retirar row'>
        <CashoutInfo successfullRequest={state.successfullRequest} />
        <RowPossibleBalance
          cashoutInfo={availableCashout}
          balanceReal={userBalance.balanceReal}
        />

        {availableCashout.available < 10000 && (
          <div className='col-xs-8 col-xs-offset-2 notEnoughMessage flex-container column'>
            <i className='ion-sad' />
            <span className='message'>
              Lo sentimos pero no puedes hacer un retiro porque <br />
              tú <strong>saldo disponible para retiro </strong> es menor a $100.00 MXN
            </span>
            <span className='text-container'>
              <i className='ion-paper-airplane' /> Si tienes alguna duda o comentario contactános en:
            </span>
            <span className='text-container'>
              <strong>
                <a href='mailto:soporte@pickwin.net'>soporte@pickwin.net</a>
              </strong>
            </span>
          </div>
        )}

        {state.cashOutVerified !== 4 && state.incodeActive === true && (
          <div className='flex-col col-no-padding col-80 col-offset-10'>
            <div>
              <p>Para poder continuar necesitamos confirmar tu identidad mediante un mensaje SMS</p>
              <p>Visita el sitio web que se te envie por sms, sigue los pasos de validación en tu celular</p>
              <p>Una vez que hayas terminado y consulta tus resultados cuando el contador termine.</p>
              <p>Por favor ten a la mano:</p>
              <ul>
                <li>Identificación oficial vigente (INE o Pasaporte)</li>
              </ul>
            </div>
            
            {(userData.phone === '' || userData.phone === null) && (
              <div>
                <FormControl
                  onChange={handlePhone}
                  placeholder='Numero de teléfono'
                  type='text'
                  value={state.phone}
                  style={{ marginTop: '1rem' }}
                />
              </div>
            )}
            
            {!state.smsGetResult ? (
              <Button
                onClick={sendSms}
                type='button'
                disabled={state.smsSubmit}
                className='btn-form btn-pick flex-container align justify'
              >
                {state.smsButtonText}
              </Button>
            ) : (
              <Button
                onClick={getIncodeResults}
                type='button'
                disabled={state.resultIncodeButton}
                className='btn-form btn-pick flex-container align justify'
              >
                Obtener resultados de validación
              </Button>
            )}
          </div>
        )}

        {/* If user needs to update financial data before cashout */}
        {state.cashOutVerified === 4 && availableCashout.available >= 10000 && initializedFinancial && needFinancialData && (
          <ExtraFinancialData
            financialData={financialData}
            onSuccess={successFinancialConfirmation}
          />
        )}

        {/* If all is ok to request balance */}
        {availableCashout.available >= 10000 && initializedFinancial && !needFinancialData && (
          <div className='form-wrapper'>
            <div className='flex-row row-center subtitulo-user-info'>
              <img src={flechas} className='title-icon' alt="Flechas icon" />
              <span>Validación de datos</span>
            </div>

            {state.incodeActive === false && (
              <FormCashout
                validDoc={!!isValid}
                documentos={documentos}
                updateUserInfo={updateUserInfo}
                updateUserBalance={updateUserBalance}
                availableForCashout={availableCashout}
                incodeActive={state.incodeActive}
                refetchCahouts={refreshCashouts}
              />
            )}

            {state.cashOutVerified === 4 && state.incodeActive === true && (
              <FormCashout
                validDoc={!!isValid}
                documentos={documentos}
                updateUserInfo={updateUserInfo}
                updateUserBalance={updateUserBalance}
                availableForCashout={availableCashout}
                incodeActive={state.incodeActive}
                refetchCahouts={refreshCashouts}
              />
            )}
          </div>
        )}
      </div>

      {/* If user has verified identity and has cashouts */}
      {state.cashOutVerified === 4 && state.incodeActive === true && (
        <>
          <div className='flex-row row-center subtitulo-user-info mt-2'>
            <img src={flechas} className='title-icon' alt="Flechas icon" style={{ marginRight: 10 }} />
            <span>Historial de retiros</span>
          </div>
          <CashoutsTable />
        </>
      )}

    </div>
  )
}

export default Retirar
