import React, { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import numeral from 'numeral'
import styled from 'styled-components'

// Hooks
import useRealNode from '../../hooks/useRealNode'
import { useCashoutsPending } from './use-cashouts-pending'

// Components
import Text from '../Text'
import Iconify from '../Iconify'
import Loading from '../Loading'
import { Status } from './Status'
import { CancellTrigger } from './CancellTrigger'
import { BalanceRealAnimated } from './BalanceRealAnimated'
import { Table, TableContainer, Td, Th, Tr } from './CahoutsTable'
import { Bar,  CloseButton, Content, ModalPanel } from '../ConfirmModal/ConfirmModal'

// Utils
import { publish, subscribe, unsubscribe } from '../../utils/events'

const EVENT_NAME = 'CASHOUTS_PENDING:SHOW_MODAL'

export const showPopupCashoutsPending = ({
  buttonConfirm = 'Continuar con Abono',
  buttonCancel = 'Cancelar Abono'
}) => {
  // Cacheamos el resolve
  return new Promise((resolve) => {
    resolve = resolve
    publish(EVENT_NAME, {
      resolve,
      buttonConfirm,
      buttonCancel
    })
  })
}

export const PopupCashoutsPending = () => {
  const node = useRealNode('modal-node-portal')
  const [show, setShow] = useState(false)
  const resolveRef = useRef(null)
  const [buttonText, setButtonText] = useState({
    confirm: 'Continuar',
    cancel: 'Cancelar'
  })

  // Event Listeners
  useEffect(() => {
    const listener = (data) => {
      const { buttonConfirm, buttonCancel, resolve } = data.detail

      setButtonText({
        confirm: buttonConfirm,
        cancel: buttonCancel
      })

      setShow(true)

      // Cacheamos el resolve de la promesa
      resolveRef.current = resolve
    }

    subscribe(EVENT_NAME, listener)

    return () => {
      unsubscribe(EVENT_NAME, listener)
    }
  }, [])

  const handleClose = (event) => {
    event.stopPropagation()
    event.preventDefault()

    setShow(false)
  }
  
  const stopPropagation = (event) => {
    event.stopPropagation()
  }

  const cancelCashouts = () => {
    resolveRef.current('WAS_CANCELLED_PENDING')
    setShow(false)
  }

  const continueWithPayment = () => {
    resolveRef.current('CONTINUE_WITH_PAYMENT')
    setShow(false)
  }

  if (!show) return null

  return ReactDOM.createPortal(
    (
      <ModalPanel tabIndex='1' onMouseDown={stopPropagation}>
        <Content style={{ maxWidth: 700, minHeight: 200 }}>
          <Bar>
            <Text center as='h6'>CANCELAR RETIROS</Text>
            <CloseButton onClick={handleClose}>
              <Iconify icon='ic:baseline-close' />
            </CloseButton>
          </Bar>

          <Info>
            <Text size={15} mt={25} mb={10}>Tienes retiros pendientes, ¿qué deseas cancelar alguno?</Text>
            <Text bold size={15} mb={15}>El balance se agregara inmediatamente a tu cuenta.</Text>
          </Info>

          <TableContent />

          <BalanceRealAnimated />
        
          <Footer style={{ marginBottom: 25 }}>
            <ButtonCancel onMouseDown={cancelCashouts} className='guardar-picks btn-rojo'>{buttonText.cancel}</ButtonCancel>
            <ButtonCancel onMouseDown={continueWithPayment} className='guardar-picks'>{buttonText.confirm}</ButtonCancel>
          </Footer>
        </Content>
      </ModalPanel>
    ), node)
}

export const TableContent = () => {
  const { cashouts, isLoading } = useCashoutsPending()

  return (
    <>
      {isLoading && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <Loading />
        </div>
      )}

      {cashouts.length === 0 && !isLoading && (
        <Text center as='p' mt={20} mb={20} color='#9c9c9c'>No hay solicitudes de retiro pendientes</Text>
      )}

      {cashouts.length > 0 && (
        <TableContainer>
          <Table>
            <thead>
              <tr>
                <Th>Fecha</Th>
                <Th>Monto</Th>
                <Th>Cuenta</Th>
                <Th>Estado</Th>
                <Th>Cancelación</Th>
              </tr>
            </thead>
            <tbody>
              {cashouts.map((cashout, index) => (
                <Tr key={index}>
                  <Td>{new Date(cashout.created).toLocaleDateString()}</Td>
                  <Td>{numeral(cashout.amount / 100).format('$0,000.00')}</Td>
                  <Td>{cashout.bank}...{cashout.bank_code}</Td>
                  <Td><Status status={cashout.status} /></Td>
                  <Td>
                    <CancellTrigger cashout={cashout} closeAfterCancel />
                  </Td>
                </Tr>
              ))}
            </tbody>
          </Table>
        </TableContainer>
      )}
    </>
  )
}


export const ButtonBase = styled.button`
  border: 0;
  outline: 0;
  color: #FFF;
  font-size: 1.1em;
  font-weight: bold;
  padding: 0.5em 2.5em;
  border-radius: 1em;
  transition: transform 200ms ease;
  will-change: transform;
  width: 300px;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 0.9;
    transform: scale(1.02);
  }
`

export const ButtonCancel = styled(ButtonBase)`
  background-color: #88ba44;
`

export const ButtonConfirm = styled(ButtonBase)`
  background-color: #ff0000;
`

const Footer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1em;
  align-items: center;
`

const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`
