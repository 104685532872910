import { useQuery } from '@tanstack/react-query'
import API from '../../utils/api'

export const useCashouts = () => {
  const { data, isLoading, refetch } = useQuery(['/cashouts/history'], API.getCashouts)

  return {
    cashouts: data?.data?.cashouts || [],
    isLoading,
    refetch
  }
}


