import { useQuery } from '@tanstack/react-query'
import API from '../../utils/api'

export const useCashoutsPending = () => {
  const { data, isLoading, refetch } = useQuery(['/cashouts/pending'], API.getCashoutsPending)

  return {
    cashouts: data?.data?.cashouts || [],
    isLoading,
    refetch
  }
}


